/*--------------------------------------------------------------------/
	sale
/--------------------------------------------------------------------*/
.sale-reason {
	padding-top: clamp(3rem, 5vw, 7rem);
	padding-bottom: clamp(4rem, 12vw, 12rem);
	position: relative;
	@include media-breakpoint-up(lg) {
		padding-top: 7rem;
		padding-bottom: clamp(4rem, 12vw, 12rem);
	}
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: calc(100% - 5vw);
		background: $main_c;
		@include media-breakpoint-up(lg) {
			height: calc(100% - 5.5rem);
		}
	}
	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		right: 0;
		background: $sub_c;
		width: 90%;
		height: calc(100% - 8vw);
		@include media-breakpoint-up(lg) {
			height: calc(100% - 9.5rem);
		}
		@include media-breakpoint-up(xl) {
			width: calc(50% + 746px);
		}
	}
	&__add {
		position: absolute;
		bottom: 0;
		right: 5%;
		z-index: z-index(module, part04);
		span {
			@include f-family(font02);
			color: $other_c9;
			@include f-w(700);
			@include l-sp(0.05em);
			@include f-s_xs(3, 140);
			@include line-h(0.85);
			@include media-breakpoint-up(xl) {
				@include f-s_xl(10, 40);
			}
			@include media-breakpoint-up(xxl) {
				@include f-size(200);
			}
		}
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part03);
	}
	&__tit {
		text-align: left;
		color: $black;
		margin-bottom: clamp(1.5rem, 3vw, 3rem);
		&__inner {
			display: inline-block;
			background: $white;
			position: relative;
			padding: 1.25em 5% 1.25em 0;
			&::before {
				content: "";
				position: absolute;
				top: 0;
				right: 100%;
				background: $white;
				width: 100%;
				height: 100%;
			}
			small {
				@include f-family(font01);
				@include l-sp(0.05em);
				margin-bottom: 0.5em;
				display: block;
				@include f-w(700);
				position: relative;
				line-height: 1.4;
				@include f-s_xs(0.8, 12);
				@include media-breakpoint-up(xl) {
					@include f-size(24);
				}
			}
			strong {
				@include l-sp(0.1em);
				display: block;
				@include f-w(700);
				position: relative;
				line-height: 1.2;
				@include f-s_xs(2, 30);
				@include media-breakpoint-up(xl) {
					@include f-size(64);
				}
				b {
					display: inline-block;
					background: $red;
					color: $white;
					@include f-w(900);
					font-size: 1.5em;
					padding: 0 0.2em;
				}
			}
		}
	}
}

.sale-reason__box {
	@include flex-c-reverse;
	justify-content: flex-end;
	background: $white;
	margin-bottom: 1.5rem;
	@include media-breakpoint-up(lg) {
		margin-bottom: 2.5rem;
	}
	&__txt {
		padding: 0 8% 1.5em;
	}
	&__tit {
		@include f-w(700);
		margin-bottom: 0.75em;
		padding: 0.75em 7%;
		background: $main_c;
		position: relative;
		color: $white;
		overflow: hidden;
		@include f-s_xs(0.94, 4);
		margin-top: -1.5rem;
		z-index: z-index(module, part04);
		@include media-breakpoint-up(lg) {
			@include f-s_lg(0.94, 2);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(18);
		}
		&::before {
			content: "";
			background: $other_c7;
			width: 80%;
			height: 100%;
			position: absolute;
			top: 0;
			right: 50%;
			transform: skew(-20deg);
		}
		&__inner {
			position: relative;
			z-index: z-index(module, part03);
		}
	}
	&__img {
		&__inner {
			@include aspect-img;
		}
	}
}

.sale-list {
	padding: clamp(4rem, 8vw, 8rem) 0 clamp(5rem, 8vw, 8rem);
	position: relative;
	&__add {
		position: absolute;
		top: 5rem;
		left: 50%;
		width: 70%;
		max-width: 840px;
		@include media-breakpoint-up(md) {
			left: 70%;
			width: 70%;
			max-width: 840px;
		}
		@include media-breakpoint-up(xl) {
			left: calc(50% + 300px);
			width: 840px;
		}
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part03);
	}
	&__tit {
		display: flex;
		align-items: center;
		justify-content: center;
		column-gap: 2px;
		margin-bottom: clamp(2rem, 3vw, 3rem);
		& > span {
			width: 60px;
			height: 60px;
			color: $white;
			display: flex;
			justify-content: center;
			align-items: center;
			background: $main_c;
			@include f-size(32);
			@include media-breakpoint-up(lg) {
				width: 90px;
				height: 90px;
				@include f-size(48);
			}
		}
	}
	&__list {
		row-gap: 2rem;
	}
}

.sale-box {
	margin-bottom: 2rem;
	a {
		@include dec-none;
		@include transition;
		color: $txt_c;
		display: block;
		&:hover {
			box-shadow: 1px 1px 10px rgba($black, 0.1);
		}
	}
	&__flex {
		margin-bottom: 0.5rem;
		display: flex;
		span {
			@include line-h(1);
			vertical-align: top;
		}
	}
	&__num {
		display: inline-block;
		background: $red;
		color: $white;
		padding: 0.5em 0.75em;
		@include f-family(font02);
		@include f-w(700);
		@include l-sp(0);
		@include f-size(14);
		min-height: 30px;
	}
	&__category {
		display: inline-block;
		min-height: 30px;
		padding: 0.5em 0.75em 0.4em;
		@include f-w(500);
		@include l-sp(0.05em);
		@include f-size(14);
		color: $white;
		text-align: center;
	}
	&__img {
		width: 100%;
		&__inner {
			@include aspect-img(14, 9);
		}
	}
	&__txt {
		position: relative;
		padding: 1.5em 8% 2em;
		background: $white;
	}
	&__tit {
		margin-bottom: clamp(0.5rem, 2vw, 1rem);
		@include f-s_xs(1, 2);
		color: $black;
		@include f-w(700);
		@include media-breakpoint-up(xs) {
			@include f-s_xs(1, 2);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(18);
		}
	}
	&__table {
		@include f-size(14);
		th {
			text-align: left;
			vertical-align: top;
			min-width: 115px;
			padding-right: 0.5em;
		}
	}
}

.sale-flow {
	padding: 0 0 clamp(6rem, 12vw, 12rem);
	background: $p-gray;
	&__inner {
	}
	&__tit {
		text-align: center;
		position: relative;
		top: -1.5em;
		margin-bottom: calc(2rem + 1em);
		@include media-breakpoint-up(sm) {
			top: -2rem;
		}
		em {
			@include f-family(font02);
			@include l-sp(0.1em);
			font-style: normal;
			display: block;
			color: $main_c;
			@include f-w(700);
			position: relative;
			line-height: 1.2;
			@include f-s_xs(2.25, 28);
			@include media-breakpoint-up(xl) {
				@include f-size(60);
			}
		}
		span {
			@include f-family(font01);
			@include l-sp(0.1em);
			display: block;
			@include f-w(700);
			position: relative;
			line-height: 1.4;
			@include f-s_xs(1.14, 10);
			@include media-breakpoint-up(xl) {
				@include f-size(28);
			}
		}
	}
	&__list {
		counter-reset: flow-num;
		@include media-breakpoint-up(xl) {
			@include flex-wrap;
			column-gap: 1.8625%;
			row-gap: 4rem;
			& > * {
				width: 18.51%;
			}
		}
		@include media-breakpoint-down(lg) {
			@include flex-column;
			justify-content: center;
			align-items: center;
			row-gap: clamp(3rem, 6vw, 6rem);
			& > * {
				width: 100%;
				max-width: 960px;
			}
		}
	}
}

.sale-flow__box {
	position: relative;
	&:not(:last-of-type) {
		&::before {
			position: absolute;
			content: "";
			@include media-breakpoint-up(xl) {
				top: 50%;
				left: 100%;
				width: 80%;
				height: 1px;
				background-size: 8px 1px;
				background-image: linear-gradient(to right, $main_c, $main_c 3px, transparent 3px, transparent 8px);
			}
			@include media-breakpoint-down(lg) {
				width: 1px;
				height: 80%;
				top: 100%;
				left: calc(50% + 15px);
				background-size: 1px 8px;
				background-image: linear-gradient(to bottom, $main_c, $main_c 3px, transparent 3px, transparent 8px);
			}
			@include media-breakpoint-down(xs) {
				left: calc(50% + 10px);
			}
		}
	}
	&:nth-of-type(5n) {
		&::before {
			@include media-breakpoint-up(xl) {
				display: none;
			}
		}
	}
	&__inner {
		width: calc(100% - 20px);
		margin-left: 20px;
		height: 100%;
		border: 1px solid $main_c;
		background: $white;
		position: relative;
		z-index: z-index(module, part03);
		padding: 1.5em 8% 2em;
		display: flex;
		flex-direction: column;
		@include media-breakpoint-between(sm, lg) {
			flex-direction: row;
			column-gap: 4%;
			padding: 1.5em 8% 1.5em 80px;
		}
		@include media-breakpoint-up(sm) {
			width: calc(100% - 30px);
			margin-left: 30px;
		}
		@include media-breakpoint-up(xl) {
			padding: 1.5em 8% 2em;
		}
		@include media-breakpoint-up(xxl) {
			padding: 1.5em 10% 2em;
		}
	}
	&__num {
		position: absolute;
		top: -20px;
		left: -20px;
		width: 80px;
		height: 80px;
		background: $main_c;
		z-index: z-index(module, part02);
		@include media-breakpoint-up(sm) {
			top: -30px;
			left: -30px;
		}
		span {
			position: relative;
			width: 100%;
			height: 100%;
			color: $white;
			display: block;
			&::before {
				counter-increment: flow-num;
				content: counter(flow-num, decimal-leading-zero);
				@include f-family(font02);
				@include f-w(700);
				@include line-h(1);
				@include l-sp(0.05em);
				position: absolute;
				top: calc(50% - 0.5em);
				left: calc(50% - 0.65em);
				@include f-size(36);
			}
		}
	}
	&__img {
		width: 120px;
		height: 120px;
		@include m-a;
		margin-bottom: 1rem;
		flex-shrink: 0;
		&__inner {
			@include aspect-img(1, 1);
		}
	}
	&__tit {
		@include f-w(700);
		@include f-s_xs(1, 4);
		margin-bottom: 0.4em;
		@include media-breakpoint-up(xl) {
			@include f-size(20);
		}
	}
}

/* sale-slider */
.sale-slider {
	position: relative;
	height: auto;
	margin-bottom: clamp(3rem, 4vw, 4rem);
	@include media-breakpoint-up(lg) {
		@include flex-between;
	}
}

@import "../_plugins/slick"; //slick

/* gallery-top */
.gallery-top {
	position: relative;
	@include media-breakpoint-up(lg) {
		width: 51.94%;
		max-width: 800px;
	}
	@include media-breakpoint-down(md) {
		margin-bottom: 1.5rem;
	}
	&__inner {
		width: 100%;
		position: relative;
	}
	& .slick-arrow {
		border: 1px solid $m-gray;
		background: rgba($white, 0.8);
		display: flex;
		align-items: center;
		@include transition;
		@include radius(50%);
		&:hover {
			background: rgba($point_c, 0.8);
		}
		& i {
			display: flex;
			justify-content: center;
			align-items: center;
			@include f-size(20);
			width: 30px;
			height: 30px;
			@include media-breakpoint-up(lg) {
				width: 40px;
				height: 40px;
			}
			&::before {
				color: $m-gray;
			}
		}
	}
	& .slick-prev {
		&::before {
			display: none;
		}
	}
	& .slick-next {
		&::before {
			display: none;
		}
	}
	.sp-slide {
		position: relative;
		overflow: hidden;
		&__inner {
			@include aspect-img();
			.is-gray {
				filter: grayscale(100%);
			}
		}
		& figcaption {
			margin-top: 0.75rem;
			color: $txt_c;
			line-height: 1.4;
			z-index: 1;
		}
	}
}

/* gallery-thumbs */
.gallery-thumbs {
	position: relativve;
	@include media-breakpoint-up(lg) {
		width: 42.2%;
	}
	&__inner {
		width: 100%;
		position: relative;
	}
}

.gallery-thumbs__list {
	@include flex-wrap;
	@include media-breakpoint-up(lg) {
		column-gap: 1.538%;
		row-gap: 0.64vw;
		& > * {
			width: 15.384%;
		}
	}
	@include media-breakpoint-up(xl) {
		row-gap: 10px;
	}
	@include media-breakpoint-down(md) {
		column-gap: 2%;
		row-gap: 1.95vw;
		& > * {
			width: 15%;
		}
	}
	& .sp-thumbnail {
		@include transition;
		overflow: hidden;
		.is-gray {
			filter: grayscale(100%);
		}
		& img {
			@include transition;
		}
		&:hover {
			img {
				transform: scale(1.1, 1.1);
			}
		}
		&__inner {
			@include aspect-img(1, 1);
		}
	}
}

//sale-main
.sale-main {
	margin-bottom: clamp(3rem, 4vw, 4rem);
	&__img {
		max-width: 800px;
		@include m-a;
		&__inner {
			@include aspect-img();
			img {
				height: 100%;
				@include m-a;
				-o-object-fit: contain;
				object-fit: contain;
			}
		}
		figcaption {
			margin-top: 0.7rem;
		}
	}
}

/* sale-text */
.sale-text {
	background: $o-white;
	padding: 2em 5%;
	margin-bottom: clamp(2rem, 3vw, 3rem);
	@include media-breakpoint-up(md) {
		padding: 3.5em 8%;
	}
}

//sale-contact
.sale-contact {
	margin-top: clamp(4rem, 9vw, 9rem);
	border: 1px solid $main_c;
	background: $white;
	&__inner {
		padding: 2em 5%;
		@include media-breakpoint-up(lg) {
			padding: 3.5em 8%;
		}
	}
	&__txt {
		margin-bottom: clamp(1.5rem, 2vw, 2rem);
		@include media-breakpoint-up(lg) {
			text-align: center;
		}
		p {
			@include f-w(500);
			@include f-s_xs(0.94, 4);
			@include media-breakpoint-up(xl) {
				@include f-size(20);
			}
			small {
				@include f-w(400);
			}
		}
	}
	&__flex {
		display: flex;
		@include media-breakpoint-up(md) {
			justify-content: center;
			column-gap: 5.27%;
			& > * {
				width: 47.36%;
				max-width: 520px;
			}
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: 1rem;
		}
	}
	&__btn {
		a {
			height: 72px;
			@include transition;
			@include dec-none;
			color: $white;
			@include f-w(700);
			display: flex;
			justify-content: center;
			align-items: center;
			@include line-h(1.2);
			padding: 0.2em 2%;
			@include f-s_xs(0.9, 3);
			@include media-breakpoint-up(md) {
				@include f-s_md(0.94, 3);
				height: 96px;
			}
			@include media-breakpoint-up(xl) {
				@include f-size(18);
			}
			&.is-contact {
				background: $main_c;
				&:hover {
					background: $point_c;
				}
				span {
					@include icon(mail);
					padding-left: 2.5rem;
					position: relative;
					&::before {
						position: absolute;
						top: 50%;
						left: 0;
						transform: translateY(-50%);
						font-size: 1.5em;
					}
				}
			}
			&.is-line {
				background: $line_c;
				&:hover {
					background: lighten($line_c, 10%);
				}
				span {
					@include icon(line);
					padding-left: 2.75rem;
					position: relative;
					&::before {
						position: absolute;
						top: 50%;
						left: 0;
						transform: translateY(-50%);
						font-size: 1.75em;
					}
				}
			}
		}
	}
}

//sale-outline
.sale-outline {
	margin-bottom: clamp(2rem, 3vw, 3rem);
	&__list {
		border-top: 1px solid $other_c1;
		border-right: 1px solid $other_c1;
		@include media-breakpoint-up(md) {
			@include flex-wrap;
			& > * {
				width: 50%;
			}
		}
	}
	&__row {
		display: flex;
		height: 100%;
		border-bottom: 1px solid $other_c1;
		&.is-last {
			width: 100%;
			flex-shrink: 0;
			dt {
				@include media-breakpoint-up(md) {
					width: 14.28%;
				}
			}
			dd {
				@include media-breakpoint-up(md) {
					width: 85.72%;
				}
			}
		}
		dt {
			width: 28.57%;
			min-width: 150px;
			max-width: 220px;
			background: $other_c1;
			height: 100%;
			flex-shrink: 0;
			padding: 1em;
		}
		dd {
			width: 71.43%;
			background: $white;
			height: 100%;
			flex-shrink: 1;
			padding: 1em;
		}
	}
}

// sale-list
.pdf-list {
	margin-bottom: clamp(2rem, 3vw, 3rem);
	background: $main_c;
	padding: 2em 5%;
	@include media-breakpoint-up(lg) {
		padding: 3em 8%;
	}
	&__inner {
		@include media-breakpoint-up(lg) {
			@include flex-wrap;
			column-gap: 2%;
			row-gap: 1rem;
			& > * {
				width: 49%;
			}
		}
		& li {
			&:not(:last-of-type) {
				@include media-breakpoint-down(md) {
					margin-bottom: 1rem;
				}
			}
			a {
				padding: 1rem 4% 1rem 4rem;
				@include transition;
				background: $white;
				position: relative;
				width: 100%;
				height: 100%;
				display: block;
				color: $txt_c;
				@include dec-none;
				@include f-w(500);
				@include icon(pdf);
				&::before {
					position: absolute;
					left: 1.25rem;
					@include f-em(30);
					color: $gray;
				}
				&:hover {
					background: $gray;
					border-color: $gray;
					color: $white;
					&::before {
						color: $white;
					}
				}
			}
		}
	}
}

/*--------------------------------------------------------------------/
	common-parts
/--------------------------------------------------------------------*/
/* cotegory */
.category {
	&-list {
		margin-bottom: clamp(2rem, 3vw, 3rem);
		@include media-breakpoint-up(md) {
			display: flex;
		}
		line-height: 1.4;
		&__tit {
			@include media-breakpoint-up(md) {
				position: relative;
				padding-top: 0.3rem;
				padding-left: 0.5rem;
				padding-right: 0.75rem;
				@include f-family(font02);
				@include f-w(700);
				color: $black;
				flex-shrink: 0;
			}
			@include media-breakpoint-down(sm) {
				margin-bottom: 0.5rem;
			}
		}
		& .category-item {
			@include f-size(12);
			padding: 0.3rem 1rem;
			min-width: 140px;
			vertical-align: top;
			@include media-breakpoint-up(md) {
				@include f-size(14);
			}
		}
		&__items {
			font-size: 0;
			& > * {
				display: inline-block;
				margin-bottom: 0.2rem;
				margin-right: 0.2rem;
				@include f-em(16);
			}
			a {
				@include dec-none;
				@include transition;
				&:hover {
					opacity: 0.8;
				}
			}
		}
	}
	&-item {
		display: inline-block;
		background-color: $dl-gray;
		color: $white;
		text-align: center;
		line-height: 1.4;
		padding: 0.2rem 0.75rem;
		@include f-size(12);
		@include l-sp(0.05em);
		&--black {
			background-color: $black;
		}
	}
}
