@charset "utf-8";
@import "base";
/*====================================================================

lv2.css

=====================================================================*/
/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
.main-view {
	position: relative;
	background: $sub_c;
	overflow: hidden;
	height: clamp(260px, 56.96vw, 360px);
	@include media-breakpoint-up(lg) {
		height: 430px;
	}
	@include media-breakpoint-down(sm) {
	}
	&__add {
		position: absolute;
		bottom: 2rem;
		left: 30%;
		width: 74%;
		@include media-breakpoint-up(md) {
			left: 40%;
			width: 64%;
		}
		@include media-breakpoint-up(lg) {
			left: 52%;
			bottom: 4rem;
			width: 54%;
		}
	}
	& .l-cont {
		position: relative;
		height: 100%;
		display: flex;
		align-items: center;
	}
	&__tit {
		text-align: left;
		@include f-w(400);
		color: $white;
		em {
			@include f-family(font02);
			@include l-sp(0.05em);
			font-style: normal;
			display: block;
			@include f-w(700);
			position: relative;
			line-height: 1.2;
			@include f-s_xs(2, 40);
			@include media-breakpoint-up(xl) {
				@include f-size(72);
			}
		}
		&__txt {
			@include f-family(font01);
			@include l-sp(0.1em);
			display: block;
			@include f-w(700);
			position: relative;
			line-height: 1.4;
			@include f-s_xs(1.14, 10);
			@include media-breakpoint-up(xl) {
				@include f-size(28);
			}
		}
	}
	&__term {
		display: block;
		padding-left: 0.2em;
		margin-top: 1em;
		@include f-s_xs(0.85, 5);
		@include media-breakpoint-up(xl) {
			@include f-size(18);
		}
		b {
			color: $white;
		}
	}
	&__archive {
		@include f-family(font01);
		@include l-sp(0.1em);
		display: block;
		@include f-w(700);
		position: relative;
		line-height: 1.4;
		@include f-s_xs(1.25, 16);
		@include media-breakpoint-up(xl) {
			@include f-size(36);
		}
	}
	&__sub {
		display: flex;
		column-gap: .5em;
		margin-bottom: clamp(1rem,2vw,1.5rem);
	}
	&__date {
		@include f-family(font02);
		@include l-sp(0.05em);
		@include f-w(700);
		color: $other_c3;
		@include f-s_xs(0.85,14);
		@include media-breakpoint-up(xl) {
			@include f-size(28);
		}
	}
	&__nam {
		display: inline-block;
		background: $red;
		color: $white;
		padding:0.2rem 1rem;
		@include f-family(font02);
		@include f-w(700);
		@include l-sp(0);
		@include f-s_xs(0.85,6);
		@include media-breakpoint-up(xl) {
			@include f-size(20);
		}
	}
	&__category {
		.category-item {
			@include f-s_xs(0.85,6);
			@include f-w(700);
			@include media-breakpoint-up(xl) {
				@include f-size(20);
			}
		}
	}
}

.contents {
	padding-top: clamp(2rem, 5vw, 80px);
	overflow: hidden;
}

/*--------------------------------------------------------------------/
	single-page
/--------------------------------------------------------------------*/
.single-cont {
	&__txt {
		margin-bottom: 4rem;
		@include clearfix;
		@include media-breakpoint-up(md) {
			margin-bottom: 6rem;
		}
	}
	&__thumbnail {
		float: right;
		width: 50%;
		margin-left: 6%;
		margin-bottom: 3rem;
	}
}

.single-sub {
	margin-bottom: 1.5rem;
	@include media-breakpoint-up(sm) {
		margin-bottom: 2rem;
		display: flex;
		align-items: flex-start;
	}
}
.single-date {
	@include f-family(font01);
	line-height: 1.4;
	color: $gray;
	margin-right: 1rem;
}

@import "_components/breadcrumb"; //パンくずリスト
@import "_plugins/lightbox"; //lightbox
@import "_lv2/news"; //ブログ
@import "_lv2/aside"; //サイドバー
@import "_lv2/sale"; //ポンプ車販売
@import "_lv2/purchase"; //ポンプ車買取
@import "_lv2/rental"; //ポンプ車レンタル
@import "_lv2/fixtures"; //パーツ販売
@import "_lv2/training"; //操作研修サービス
@import "_lv2/ecosuru"; //次世代先行剤エコスルシリーズ
@import "_lv2/transmitter"; //送信機(ラジコン)買取
@import "_lv2/company"; //会社概要
@import "_lv2/contact"; //お問い合わせ
