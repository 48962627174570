@charset "UTF-8";
/* webfont 変数　*/
/* ofi */
@media (min-width: 1825px) {
  /*====================================================================/
/*--Large PC 以上--
/====================================================================*/
}

@media (min-width: 1637px) {
  /*====================================================================/
/*---PC 以上--
/====================================================================*/
}

@media (min-width: 1057px) {
  /*====================================================================/
/*--XGAサイズ　iPadPro 以上--
/====================================================================*/
}

@media (min-width: 768px) {
  /*====================================================================/
/*--タブレット　iPadmini 以上--
/====================================================================*/
}

@media (min-width: 576px) {
  /*====================================================================/
/*--スマホ 以上--
/====================================================================*/
}

@media (min-width: 376px) {
  /*====================================================================/
/*--iPhone 以上--
/====================================================================*/
}

@media (max-width: 1824px) {
  /*====================================================================/
/*--Large PC 未満--
/====================================================================*/
}

@media (max-width: 1636px) {
  /*====================================================================/
/*--PC 未満--
/====================================================================*/
}

@media (max-width: 1056px) {
  /*====================================================================/
/*--XGAサイズ iPadPro 未満--
/====================================================================*/
}

@media (max-width: 767px) {
  /*====================================================================/
/*--タブレット iPadmini 未満--
/====================================================================*/
}

@media (max-width: 575px) {
  /*====================================================================/
/*--iPhone 以下--
/====================================================================*/
}

@media (min-width: 576px) and (max-width: 767px) {
  /*====================================================================/
/*--スマホ以上 タブレット iPadmini未満--
/====================================================================*/
}

@media (min-width: 768px) and (max-width: 1056px) {
  /*====================================================================/
/*--タブレット iPadmini以上 XGAサイズ iPadPro未満--
/====================================================================*/
}

@media (min-width: 1057px) and (max-width: 1636px) {
  /*====================================================================/
/*--XGAサイズ iPadPro以上 PC未満--
/====================================================================*/
}

@media (min-width: 1637px) and (max-width: 1824px) {
  /*====================================================================/
/*--px以上 Large PC未満--
/====================================================================*/
}

/*====================================================================

lv2.css

=====================================================================*/
/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
.main-view {
  position: relative;
  background: #0E0F53;
  overflow: hidden;
  height: clamp(260px, 56.96vw, 360px);
}

@media (min-width: 1057px) {
  .main-view {
    height: 430px;
  }
}

.main-view__add {
  position: absolute;
  bottom: 2rem;
  left: 30%;
  width: 74%;
}

@media (min-width: 768px) {
  .main-view__add {
    left: 40%;
    width: 64%;
  }
}

@media (min-width: 1057px) {
  .main-view__add {
    left: 52%;
    bottom: 4rem;
    width: 54%;
  }
}

.main-view .l-cont {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}

.main-view__tit {
  text-align: left;
  font-weight: 400;
  color: #fff;
}

.main-view__tit em {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
  font-style: normal;
  display: block;
  font-weight: 700;
  position: relative;
  line-height: 1.2;
  font-size: calc(2rem + 40 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .main-view__tit em {
    font-size: 4.5rem;
  }
}

.main-view__tit__txt {
  font-family: 'Noto Sans JP', sans-serif;
  letter-spacing: 0.1em;
  display: block;
  font-weight: 700;
  position: relative;
  line-height: 1.4;
  font-size: calc(1.14rem + 10 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .main-view__tit__txt {
    font-size: 1.75rem;
  }
}

.main-view__term {
  display: block;
  padding-left: 0.2em;
  margin-top: 1em;
  font-size: calc(0.85rem + 5 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .main-view__term {
    font-size: 1.13rem;
  }
}

.main-view__term b {
  color: #fff;
}

.main-view__archive {
  font-family: 'Noto Sans JP', sans-serif;
  letter-spacing: 0.1em;
  display: block;
  font-weight: 700;
  position: relative;
  line-height: 1.4;
  font-size: calc(1.25rem + 16 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .main-view__archive {
    font-size: 2.25rem;
  }
}

.main-view__sub {
  display: flex;
  column-gap: .5em;
  margin-bottom: clamp(1rem, 2vw, 1.5rem);
}

.main-view__date {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
  font-weight: 700;
  color: #D9E5FF;
  font-size: calc(0.85rem + 14 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .main-view__date {
    font-size: 1.75rem;
  }
}

.main-view__nam {
  display: inline-block;
  background: #CE0000;
  color: #fff;
  padding: 0.2rem 1rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  letter-spacing: 0;
  font-size: calc(0.85rem + 6 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .main-view__nam {
    font-size: 1.25rem;
  }
}

.main-view__category .category-item {
  font-size: calc(0.85rem + 6 * (100vw -  376px) / 1164);
  font-weight: 700;
}

@media (min-width: 1637px) {
  .main-view__category .category-item {
    font-size: 1.25rem;
  }
}

.contents {
  padding-top: clamp(2rem, 5vw, 80px);
  overflow: hidden;
}

/*--------------------------------------------------------------------/
	single-page
/--------------------------------------------------------------------*/
.single-cont__txt {
  margin-bottom: 4rem;
}

.single-cont__txt::after {
  display: block;
  content: "";
  clear: both;
}

@media (min-width: 768px) {
  .single-cont__txt {
    margin-bottom: 6rem;
  }
}

.single-cont__thumbnail {
  float: right;
  width: 50%;
  margin-left: 6%;
  margin-bottom: 3rem;
}

.single-sub {
  margin-bottom: 1.5rem;
}

@media (min-width: 576px) {
  .single-sub {
    margin-bottom: 2rem;
    display: flex;
    align-items: flex-start;
  }
}

.single-date {
  font-family: 'Noto Sans JP', sans-serif;
  line-height: 1.4;
  color: #979DA4;
  margin-right: 1rem;
}

/*--------------------------------------------------------------------/
	breadcrumb
/--------------------------------------------------------------------*/
.breadcrumb {
  position: absolute;
  display: block;
  top: 1rem;
  right: 5%;
  width: calc(100% - (5%* 2));
}

@media (min-width: 1057px) {
  .breadcrumb {
    right: 40px;
    width: calc(100% - (40px* 2));
  }
}

.breadcrumb__list {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.84em;
  width: 100%;
}

@media (min-width: 768px) {
  .breadcrumb__list {
    justify-content: flex-end;
  }
}

.breadcrumb__list .item-home {
  font-size: 1.2em;
}

.breadcrumb__list li {
  position: relative;
  color: #6768AF;
}

.breadcrumb__list li:not(:last-child) {
  padding-right: 1.5em;
}

.breadcrumb__list li:not(:last-child):after {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.breadcrumb__list li:not(:last-child)::after {
  position: absolute;
  right: 0.3em;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.8em;
}

.breadcrumb__list a {
  color: #6768AF;
  transition: all 0.3s ease 0s;
  vertical-align: top;
  text-decoration: none !important;
}

.breadcrumb__list a:first-child {
  text-decoration: none !important;
}

.breadcrumb__list a:hover {
  color: #EBF1FF;
}

/* Preload images */
body:after {
  content: url(../img/common/close.png) url(../img/common/loading.gif) url(../img/common/prev.png) url(../img/common/next.png);
  display: none;
}

body.lb-disable-scrolling {
  overflow: hidden;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none;
}

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
}

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;
  /* Image border */
  border: 4px solid white;
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  position: relative;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
  /* Background color behind image.
     This is visible during transitions. */
  background-color: white;
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../img/common/loading.gif) no-repeat;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
}

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
}

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(../img/common/prev.png) left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(../img/common/next.png) right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-data {
  padding: 0 4px;
  color: #ccc;
}

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
  padding-top: .5rem;
}

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
}

.lb-data .lb-caption a {
  color: #4ae;
}

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999;
}

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(../img/common/close.png) top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/*--------------------------------------------------------------------/
	news
/--------------------------------------------------------------------*/
.contents-flex {
  display: flex;
}

@media (min-width: 1057px) {
  .contents-flex {
    justify-content: space-between;
  }
}

@media (max-width: 1056px) {
  .contents-flex {
    flex-direction: column;
    row-gap: clamp(3rem, 5vw, 5rem);
  }
}

.contents-left {
  width: 100%;
}

@media (min-width: 1057px) {
  .contents-left {
    width: 71.42%;
  }
}

.news-interview {
  padding-bottom: clamp(3rem, 5vw, 5rem);
}

.news-interview__flex {
  display: flex;
}

@media (min-width: 768px) {
  .news-interview__flex {
    justify-content: space-between;
  }
  .news-interview__flex.is-right {
    flex-direction: row-reverse;
  }
}

@media (max-width: 767px) {
  .news-interview__flex {
    flex-direction: column;
    row-gap: 0.75rem;
  }
}

@media (min-width: 768px) {
  .news-interview__flex .news-interview__img {
    width: 38%;
  }
}

.news-interview__flex .news-interview__img__inner {
  overflow: hidden;
}

@media (min-width: 768px) {
  .news-interview__flex .news-interview__txt {
    width: 58%;
  }
}

.news-video {
  padding-bottom: clamp(3rem, 5vw, 5rem);
}

.news-video__tit {
  margin-bottom: calc(1.5rem + 0.3em);
  font-size: calc(1rem + 8 * (100vw -  376px) / 1164);
  font-weight: 600;
  position: relative;
  overflow: hidden;
}

@media (min-width: 1637px) {
  .news-video__tit {
    font-size: 1.5rem;
  }
}

.news-video__tit span {
  position: relative;
  display: inline-block;
  padding-right: 1em;
  z-index: 2;
}

.news-video__tit span::before {
  content: "";
  width: 100vw;
  height: 1px;
  background: #C9CED2;
  position: absolute;
  top: 50%;
  left: 100%;
  z-index: 1;
}

@media (min-width: 1057px) {
  .news-video__flex {
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 1057px) {
  .news-video__video {
    flex-basis: 37.5%;
  }
}

@media (max-width: 1056px) {
  .news-video__video {
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;
    margin-bottom: 1rem;
  }
}

.news-video__video__inner {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  border-radius: 8px;
  overflow: hidden;
  z-index: 1;
}

.news-video__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 1057px) {
  .news-video__txt {
    flex-basis: 58%;
  }
}

/* news-table */
.news-table {
  padding-bottom: clamp(3rem, 5vw, 5rem);
}

.news-table__tit {
  text-align: center;
  font-size: calc(1rem + 8 * (100vw -  376px) / 1164);
  font-weight: 600;
  margin-bottom: calc(1.5rem + 0.3em);
}

@media (min-width: 1637px) {
  .news-table__tit {
    font-size: 1.5rem;
  }
}

/*--------------------------------------------------------------------/
	single-page
/--------------------------------------------------------------------*/
.single-cont {
  padding-bottom: clamp(3rem, 6vw, 6rem);
}

.single-cont__inner {
  position: relative;
}

.single-cont__inner::after {
  display: block;
  content: "";
  clear: both;
}

.single-cont__addtoany {
  margin-top: 2rem;
  border: 1px solid #C9CED2;
  padding: 1em 1.5em;
}

.single-cont__addtoany .addtoany_shortcode > div {
  line-height: 1 !important;
}

.single-cont__tag {
  padding-top: 1em;
}

.single-cont__tag a {
  font-size: 1rem !important;
  color: #1D2087;
  padding-left: 1.5em;
  margin-right: 0.5rem;
  position: relative;
  text-decoration: none !important;
}

.single-cont__tag a:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.single-cont__tag a::before {
  position: absolute;
  top: 0.6em;
  left: 0.2rem;
  font-size: 0.85em;
  color: #1D2087;
  text-decoration: none !important;
}

.single-cont__tag a:hover {
  text-decoration: underline !important;
}

.wp-block-gallery {
  margin-bottom: 2em;
}

.wp-block-image {
  overflow: hidden;
}

.wp-block-file {
  margin-bottom: 2em;
}

.wp-block-columns {
  margin-bottom: clamp(2rem, 4vw, 4rem) !important;
}

.wp-block-columns h2 {
  margin-bottom: 0.75em !important;
  padding-bottom: 0.5em !important;
  border-bottom: 1px solid #1D2087;
  font-size: calc(1rem + 4 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .wp-block-columns h2 {
    font-size: 1.25rem;
  }
}

/*--------------------------------------------------------------------/
	aside
/--------------------------------------------------------------------*/
@media (min-width: 1057px) {
  .aside {
    width: 20.77%;
  }
}

.widget-blog-aside:not(:last-child) {
  margin-bottom: 2.5rem;
}

.widget-blog-aside__tit {
  padding: 0.3rem 0 0.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #1D2087;
  font-weight: 500;
  font-size: 1.28em;
}

@media (min-width: 576px) {
  .widget-blog-aside__tit {
    font-size: 1.2em;
  }
}

@media (min-width: 1057px) {
  .widget-blog-aside__tit {
    font-size: 1.12em;
  }
}

.widget-blog-aside ul li {
  line-height: 1.4;
}

.widget-blog-aside ul li:not(:last-of-type) {
  margin-bottom: .5em;
}

.widget-blog-aside ul li a {
  padding-left: 1.5em;
  color: #232323;
  position: relative;
  text-decoration: none !important;
  display: block;
}

.widget-blog-aside ul li a::before {
  position: absolute;
  top: .3em;
  left: .2em;
  content: "●";
  vertical-align: baseline;
  font-size: 0.85em;
  color: #1D2087;
  text-decoration: none !important;
}

@media (min-width: 576px) {
  .widget-blog-aside ul li a::before {
    font-size: 0.8em;
  }
}

@media (min-width: 1057px) {
  .widget-blog-aside ul li a::before {
    font-size: 0.75em;
  }
}

.widget-blog-aside ul li a:hover {
  text-decoration: underline !important;
}

.tag-cloud-link {
  font-size: 1rem !important;
  color: #232323;
  padding-left: 1.5em;
  margin-right: .5rem;
  position: relative;
  text-decoration: none !important;
}

.tag-cloud-link:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tag-cloud-link::before {
  position: absolute;
  top: 0.6em;
  left: .2rem;
  font-size: 1em;
  color: #1D2087;
  text-decoration: none !important;
}

@media (min-width: 576px) {
  .tag-cloud-link::before {
    font-size: 0.93em;
  }
}

@media (min-width: 1057px) {
  .tag-cloud-link::before {
    font-size: 0.87em;
  }
}

.tag-cloud-link:hover {
  text-decoration: underline !important;
}

p.wp-block-tag-cloud {
  font-size: 1em;
  line-height: 1.5;
}

@media (min-width: 576px) {
  p.wp-block-tag-cloud {
    font-size: 0.93em;
  }
}

@media (min-width: 1057px) {
  p.wp-block-tag-cloud {
    font-size: 0.87em;
  }
}

.wp-block-archives-dropdown label {
  display: none !important;
}

.wp-block-archives-dropdown select {
  width: 90%;
}

@media (max-width: 767px) {
  .wp-block-archives-dropdown select {
    width: 100%;
  }
}

/* calendar
------------------------------------*/
#wp-calendar {
  border-collapse: separate;
  width: 100%;
}

.calendar_wrap {
  padding: 0.5rem 0.75rem;
  border: 1px solid #C9CED2;
}

.wp-calendar-table {
  margin-bottom: 0.75rem;
}

.wp-calendar-table caption {
  padding: 0.3rem 2%;
}

.wp-calendar-table thead th {
  text-align: center;
  padding: 6px;
  width: auto;
  color: #979DA4;
  font-weight: 500;
}

.wp-calendar-table td {
  text-align: center;
  background: #F3F5F7;
  line-height: 2;
  border: 2px solid #fff;
  font-size: 1.07em;
}

@media (min-width: 576px) {
  .wp-calendar-table td {
    font-size: 1em;
  }
}

@media (min-width: 1057px) {
  .wp-calendar-table td {
    font-size: 0.93em;
  }
}

.wp-calendar-table td a {
  background-color: #1D2087;
  color: #fff;
  transition: all 0.3s ease 0s;
  display: block;
  text-decoration: none !important;
}

.wp-calendar-table td a:hover {
  background-color: #0E0F53;
}

.wp-calendar-table td.pad {
  background: transparent;
}

.wp-calendar-table td#today {
  background: #979DA4;
  color: #fff;
}

.wp-calendar-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 0.3rem;
}

.wp-calendar-nav a {
  display: block;
  color: #232323;
  padding: 0.4rem 1rem;
  background-color: #F3F5F7;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
}

.wp-calendar-nav a:hover {
  background-color: #979DA4;
  color: #fff;
}

/*--------------------------------------------------------------------/
	search-form
/--------------------------------------------------------------------*/
.search-form {
  position: relative;
  width: 100%;
  border: 1px solid #979DA4;
  display: flex;
  justify-content: space-between;
}

.search-form__txt {
  height: 40px;
  padding: 0 10px;
  outline: 0;
  background: #fff;
  appearance: none;
  border: none;
  width: calc(100% - 50px);
}

.search-form__btn {
  height: 40px;
  width: 50px;
  padding: 2px;
  background: none;
  font-size: 2em;
  vertical-align: bottom;
  color: #C9CED2;
  background: #fff;
  transition: all 0.3s ease 0s;
  border: none;
}

.search-form__btn i {
  font-size: 1.25rem;
}

.search-form__btn:hover {
  color: #113AAC;
}

/*--------------------------------------------------------------------/
	sale
/--------------------------------------------------------------------*/
.sale-reason {
  padding-top: clamp(3rem, 5vw, 7rem);
  padding-bottom: clamp(4rem, 12vw, 12rem);
  position: relative;
}

@media (min-width: 1057px) {
  .sale-reason {
    padding-top: 7rem;
    padding-bottom: clamp(4rem, 12vw, 12rem);
  }
}

.sale-reason::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 5vw);
  background: #1D2087;
}

@media (min-width: 1057px) {
  .sale-reason::before {
    height: calc(100% - 5.5rem);
  }
}

.sale-reason::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  background: #0E0F53;
  width: 90%;
  height: calc(100% - 8vw);
}

@media (min-width: 1057px) {
  .sale-reason::after {
    height: calc(100% - 9.5rem);
  }
}

@media (min-width: 1637px) {
  .sale-reason::after {
    width: calc(50% + 746px);
  }
}

.sale-reason__add {
  position: absolute;
  bottom: 0;
  right: 5%;
  z-index: 1;
}

.sale-reason__add span {
  font-family: 'Montserrat', sans-serif;
  color: #1C1D61;
  font-weight: 700;
  letter-spacing: 0.05em;
  font-size: calc(3rem + 140 * (100vw -  376px) / 1164);
  line-height: 0.85;
}

@media (min-width: 1637px) {
  .sale-reason__add span {
    font-size: calc(10rem + 40 * (100vw - 1540px) / 188);
  }
}

@media (min-width: 1825px) {
  .sale-reason__add span {
    font-size: 12.5rem;
  }
}

.sale-reason__inner {
  position: relative;
  z-index: 2;
}

.sale-reason__tit {
  text-align: left;
  color: #000;
  margin-bottom: clamp(1.5rem, 3vw, 3rem);
}

.sale-reason__tit__inner {
  display: inline-block;
  background: #fff;
  position: relative;
  padding: 1.25em 5% 1.25em 0;
}

.sale-reason__tit__inner::before {
  content: "";
  position: absolute;
  top: 0;
  right: 100%;
  background: #fff;
  width: 100%;
  height: 100%;
}

.sale-reason__tit__inner small {
  font-family: 'Noto Sans JP', sans-serif;
  letter-spacing: 0.05em;
  margin-bottom: 0.5em;
  display: block;
  font-weight: 700;
  position: relative;
  line-height: 1.4;
  font-size: calc(0.8rem + 12 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .sale-reason__tit__inner small {
    font-size: 1.5rem;
  }
}

.sale-reason__tit__inner strong {
  letter-spacing: 0.1em;
  display: block;
  font-weight: 700;
  position: relative;
  line-height: 1.2;
  font-size: calc(2rem + 30 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .sale-reason__tit__inner strong {
    font-size: 4rem;
  }
}

.sale-reason__tit__inner strong b {
  display: inline-block;
  background: #CE0000;
  color: #fff;
  font-weight: 900;
  font-size: 1.5em;
  padding: 0 0.2em;
}

.sale-reason__box {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  background: #fff;
  margin-bottom: 1.5rem;
}

@media (min-width: 1057px) {
  .sale-reason__box {
    margin-bottom: 2.5rem;
  }
}

.sale-reason__box__txt {
  padding: 0 8% 1.5em;
}

.sale-reason__box__tit {
  font-weight: 700;
  margin-bottom: 0.75em;
  padding: 0.75em 7%;
  background: #1D2087;
  position: relative;
  color: #fff;
  overflow: hidden;
  font-size: calc(0.94rem + 4 * (100vw -  376px) / 1164);
  margin-top: -1.5rem;
  z-index: 1;
}

@media (min-width: 1057px) {
  .sale-reason__box__tit {
    font-size: calc(0.94rem + 2 * (100vw - 1000px) / 540);
  }
}

@media (min-width: 1637px) {
  .sale-reason__box__tit {
    font-size: 1.13rem;
  }
}

.sale-reason__box__tit::before {
  content: "";
  background: #30339B;
  width: 80%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 50%;
  transform: skew(-20deg);
}

.sale-reason__box__tit__inner {
  position: relative;
  z-index: 2;
}

.sale-reason__box__img__inner {
  position: relative;
  width: 100%;
}

.sale-reason__box__img__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}

.sale-reason__box__img__inner > div,
.sale-reason__box__img__inner figure,
.sale-reason__box__img__inner a,
.sale-reason__box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.sale-list {
  padding: clamp(4rem, 8vw, 8rem) 0 clamp(5rem, 8vw, 8rem);
  position: relative;
}

.sale-list__add {
  position: absolute;
  top: 5rem;
  left: 50%;
  width: 70%;
  max-width: 840px;
}

@media (min-width: 768px) {
  .sale-list__add {
    left: 70%;
    width: 70%;
    max-width: 840px;
  }
}

@media (min-width: 1637px) {
  .sale-list__add {
    left: calc(50% + 300px);
    width: 840px;
  }
}

@media (max-width: 767px) {
  .sale-list__add {
    display: none;
  }
}

.sale-list__inner {
  position: relative;
  z-index: 2;
}

.sale-list__tit {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 2px;
  margin-bottom: clamp(2rem, 3vw, 3rem);
}

.sale-list__tit > span {
  width: 60px;
  height: 60px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1D2087;
  font-size: 2rem;
}

@media (min-width: 1057px) {
  .sale-list__tit > span {
    width: 90px;
    height: 90px;
    font-size: 3rem;
  }
}

.sale-list__list {
  row-gap: 2rem;
}

.sale-box {
  margin-bottom: 2rem;
}

.sale-box a {
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
  color: #232323;
  display: block;
}

.sale-box a:hover {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
}

.sale-box__flex {
  margin-bottom: 0.5rem;
  display: flex;
}

.sale-box__flex span {
  line-height: 1;
  vertical-align: top;
}

.sale-box__num {
  display: inline-block;
  background: #CE0000;
  color: #fff;
  padding: 0.5em 0.75em;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  letter-spacing: 0;
  font-size: 0.88rem;
  min-height: 30px;
}

.sale-box__category {
  display: inline-block;
  min-height: 30px;
  padding: 0.5em 0.75em 0.4em;
  font-weight: 500;
  letter-spacing: 0.05em;
  font-size: 0.88rem;
  color: #fff;
  text-align: center;
}

.sale-box__img {
  width: 100%;
}

.sale-box__img__inner {
  position: relative;
  width: 100%;
}

.sale-box__img__inner::before {
  content: "";
  display: block;
  padding-top: 64.28%;
  position: relative;
}

.sale-box__img__inner > div,
.sale-box__img__inner figure,
.sale-box__img__inner a,
.sale-box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.sale-box__txt {
  position: relative;
  padding: 1.5em 8% 2em;
  background: #fff;
}

.sale-box__tit {
  margin-bottom: clamp(0.5rem, 2vw, 1rem);
  font-size: calc(1rem + 2 * (100vw -  376px) / 1164);
  color: #000;
  font-weight: 700;
}

@media (min-width: 376px) {
  .sale-box__tit {
    font-size: calc(1rem + 2 * (100vw -  376px) / 1164);
  }
}

@media (min-width: 1637px) {
  .sale-box__tit {
    font-size: 1.13rem;
  }
}

.sale-box__table {
  font-size: 0.88rem;
}

.sale-box__table th {
  text-align: left;
  vertical-align: top;
  min-width: 115px;
  padding-right: 0.5em;
}

.sale-flow {
  padding: 0 0 clamp(6rem, 12vw, 12rem);
  background: #F3F5F7;
}

.sale-flow__tit {
  text-align: center;
  position: relative;
  top: -1.5em;
  margin-bottom: calc(2rem + 1em);
}

@media (min-width: 576px) {
  .sale-flow__tit {
    top: -2rem;
  }
}

.sale-flow__tit em {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.1em;
  font-style: normal;
  display: block;
  color: #1D2087;
  font-weight: 700;
  position: relative;
  line-height: 1.2;
  font-size: calc(2.25rem + 28 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .sale-flow__tit em {
    font-size: 3.75rem;
  }
}

.sale-flow__tit span {
  font-family: 'Noto Sans JP', sans-serif;
  letter-spacing: 0.1em;
  display: block;
  font-weight: 700;
  position: relative;
  line-height: 1.4;
  font-size: calc(1.14rem + 10 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .sale-flow__tit span {
    font-size: 1.75rem;
  }
}

.sale-flow__list {
  counter-reset: flow-num;
}

@media (min-width: 1637px) {
  .sale-flow__list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1.8625%;
    row-gap: 4rem;
  }
  .sale-flow__list > * {
    width: 18.51%;
  }
}

@media (max-width: 1636px) {
  .sale-flow__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: clamp(3rem, 6vw, 6rem);
  }
  .sale-flow__list > * {
    width: 100%;
    max-width: 960px;
  }
}

.sale-flow__box {
  position: relative;
}

.sale-flow__box:not(:last-of-type)::before {
  position: absolute;
  content: "";
}

@media (min-width: 1637px) {
  .sale-flow__box:not(:last-of-type)::before {
    top: 50%;
    left: 100%;
    width: 80%;
    height: 1px;
    background-size: 8px 1px;
    background-image: linear-gradient(to right, #1D2087, #1D2087 3px, transparent 3px, transparent 8px);
  }
}

@media (max-width: 1636px) {
  .sale-flow__box:not(:last-of-type)::before {
    width: 1px;
    height: 80%;
    top: 100%;
    left: calc(50% + 15px);
    background-size: 1px 8px;
    background-image: linear-gradient(to bottom, #1D2087, #1D2087 3px, transparent 3px, transparent 8px);
  }
}

@media (max-width: 575px) {
  .sale-flow__box:not(:last-of-type)::before {
    left: calc(50% + 10px);
  }
}

@media (min-width: 1637px) {
  .sale-flow__box:nth-of-type(5n)::before {
    display: none;
  }
}

.sale-flow__box__inner {
  width: calc(100% - 20px);
  margin-left: 20px;
  height: 100%;
  border: 1px solid #1D2087;
  background: #fff;
  position: relative;
  z-index: 2;
  padding: 1.5em 8% 2em;
  display: flex;
  flex-direction: column;
}

@media (min-width: 576px) and (max-width: 1636px) {
  .sale-flow__box__inner {
    flex-direction: row;
    column-gap: 4%;
    padding: 1.5em 8% 1.5em 80px;
  }
}

@media (min-width: 576px) {
  .sale-flow__box__inner {
    width: calc(100% - 30px);
    margin-left: 30px;
  }
}

@media (min-width: 1637px) {
  .sale-flow__box__inner {
    padding: 1.5em 8% 2em;
  }
}

@media (min-width: 1825px) {
  .sale-flow__box__inner {
    padding: 1.5em 10% 2em;
  }
}

.sale-flow__box__num {
  position: absolute;
  top: -20px;
  left: -20px;
  width: 80px;
  height: 80px;
  background: #1D2087;
  z-index: 3;
}

@media (min-width: 576px) {
  .sale-flow__box__num {
    top: -30px;
    left: -30px;
  }
}

.sale-flow__box__num span {
  position: relative;
  width: 100%;
  height: 100%;
  color: #fff;
  display: block;
}

.sale-flow__box__num span::before {
  counter-increment: flow-num;
  content: counter(flow-num, decimal-leading-zero);
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.05em;
  position: absolute;
  top: calc(50% - 0.5em);
  left: calc(50% - 0.65em);
  font-size: 2.25rem;
}

.sale-flow__box__img {
  width: 120px;
  height: 120px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  flex-shrink: 0;
}

.sale-flow__box__img__inner {
  position: relative;
  width: 100%;
}

.sale-flow__box__img__inner::before {
  content: "";
  display: block;
  padding-top: 100%;
  position: relative;
}

.sale-flow__box__img__inner > div,
.sale-flow__box__img__inner figure,
.sale-flow__box__img__inner a,
.sale-flow__box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.sale-flow__box__tit {
  font-weight: 700;
  font-size: calc(1rem + 4 * (100vw -  376px) / 1164);
  margin-bottom: 0.4em;
}

@media (min-width: 1637px) {
  .sale-flow__box__tit {
    font-size: 1.25rem;
  }
}

/* sale-slider */
.sale-slider {
  position: relative;
  height: auto;
  margin-bottom: clamp(3rem, 4vw, 4rem);
}

@media (min-width: 1057px) {
  .sale-slider {
    display: flex;
    justify-content: space-between;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/common/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 30px;
  width: 30px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  top: 29.23vw;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  z-index: 4;
  /*&.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }
    &:before {
        font-family: $slick-font-family;
        font-size: 20px;
        line-height: 1;
        color: $slick-arrow-color;
        opacity: $slick-opacity-default;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }*/
}

@media (min-width: 1057px) {
  .slick-prev,
  .slick-next {
    height: 40px;
    width: 40px;
    top: 16.81vw;
  }
}

@media (min-width: 1637px) {
  .slick-prev,
  .slick-next {
    top: 266px;
  }
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev {
  left: -15px;
}

@media (min-width: 1057px) {
  .slick-prev {
    left: -20px;
  }
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -15px;
}

@media (min-width: 1057px) {
  [dir="rtl"] .slick-prev {
    right: -20px;
  }
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -15px;
}

@media (min-width: 1057px) {
  .slick-next {
    right: -20px;
  }
}

[dir="rtl"] .slick-next {
  left: -15px;
  right: auto;
}

@media (min-width: 1057px) {
  [dir="rtl"] .slick-next {
    left: -20px;
  }
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* gallery-top */
.gallery-top {
  position: relative;
}

@media (min-width: 1057px) {
  .gallery-top {
    width: 51.94%;
    max-width: 800px;
  }
}

@media (max-width: 1056px) {
  .gallery-top {
    margin-bottom: 1.5rem;
  }
}

.gallery-top__inner {
  width: 100%;
  position: relative;
}

.gallery-top .slick-arrow {
  border: 1px solid #C9CED2;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  transition: all 0.3s ease 0s;
  border-radius: 50%;
}

.gallery-top .slick-arrow:hover {
  background: rgba(18, 12, 188, 0.8);
}

.gallery-top .slick-arrow i {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  width: 30px;
  height: 30px;
}

@media (min-width: 1057px) {
  .gallery-top .slick-arrow i {
    width: 40px;
    height: 40px;
  }
}

.gallery-top .slick-arrow i::before {
  color: #C9CED2;
}

.gallery-top .slick-prev::before {
  display: none;
}

.gallery-top .slick-next::before {
  display: none;
}

.gallery-top .sp-slide {
  position: relative;
  overflow: hidden;
}

.gallery-top .sp-slide__inner {
  position: relative;
  width: 100%;
}

.gallery-top .sp-slide__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}

.gallery-top .sp-slide__inner > div,
.gallery-top .sp-slide__inner figure,
.gallery-top .sp-slide__inner a,
.gallery-top .sp-slide__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.gallery-top .sp-slide__inner .is-gray {
  filter: grayscale(100%);
}

.gallery-top .sp-slide figcaption {
  margin-top: 0.75rem;
  color: #232323;
  line-height: 1.4;
  z-index: 1;
}

/* gallery-thumbs */
.gallery-thumbs {
  position: relativve;
}

@media (min-width: 1057px) {
  .gallery-thumbs {
    width: 42.2%;
  }
}

.gallery-thumbs__inner {
  width: 100%;
  position: relative;
}

.gallery-thumbs__list {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 1057px) {
  .gallery-thumbs__list {
    column-gap: 1.538%;
    row-gap: 0.64vw;
  }
  .gallery-thumbs__list > * {
    width: 15.384%;
  }
}

@media (min-width: 1637px) {
  .gallery-thumbs__list {
    row-gap: 10px;
  }
}

@media (max-width: 1056px) {
  .gallery-thumbs__list {
    column-gap: 2%;
    row-gap: 1.95vw;
  }
  .gallery-thumbs__list > * {
    width: 15%;
  }
}

.gallery-thumbs__list .sp-thumbnail {
  transition: all 0.3s ease 0s;
  overflow: hidden;
}

.gallery-thumbs__list .sp-thumbnail .is-gray {
  filter: grayscale(100%);
}

.gallery-thumbs__list .sp-thumbnail img {
  transition: all 0.3s ease 0s;
}

.gallery-thumbs__list .sp-thumbnail:hover img {
  transform: scale(1.1, 1.1);
}

.gallery-thumbs__list .sp-thumbnail__inner {
  position: relative;
  width: 100%;
}

.gallery-thumbs__list .sp-thumbnail__inner::before {
  content: "";
  display: block;
  padding-top: 100%;
  position: relative;
}

.gallery-thumbs__list .sp-thumbnail__inner > div,
.gallery-thumbs__list .sp-thumbnail__inner figure,
.gallery-thumbs__list .sp-thumbnail__inner a,
.gallery-thumbs__list .sp-thumbnail__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.sale-main {
  margin-bottom: clamp(3rem, 4vw, 4rem);
}

.sale-main__img {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.sale-main__img__inner {
  position: relative;
  width: 100%;
}

.sale-main__img__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}

.sale-main__img__inner > div,
.sale-main__img__inner figure,
.sale-main__img__inner a,
.sale-main__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.sale-main__img__inner img {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  -o-object-fit: contain;
  object-fit: contain;
}

.sale-main__img figcaption {
  margin-top: 0.7rem;
}

/* sale-text */
.sale-text {
  background: #F7F9FB;
  padding: 2em 5%;
  margin-bottom: clamp(2rem, 3vw, 3rem);
}

@media (min-width: 768px) {
  .sale-text {
    padding: 3.5em 8%;
  }
}

.sale-contact {
  margin-top: clamp(4rem, 9vw, 9rem);
  border: 1px solid #1D2087;
  background: #fff;
}

.sale-contact__inner {
  padding: 2em 5%;
}

@media (min-width: 1057px) {
  .sale-contact__inner {
    padding: 3.5em 8%;
  }
}

.sale-contact__txt {
  margin-bottom: clamp(1.5rem, 2vw, 2rem);
}

@media (min-width: 1057px) {
  .sale-contact__txt {
    text-align: center;
  }
}

.sale-contact__txt p {
  font-weight: 500;
  font-size: calc(0.94rem + 4 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .sale-contact__txt p {
    font-size: 1.25rem;
  }
}

.sale-contact__txt p small {
  font-weight: 400;
}

.sale-contact__flex {
  display: flex;
}

@media (min-width: 768px) {
  .sale-contact__flex {
    justify-content: center;
    column-gap: 5.27%;
  }
  .sale-contact__flex > * {
    width: 47.36%;
    max-width: 520px;
  }
}

@media (max-width: 767px) {
  .sale-contact__flex {
    flex-direction: column;
    row-gap: 1rem;
  }
}

.sale-contact__btn a {
  height: 72px;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  color: #fff;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.2;
  padding: 0.2em 2%;
  font-size: calc(0.9rem + 3 * (100vw -  376px) / 1164);
}

@media (min-width: 768px) {
  .sale-contact__btn a {
    font-size: calc(0.94rem + 3 * (100vw - 768px) / 772);
    height: 96px;
  }
}

@media (min-width: 1637px) {
  .sale-contact__btn a {
    font-size: 1.13rem;
  }
}

.sale-contact__btn a.is-contact {
  background: #1D2087;
}

.sale-contact__btn a.is-contact:hover {
  background: #120CBC;
}

.sale-contact__btn a.is-contact span {
  padding-left: 2.5rem;
  position: relative;
}

.sale-contact__btn a.is-contact span:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sale-contact__btn a.is-contact span::before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 1.5em;
}

.sale-contact__btn a.is-line {
  background: #00B900;
}

.sale-contact__btn a.is-line:hover {
  background: #00ec00;
}

.sale-contact__btn a.is-line span {
  padding-left: 2.75rem;
  position: relative;
}

.sale-contact__btn a.is-line span:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sale-contact__btn a.is-line span::before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 1.75em;
}

.sale-outline {
  margin-bottom: clamp(2rem, 3vw, 3rem);
}

.sale-outline__list {
  border-top: 1px solid #EBF1FF;
  border-right: 1px solid #EBF1FF;
}

@media (min-width: 768px) {
  .sale-outline__list {
    display: flex;
    flex-wrap: wrap;
  }
  .sale-outline__list > * {
    width: 50%;
  }
}

.sale-outline__row {
  display: flex;
  height: 100%;
  border-bottom: 1px solid #EBF1FF;
}

.sale-outline__row.is-last {
  width: 100%;
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .sale-outline__row.is-last dt {
    width: 14.28%;
  }
}

@media (min-width: 768px) {
  .sale-outline__row.is-last dd {
    width: 85.72%;
  }
}

.sale-outline__row dt {
  width: 28.57%;
  min-width: 150px;
  max-width: 220px;
  background: #EBF1FF;
  height: 100%;
  flex-shrink: 0;
  padding: 1em;
}

.sale-outline__row dd {
  width: 71.43%;
  background: #fff;
  height: 100%;
  flex-shrink: 1;
  padding: 1em;
}

.pdf-list {
  margin-bottom: clamp(2rem, 3vw, 3rem);
  background: #1D2087;
  padding: 2em 5%;
}

@media (min-width: 1057px) {
  .pdf-list {
    padding: 3em 8%;
  }
}

@media (min-width: 1057px) {
  .pdf-list__inner {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2%;
    row-gap: 1rem;
  }
  .pdf-list__inner > * {
    width: 49%;
  }
}

@media (max-width: 1056px) {
  .pdf-list__inner li:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}

.pdf-list__inner li a {
  padding: 1rem 4% 1rem 4rem;
  transition: all 0.3s ease 0s;
  background: #fff;
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  color: #232323;
  text-decoration: none !important;
  font-weight: 500;
}

.pdf-list__inner li a:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pdf-list__inner li a::before {
  position: absolute;
  left: 1.25rem;
  font-size: 2.14em;
  color: #979DA4;
}

@media (min-width: 576px) {
  .pdf-list__inner li a::before {
    font-size: 2em;
  }
}

@media (min-width: 1057px) {
  .pdf-list__inner li a::before {
    font-size: 1.87em;
  }
}

.pdf-list__inner li a:hover {
  background: #979DA4;
  border-color: #979DA4;
  color: #fff;
}

.pdf-list__inner li a:hover::before {
  color: #fff;
}

/*--------------------------------------------------------------------/
	common-parts
/--------------------------------------------------------------------*/
/* cotegory */
.category-list {
  margin-bottom: clamp(2rem, 3vw, 3rem);
  line-height: 1.4;
}

@media (min-width: 768px) {
  .category-list {
    display: flex;
  }
}

@media (min-width: 768px) {
  .category-list__tit {
    position: relative;
    padding-top: 0.3rem;
    padding-left: 0.5rem;
    padding-right: 0.75rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: #000;
    flex-shrink: 0;
  }
}

@media (max-width: 767px) {
  .category-list__tit {
    margin-bottom: 0.5rem;
  }
}

.category-list .category-item {
  font-size: 0.75rem;
  padding: 0.3rem 1rem;
  min-width: 140px;
  vertical-align: top;
}

@media (min-width: 768px) {
  .category-list .category-item {
    font-size: 0.88rem;
  }
}

.category-list__items {
  font-size: 0;
}

.category-list__items > * {
  display: inline-block;
  margin-bottom: 0.2rem;
  margin-right: 0.2rem;
  font-size: 1.14em;
}

@media (min-width: 576px) {
  .category-list__items > * {
    font-size: 1.06em;
  }
}

@media (min-width: 1057px) {
  .category-list__items > * {
    font-size: 1em;
  }
}

.category-list__items a {
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
}

.category-list__items a:hover {
  opacity: 0.8;
}

.category-item {
  display: inline-block;
  background-color: #6F757B;
  color: #fff;
  text-align: center;
  line-height: 1.4;
  padding: 0.2rem 0.75rem;
  font-size: 0.75rem;
  letter-spacing: 0.05em;
}

.category-item--black {
  background-color: #000;
}

/*--------------------------------------------------------------------/
	purchase
/--------------------------------------------------------------------*/
.purchase-intro {
  position: relative;
  padding: clamp(2rem, 4vw, 4rem) 0 clamp(3rem, 6vw, 6rem);
}

.purchase-intro::before {
  content: "";
  width: 100%;
  height: 60%;
  position: absolute;
  top: 0;
  left: 0;
  background: #1D2087;
  z-index: 1;
}

.purchase-intro::after {
  content: "";
  width: 100%;
  height: 40%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #D9E5FF;
  z-index: 1;
}

.purchase-intro__add {
  position: absolute;
  top: 1em;
  z-index: 2;
}

@media (min-width: 1057px) {
  .purchase-intro__add {
    left: 70%;
    width: 54%;
  }
}

@media (min-width: 1637px) {
  .purchase-intro__add {
    left: calc(50% + 240px);
  }
}

@media (max-width: 1056px) {
  .purchase-intro__add {
    display: none;
  }
}

.purchase-intro__inner {
  font-size: calc(0.88rem + 3 * (100vw -  376px) / 1164);
  font-weight: 500;
  position: relative;
  z-index: 3;
}

@media (min-width: 1637px) {
  .purchase-intro__inner {
    font-size: 1.13rem;
  }
}

.purchase-intro__tit {
  margin-bottom: clamp(1.5rem, 3vw, 3rem);
}

.purchase-intro__tit__inner {
  background: #fff;
  padding: 1em 1em 1em 0;
  position: relative;
  display: inline-block;
  font-weight: 700;
  font-size: calc(1rem + 8 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .purchase-intro__tit__inner {
    font-size: 1.5rem;
  }
}

.purchase-intro__tit__inner::before {
  content: "";
  position: absolute;
  top: 0;
  right: 100%;
  width: 100vw;
  height: 100%;
  background: #fff;
}

.purchase-intro__tit__inner b {
  color: #120CBC;
  font-size: 2.5em;
}

.purchase-intro__tit__inner strong {
  display: inline-block;
  background: #CE0000;
  color: #fff;
  font-size: 1.75em;
  padding: 0.1em 0.2em;
}

.purchase-intro__list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2%;
  row-gap: clamp(0.5rem, 1.5vw, 1rem);
}

@media (min-width: 1057px) {
  .purchase-intro__list {
    column-gap: 0.925%;
  }
}

.purchase-intro__img {
  width: 49%;
}

@media (min-width: 1057px) {
  .purchase-intro__img {
    width: 24.3%;
  }
}

.purchase-intro__txt {
  margin-top: clamp(2rem, 5vw, 5rem);
  padding: 1.5em 5%;
  background: #1D2087;
  color: #fff;
}

.purchase-point {
  position: relative;
  padding-top: clamp(5rem, 9vw, 9rem);
  margin-bottom: clamp(3rem, 6vw, 6rem);
}

.purchase-point__inner {
  padding: 1em 5% 2em;
  border: 1px solid #1D2087;
  background: #fff;
  position: relative;
  z-index: 2;
}

@media (min-width: 768px) {
  .purchase-point__inner {
    padding: 1em 5% 4em;
  }
}

.purchase-point__tit {
  text-align: center;
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  line-height: 1;
  top: -3em;
}

.purchase-point__tit__inner {
  background: #fff;
  padding: 0 4%;
  display: inline-block;
}

.purchase-point__tit__inner span {
  display: block;
  font-weight: 700;
  color: #1D2087;
  font-size: calc(0.94rem + 18 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .purchase-point__tit__inner span {
    font-size: 2.25rem;
  }
}

.purchase-point__tit__inner strong {
  display: block;
  font-weight: 900;
  line-height: 1;
  font-size: calc(1.5rem + 28 * (100vw -  376px) / 1164);
  letter-spacing: 0.15em;
}

@media (min-width: 1637px) {
  .purchase-point__tit__inner strong {
    font-size: 3.38rem;
  }
}

.purchase-point__tit__inner strong b {
  font-size: 2em;
  color: #CE0000;
}

.purchase-point__list {
  display: flex;
}

@media (min-width: 768px) {
  .purchase-point__list {
    column-gap: 1.3%;
    row-gap: 1em;
    justify-content: center;
    flex-wrap: wrap;
  }
  .purchase-point__list > * {
    width: 49.35%;
    max-width: 640px;
  }
}

@media (min-width: 1637px) {
  .purchase-point__list {
    row-gap: 1.25rem;
  }
}

@media (max-width: 767px) {
  .purchase-point__list {
    flex-direction: column;
    row-gap: 1rem;
  }
}

.purchase-point__item {
  background: #CE0000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 2em;
}

@media (min-width: 768px) {
  .purchase-point__item {
    min-height: 100px;
  }
}

.purchase-point__item__inner {
  text-align: center;
  line-height: 1.5;
  font-weight: 700;
  font-size: calc(0.94rem + 4 * (100vw -  376px) / 1164);
}

@media (min-width: 768px) {
  .purchase-point__item__inner {
    font-size: calc(0.94rem + 5 * (100vw - 768px) / 772);
  }
}

@media (min-width: 1637px) {
  .purchase-point__item__inner {
    font-size: 1.25rem;
  }
}

.purchase-assessment {
  padding-top: clamp(3rem, 6vw, 6rem);
  padding-bottom: clamp(5rem, 12vw, 12rem);
  margin-bottom: clamp(5rem, 9vw, 9rem);
  position: relative;
  background: #1D2087;
  overflow: hidden;
}

.purchase-assessment::before {
  content: "";
  background: #30339B;
  width: 80%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 50%;
  transform: skew(-20deg);
}

.purchase-assessment__add {
  position: absolute;
  bottom: 0;
  right: 5%;
  z-index: 2;
  width: 100vw;
  text-align: right;
}

.purchase-assessment__add span {
  font-family: 'Montserrat', sans-serif;
  color: #474897;
  font-weight: 700;
  letter-spacing: 0.05em;
  font-size: calc(3rem + 140 * (100vw -  376px) / 1164);
  line-height: 0.85;
}

@media (min-width: 1637px) {
  .purchase-assessment__add span {
    font-size: calc(10rem + 40 * (100vw - 1540px) / 188);
  }
}

@media (min-width: 1825px) {
  .purchase-assessment__add span {
    font-size: 12.5rem;
  }
}

.purchase-assessment__inner {
  position: relative;
  background: #fff;
  z-index: 2;
  padding: 0 5% 2em;
}

.purchase-assessment__tit {
  color: #000;
  font-weight: 900;
  font-size: calc(0.9rem + 20 * (100vw -  376px) / 1164);
  line-height: 1.4;
  text-align: center;
  position: relative;
  top: -1em;
}

@media (min-width: 1637px) {
  .purchase-assessment__tit {
    font-size: 2rem;
  }
}

.purchase-assessment__tit__inner {
  display: inline-block;
  background: #fff;
  padding: 0.75em 1.25em;
  position: relative;
}

.purchase-assessment__tit__inner::after {
  content: "";
  position: absolute;
  left: 25%;
  bottom: 0;
  width: 50%;
  height: 2px;
  background: #1D2087;
}

@media (min-width: 1057px) {
  .purchase-assessment__txt {
    text-align: center;
  }
}

.purchase-type__tit {
  padding: 0.5em 2%;
  border-top: 1px solid #1D2087;
  border-bottom: 1px solid #1D2087;
  font-weight: 700;
  color: #000;
  text-align: center;
  font-size: calc(1.25rem + 32 * (100vw -  376px) / 1164);
  letter-spacing: 0.1em;
  margin-bottom: calc(2em + 2rem);
}

@media (min-width: 1057px) {
  .purchase-type__tit {
    margin-bottom: calc(2em + 4rem);
  }
}

@media (min-width: 1637px) {
  .purchase-type__tit {
    font-size: 3.25rem;
  }
}

.purchase-type__tit span {
  font-size: 1.12em;
}

.purchase-type__tit b {
  font-size: 1.75em;
  color: #CE0000;
}

.purchase-type__list {
  display: flex;
  flex-direction: column;
  row-gap: clamp(3rem, 6vw, 6rem);
}

.purchase-box {
  position: relative;
  background-color: #F7F9FB;
}

.purchase-box__num {
  position: absolute;
  left: 3%;
  line-height: 1;
  top: -0.5em;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  font-size: calc(3rem + 48 * (100vw -  376px) / 1164);
}

@media (min-width: 1057px) {
  .purchase-box__num {
    left: 6%;
  }
}

@media (min-width: 1637px) {
  .purchase-box__num {
    font-size: 6rem;
  }
}

.purchase-box__num span {
  position: relative;
  color: #1D2087;
  letter-spacing: 0.05em;
}

.purchase-box__inner {
  padding: 4em 5% 3em;
  position: relative;
}

@media (min-width: 1057px) {
  .purchase-box__inner {
    padding: 5em 8% 5em;
  }
}

.purchase-box__head {
  margin-bottom: clamp(1.5rem, 4vw, 4rem);
}

@media (min-width: 1057px) {
  .purchase-box__head {
    display: flex;
    justify-content: space-between;
  }
}

.purchase-box__tit {
  color: #000;
  overflow: hidden;
  font-weight: 700;
  font-size: calc(2rem + 40 * (100vw -  376px) / 1164);
}

@media (min-width: 1057px) {
  .purchase-box__tit {
    font-size: calc(2.5rem + 24 * (100vw - 1000px) / 540);
    width: 34.88%;
  }
}

@media (min-width: 1637px) {
  .purchase-box__tit {
    font-size: 4rem;
  }
}

.purchase-box__tit span {
  display: inline-block;
  position: relative;
  padding-right: 1em;
}

.purchase-box__txt {
  font-weight: 500;
  color: #000;
}

@media (min-width: 1057px) {
  .purchase-box__txt {
    font-size: calc(1rem + 4 * (100vw - 1000px) / 540);
    width: 60.46%;
  }
}

@media (min-width: 1637px) {
  .purchase-box__txt {
    font-size: 1.25rem;
  }
}

.purchase-box__txt b {
  display: inline-block;
  background: #CE0000;
  color: #fff;
  padding: 0.1em 0.2em;
}

.purchase-box__merit__tit {
  text-align: center;
  overflow: hidden;
  font-size: calc(1.14rem + 12 * (100vw -  376px) / 1164);
  color: #000;
  margin-bottom: calc(1rem + 1em);
  font-weight: 700;
}

@media (min-width: 1637px) {
  .purchase-box__merit__tit {
    font-size: 1.75rem;
  }
}

.purchase-box__merit__tit span {
  display: inline-block;
  padding: 0 0.75em;
  position: relative;
}

.purchase-box__merit__tit span::before, .purchase-box__merit__tit span::after {
  content: "";
  width: 100vw;
  height: 1px;
  background: #1D2087;
  position: absolute;
  top: calc(50% - 1px);
}

@media (min-width: 768px) {
  .purchase-box__merit__tit span::before, .purchase-box__merit__tit span::after {
    height: 2px;
  }
}

.purchase-box__merit__tit span::before {
  right: 100%;
}

.purchase-box__merit__tit span::after {
  left: 100%;
}

.purchase-box__merit__list {
  display: flex;
  row-gap: 1rem;
}

@media (min-width: 768px) {
  .purchase-box__merit__list {
    flex-wrap: wrap;
    column-gap: 2%;
  }
  .purchase-box__merit__list > * {
    width: 49%;
  }
}

@media (min-width: 1057px) {
  .purchase-box__merit__list {
    column-gap: 1.55%;
  }
  .purchase-box__merit__list > * {
    width: 23.8375%;
  }
}

@media (max-width: 767px) {
  .purchase-box__merit__list {
    flex-direction: column;
  }
}

.purchase-box__merit__box {
  border: 1px solid #1D2087;
  background: #fff;
}

.purchase-box__merit__box__inner {
  padding: 1.5em 8% 2em;
}

.purchase-box__merit__box__img {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  max-width: 240px;
}

.purchase-box__merit__box__img__inner {
  position: relative;
  width: 100%;
}

.purchase-box__merit__box__img__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}

.purchase-box__merit__box__img__inner > div,
.purchase-box__merit__box__img__inner figure,
.purchase-box__merit__box__img__inner a,
.purchase-box__merit__box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.purchase-box__merit__box__tit {
  line-height: 1.4;
  font-weight: 700;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .purchase-box__merit__box__tit {
    font-size: calc(0.94rem + 4 * (100vw - 768px) / 772);
  }
}

@media (min-width: 1637px) {
  .purchase-box__merit__box__tit {
    font-size: 1.06rem;
  }
}

@media (min-width: 768px) {
  .purchase-box__merit__box__txt {
    font-size: 0.94em;
  }
}

.purchase-box__file {
  margin-top: clamp(1.5rem, 2vw, 2rem);
  background: #fff;
  border: 1px solid #1D2087;
  padding: 1.5em 5% 2em;
}

.purchase-box__file__tit {
  font-weight: 700;
  margin-bottom: 0.5rem;
  font-size: calc(1rem + 4 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .purchase-box__file__tit {
    font-size: 1.25rem;
  }
}

.purchase-box__file .btn-wrap a {
  padding: 1rem 4% 1rem 4rem;
  transition: all 0.3s ease 0s;
  background: #1D2087;
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  color: #fff;
  text-decoration: none !important;
  font-weight: 500;
}

.purchase-box__file .btn-wrap a:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.purchase-box__file .btn-wrap a::before {
  position: absolute;
  left: 1.25rem;
  font-size: 2.14em;
  color: #fff;
}

@media (min-width: 576px) {
  .purchase-box__file .btn-wrap a::before {
    font-size: 2em;
  }
}

@media (min-width: 1057px) {
  .purchase-box__file .btn-wrap a::before {
    font-size: 1.87em;
  }
}

.purchase-box__file .btn-wrap a:hover {
  background: #120CBC;
  color: #fff;
}

/*--------------------------------------------------------------------/
	rental
/--------------------------------------------------------------------*/
.rental-intro {
  padding: clamp(4rem, 8vw, 8rem) 0 clamp(5rem, 10vw, 10rem);
  position: relative;
  background-image: url(../img/lv2/rental_bg_sp.jpg);
  background: center center/cover no-repeat;
}

@media (min-width: 1057px) {
  .rental-intro {
    background-image: url(../img/lv2/rental_bg_sp.jpg);
    padding: clamp(4rem, 6vw, 6rem) 0 clamp(5rem, 9vw, 9rem);
  }
}

.rental-intro::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(29, 32, 135, 0.5);
  z-index: 2;
}

.rental-intro__inner {
  position: relative;
  background: #fff;
  z-index: 3;
  padding: 2em 4%;
}

@media (min-width: 1057px) {
  .rental-intro__inner {
    padding: 2em 2% 3em;
  }
}

.rental-intro__inner p {
  line-height: 1.5;
}

@media (min-width: 768px) {
  .rental-intro__inner p {
    text-align: center;
  }
}

.rental-intro__inner p.is-blue {
  color: #1D2087;
  font-weight: 700;
  font-size: calc(0.84rem + 12 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .rental-intro__inner p.is-blue {
    font-size: 1.75rem;
  }
}

.rental-intro__inner p.is-black {
  color: #232323;
  font-weight: 900;
  font-size: calc(0.84rem + 14 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .rental-intro__inner p.is-black {
    font-size: 1.88rem;
  }
}

.rental-intro__inner p.is-black strong {
  display: inline-block;
  background: #CE0000;
  color: #fff;
  padding: 0.2em 0.3em;
  margin: 0 0.2em;
}

.rental-summary {
  position: relative;
  padding: clamp(3rem, 4vw, 4rem) 0 clamp(8rem, 12vw, 12rem);
  background: #0E0F53;
}

.rental-summary__add {
  position: absolute;
  z-index: 2;
}

@media (min-width: 1057px) {
  .rental-summary__add {
    bottom: 1em;
    left: 70%;
    width: 54%;
  }
}

@media (min-width: 1637px) {
  .rental-summary__add {
    left: calc(50% + 240px);
  }
}

@media (max-width: 1056px) {
  .rental-summary__add {
    bottom: 0;
    left: 40%;
    width: 60%;
  }
}

.rental-summary .l-cont {
  position: relative;
  z-index: 3;
}

.rental-summary__box {
  background: #fff;
  padding: 2em 5% 3em;
}

.rental-summary__box__tit {
  text-align: center;
  color: #1D2087;
  font-size: calc(1.25rem + 14 * (100vw -  376px) / 1164);
  font-weight: 700;
  margin-bottom: calc(1rem + 0.5em);
}

@media (min-width: 1637px) {
  .rental-summary__box__tit {
    font-size: 2.25rem;
  }
}

.rental-summary__box__pdf {
  margin-top: clamp(2rem, 3vw, 3rem);
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  background: #E5E9EC;
  padding: 1em 5%;
}

@media (min-width: 1057px) {
  .rental-summary__box__pdf {
    padding: 1.5em 5%;
  }
}

.rental-summary__box__pdf li a {
  padding: 1rem 4% 1rem 4.5rem;
  transition: all 0.3s ease 0s;
  background: #1D2087;
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  color: #fff;
  text-decoration: none !important;
  font-weight: 500;
}

.rental-summary__box__pdf li a:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rental-summary__box__pdf li a::before {
  position: absolute;
  left: 1.75rem;
  font-size: 2.14em;
  color: #fff;
}

@media (min-width: 576px) {
  .rental-summary__box__pdf li a::before {
    font-size: 2em;
  }
}

@media (min-width: 1057px) {
  .rental-summary__box__pdf li a::before {
    font-size: 1.87em;
  }
}

.rental-summary__box__pdf li a:hover {
  background: #120CBC;
  border-color: #120CBC;
  color: #fff;
}

.rental-summary__box__pdf li a:hover::before {
  color: #fff;
}

.rental-list {
  padding-top: clamp(3rem, 6vw, 6rem);
  padding-bottom: clamp(4rem, 10vw, 10rem);
}

@media (min-width: 1057px) {
  .rental-list {
    padding-top: 6rem;
  }
}

.rental-list__tit {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 2px;
  margin-bottom: clamp(2.5rem, 5vw, 5rem);
}

.rental-list__tit > span {
  width: 60px;
  height: 60px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1D2087;
  font-size: 2rem;
}

@media (min-width: 1057px) {
  .rental-list__tit > span {
    width: 90px;
    height: 90px;
    font-size: 3rem;
  }
}

.rental-list__list {
  display: flex;
  flex-direction: column;
  row-gap: clamp(4rem, 7vw, 7rem);
}

.rental-box__tit {
  background: #1D2087;
  padding: 0.3em 1em;
  color: #fff;
  margin-bottom: calc(0.5em + 0.5rem);
  font-weight: 700;
  font-size: calc(1rem + 14 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .rental-box__tit {
    font-size: 2rem;
  }
}

.rental-box__price {
  margin-bottom: clamp(1rem, 2vw, 2rem);
}

.rental-box__price span {
  line-height: 1;
}

.rental-box__price span.is-sub {
  display: inline-block;
  background: #1D2087;
  padding: 0.2em 0.5em;
  color: #fff;
  font-size: calc(0.84rem + 4 * (100vw -  376px) / 1164);
  font-weight: 700;
}

@media (min-width: 1637px) {
  .rental-box__price span.is-sub {
    font-size: 1.25rem;
  }
}

.rental-box__price span.is-price {
  color: #CE0000;
  font-family: 'Montserrat', sans-serif;
  font-size: calc(2rem + 40 * (100vw -  376px) / 1164);
  font-weight: 700;
  padding-left: 0.75em;
}

@media (min-width: 1637px) {
  .rental-box__price span.is-price {
    font-size: 4.5rem;
  }
}

.rental-box__price span.is-en {
  display: inline-block;
  padding-left: 0.4em;
  color: #232323;
  font-size: calc(0.84rem + 16 * (100vw -  376px) / 1164);
  font-weight: 700;
}

@media (min-width: 1637px) {
  .rental-box__price span.is-en {
    font-size: 1.88rem;
  }
}

.rental-box__flex {
  display: flex;
}

@media (min-width: 1057px) {
  .rental-box__flex {
    justify-content: space-between;
    align-items: stretch;
  }
}

@media (max-width: 1056px) {
  .rental-box__flex {
    flex-direction: column;
    row-gap: 2rem;
  }
}

@media (min-width: 1057px) {
  .rental-box__left {
    width: 65.19%;
  }
}

.rental-box__left__inner {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.4%;
  row-gap: 1vw;
}

@media (min-width: 1637px) {
  .rental-box__left__inner {
    row-gap: 0.94rem;
  }
}

@media (min-width: 1057px) {
  .rental-box__right {
    width: 30.38%;
  }
}

.rental-box__right__inner {
  width: 100%;
  height: 100%;
  border: 1px solid #1D2087;
  padding: 1em 5% 2em;
}

@media (min-width: 1057px) {
  .rental-box__right__inner {
    padding: 1.5em 2em 2em;
  }
}

.rental-box__img {
  width: 49.3%;
}

.rental-box__img__inner {
  margin: 0;
  position: relative;
  width: 100%;
}

.rental-box__img__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}

.rental-box__img__inner > div,
.rental-box__img__inner figure,
.rental-box__img__inner a,
.rental-box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.rental-box__size__tit {
  color: #232323;
  margin-bottom: 1rem;
  text-align: center;
  font-size: calc(1rem + 4 * (100vw -  376px) / 1164);
}

@media (min-width: 1057px) {
  .rental-box__size__tit {
    font-size: calc(1rem + 4 * (100vw - 1000px) / 540);
  }
}

@media (min-width: 1637px) {
  .rental-box__size__tit {
    font-size: 1.25rem;
  }
}

.rental-box__size__list {
  display: flex;
}

@media (min-width: 1057px) {
  .rental-box__size__list {
    flex-direction: column;
    row-gap: 1vw;
  }
}

@media (min-width: 1637px) {
  .rental-box__size__list {
    row-gap: 0.94rem;
  }
}

@media (max-width: 1056px) {
  .rental-box__size__list {
    justify-content: space-between;
    column-gap: 2%;
  }
}

@media (max-width: 1056px) {
  .rental-box__size__img {
    width: 49%;
  }
}

.rental-box__size__img__inner {
  margin: 0;
  position: relative;
  width: 100%;
}

.rental-box__size__img__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}

.rental-box__size__img__inner > div,
.rental-box__size__img__inner figure,
.rental-box__size__img__inner a,
.rental-box__size__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.rental-box__teble {
  margin-top: clamp(1.5rem, 3vw, 3rem);
}

.rental-contact {
  padding: clamp(2rem, 4vw, 4rem) 0 clamp(4rem, 7vw, 7rem);
  position: relative;
  background: #1D2087;
}

.rental-contact::before {
  content: "";
  position: absolute;
  bottom: 0;
  z-index: 1;
  background: #0E0F53;
  height: 80%;
  right: 0;
}

@media (min-width: 1057px) {
  .rental-contact::before {
    width: 98%;
  }
}

@media (min-width: 1637px) {
  .rental-contact::before {
    width: calc(50% + 860px);
  }
}

.rental-contact__inner {
  background: #fff;
  position: relative;
  z-index: 2;
}

.rental-contact__tit {
  background: #CE0000;
  color: #fff;
  font-weight: 900;
  padding: 0.5em 2%;
  font-size: calc(0.94rem + 16 * (100vw -  376px) / 1164);
  position: relative;
  text-align: center;
}

@media (min-width: 1637px) {
  .rental-contact__tit {
    font-size: 2rem;
  }
}

.rental-contact__tit::after {
  position: absolute;
  left: calc(50% - 8px);
  top: calc(100% - 2px);
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 16px 0 16px;
  border-color: #CE0000 transparent transparent transparent;
}

.rental-contact__txt {
  padding: 3em 5%;
}

.rental-contact__flex {
  padding-top: clamp(2rem, 3vw, 3rem);
  display: flex;
}

@media (min-width: 768px) {
  .rental-contact__flex {
    justify-content: space-between;
  }
}

@media (max-width: 767px) {
  .rental-contact__flex {
    flex-direction: column;
    row-gap: 1rem;
  }
}

.rental-contact__tel {
  background: #1D2087;
}

@media (min-width: 768px) {
  .rental-contact__tel {
    width: 61.32%;
  }
}

.rental-contact__tel__inner {
  width: 100%;
  padding: 1em 5% 2em;
}

.rental-contact__tel__tit {
  padding: 0.3em 2%;
  text-align: center;
  color: #CE0000;
  font-weight: 700;
  background: #fff;
  font-size: calc(1rem + 6 * (100vw -  376px) / 1164);
  margin-bottom: 1em;
}

@media (min-width: 768px) {
  .rental-contact__tel__tit {
    font-size: calc(0.94rem + 8 * (100vw - 768px) / 772);
  }
}

@media (min-width: 1637px) {
  .rental-contact__tel__tit {
    font-size: 1.63rem;
  }
}

.rental-contact__tel__num {
  color: #000;
  font-family: 'Montserrat', sans-serif;
  position: relative;
  font-size: calc(1.75rem + 20 * (100vw -  376px) / 1164);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1;
  text-align: center;
  color: #fff;
}

.rental-contact__tel__num:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 768px) {
  .rental-contact__tel__num {
    font-size: calc(2rem + 28 * (100vw - 768px) / 772);
  }
}

@media (min-width: 1637px) {
  .rental-contact__tel__num {
    font-size: 4rem;
  }
}

.rental-contact__tel__num::before {
  margin-right: 0.2em;
  font-size: 0.78em;
  color: #fff;
}

@media (min-width: 576px) {
  .rental-contact__tel__num::before {
    font-size: 0.73em;
  }
}

@media (min-width: 1057px) {
  .rental-contact__tel__num::before {
    font-size: 0.68em;
  }
}

.rental-contact__tel__num a {
  color: #fff !important;
}

.rental-contact__mail {
  border: 1px solid #1D2087;
}

@media (min-width: 768px) {
  .rental-contact__mail {
    width: 37.51%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.rental-contact__mail__inner {
  width: 100%;
  padding: 2em 5%;
}

.rental-contact__mail__tit {
  text-align: center;
  font-weight: 700;
  margin-bottom: 0.5em;
  font-size: calc(0.94rem + 5 * (100vw -  376px) / 1164);
}

@media (min-width: 768px) {
  .rental-contact__mail__tit {
    font-size: calc(0.75rem + 8 * (100vw - 768px) / 772);
  }
}

@media (min-width: 1637px) {
  .rental-contact__mail__tit {
    font-size: 1.25rem;
  }
}

.rental-contact__mail .btn-wrap {
  width: 100%;
}

@media (min-width: 768px) {
  .rental-contact__mail .btn-wrap {
    max-width: 320px;
  }
}

.rental-contact__mail .btn-wrap a {
  display: block;
  width: 100%;
  padding: 1em 2%;
  background: #1D2087;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
  color: #fff;
  font-weight: 700;
  text-align: center;
}

@media (min-width: 768px) {
  .rental-contact__mail .btn-wrap a {
    font-size: calc(0.8rem + 2 * (100vw - 768px) / 772);
  }
}

@media (min-width: 1637px) {
  .rental-contact__mail .btn-wrap a {
    font-size: 1rem;
  }
}

.rental-contact__mail .btn-wrap a:hover {
  background: #120CBC;
}

.rental-contact__mail .btn-wrap a span:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rental-contact__mail .btn-wrap a span::before {
  font-size: 1.2em;
  margin-right: 0.2em;
}

/*--------------------------------------------------------------------/
	fixtures
/--------------------------------------------------------------------*/
.fixtures-intro {
  padding: clamp(3rem, 6vw, 6rem) 0;
  position: relative;
  background: #1D2087;
  margin-bottom: clamp(3rem, 6vw, 6rem);
}

.fixtures-intro::before {
  content: "";
  background: #30339B;
  width: 80%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 50%;
  transform: skew(-20deg);
}

.fixtures-intro__inner {
  font-size: calc(0.85rem + 2 * (100vw -  376px) / 1164);
  font-weight: 500;
  background: #fff;
  position: relative;
  z-index: 2;
  padding: clamp(2rem, 4vw, 4rem) 5%;
}

@media (min-width: 1057px) {
  .fixtures-intro__inner {
    font-size: 1.13rem;
    text-align: center;
  }
}

.fixtures-intro__inner .txt {
  font-size: calc(0.8rem + 10 * (100vw -  376px) / 1164);
  font-weight: 700;
}

@media (min-width: 1637px) {
  .fixtures-intro__inner .txt {
    font-size: 1.75rem;
  }
}

.fixtures-intro__inner .txt span {
  display: inline-block;
  background: #CE0000;
  color: #fff;
  padding: 0 0.2em;
  font-size: 1.14em;
  margin-right: 0.2em;
}

.fixtures-parts {
  position: relative;
  padding-top: clamp(3rem, 8vw, 8rem);
  padding-bottom: clamp(4rem, 10vw, 10rem);
}

.fixtures-parts::before {
  content: "";
  background: #D9E5FF;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 90%;
}

@media (min-width: 1637px) {
  .fixtures-parts::before {
    width: 95%;
  }
}

.fixtures-parts::after {
  content: "";
  background: #1D2087;
  bottom: 0;
  right: 0;
  position: absolute;
  width: 98%;
  height: 80%;
}

@media (min-width: 1637px) {
  .fixtures-parts::after {
    width: 95%;
  }
}

.fixtures-parts__add {
  position: absolute;
  bottom: 0;
  right: 5%;
  z-index: 1;
}

.fixtures-parts__add span {
  font-family: 'Montserrat', sans-serif;
  color: #474897;
  font-weight: 700;
  letter-spacing: 0.05em;
  font-size: calc(2rem + 80 * (100vw -  376px) / 1164);
  line-height: 0.85;
}

@media (min-width: 1637px) {
  .fixtures-parts__add span {
    font-size: calc(8rem + 40 * (100vw - 1540px) / 188);
  }
}

@media (min-width: 1825px) {
  .fixtures-parts__add span {
    font-size: 10rem;
  }
}

.fixtures-parts__inner {
  position: relative;
  z-index: 2;
}

.fixtures-parts__tit {
  text-align: center;
  background: #CE0000;
  font-size: calc(0.8rem + 16 * (100vw -  376px) / 1164);
  position: relative;
  color: #fff;
  font-weight: 700;
  padding: 1em 4%;
}

@media (min-width: 1057px) {
  .fixtures-parts__tit {
    padding: 1em 8%;
  }
}

@media (min-width: 1637px) {
  .fixtures-parts__tit {
    font-size: 2.25rem;
  }
}

.fixtures-parts__txt {
  margin-left: auto;
  margin-right: auto;
  background: #fff;
}

.fixtures-parts__txt__inner {
  padding: clamp(2em, 4vw, 4rem) 5% 4em;
}

@media (min-width: 1057px) {
  .fixtures-parts__txt__inner {
    padding: clamp(2em, 4vw, 4rem) 10% 4em;
  }
}

.fixtures-parts__txt__banner {
  margin-bottom: 1rem;
}

.fixtures-product {
  padding-top: clamp(3rem, 6vw, 6rem);
}

.fixtures-product__tit {
  position: relative;
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: clamp(3rem, 6vw, 6rem);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid #1D2087;
  position: relative;
  z-index: 2;
  padding: 1.25em 4%;
  font-weight: 900;
  font-size: calc(1.25rem + 20 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .fixtures-product__tit {
    font-size: 2.5rem;
  }
}

.fixtures-product__tit::before {
  content: "";
  background: #1D2087;
  position: absolute;
  top: 0;
  left: 100%;
  height: 80%;
  width: 100vw;
}

.fixtures-product__tit::after {
  content: "";
  background: #1D2087;
  position: absolute;
  top: 0;
  right: 100%;
  height: 80%;
  width: 100vw;
}

.fixtures-product__tit__inner {
  text-align: center;
}

.fixtures-product__list {
  display: flex;
  flex-direction: column;
  row-gap: clamp(2rem, 4vw, 4rem);
}

.fixtures-product__box {
  border: 1px solid #1D2087;
  background: #fff;
}

@media (min-width: 768px) {
  .fixtures-product__box__inner {
    display: grid;
    grid-template-columns: 65% 35%;
    grid-template-rows: auto 1fr;
  }
}

.fixtures-product__box__tit {
  background: #1D2087;
  color: #fff;
  overflow: hidden;
  position: relative;
  font-weight: 700;
  padding: 1.25em 5%;
  line-height: 1.2;
  font-size: calc(1rem + 16 * (100vw -  376px) / 1164);
}

@media (min-width: 768px) {
  .fixtures-product__box__tit {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    font-size: calc(1rem + 16 * (100vw - 768px) / 772);
    padding: 1.25em 8%;
  }
}

@media (min-width: 1637px) {
  .fixtures-product__box__tit {
    font-size: 2rem;
  }
}

@media (max-width: 767px) {
  .fixtures-product__box__tit {
    margin-bottom: 1rem;
    text-align: center;
  }
}

.fixtures-product__box__tit::before {
  content: "";
  background: #30339B;
  width: 80%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 50%;
  transform: skew(-20deg);
}

.fixtures-product__box__tit span {
  position: relative;
  z-index: 1;
}

.fixtures-product__box__img {
  background: #fff;
}

@media (min-width: 768px) {
  .fixtures-product__box__img {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    padding: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .fixtures-product__box__img {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    padding: 5%;
  }
}

.fixtures-product__box__img__inner {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.fixtures-product__box__txt {
  padding: 0 5% 2rem;
}

@media (min-width: 768px) {
  .fixtures-product__box__txt {
    padding: 2em 8% 2rem;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    border-right: 1px solid #1D2087;
  }
}

/*--------------------------------------------------------------------/
	training
/--------------------------------------------------------------------*/
.training-intro {
  background: #1D2087;
  position: relative;
  color: #fff;
  overflow: hidden;
  padding: clamp(3rem, 6vw, 6rem) 0 clamp(9rem, 14vw, 14rem);
}

@media (min-width: 1057px) {
  .training-intro {
    padding: clamp(3rem, 6vw, 6rem) 0 clamp(5rem, 9vw, 9rem);
  }
}

@media (min-width: 1057px) {
  .training-intro::before {
    content: "";
    position: absolute;
    background: #1D2087;
    z-index: 2;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    transform: skew(-20deg);
  }
}

.training-intro__img {
  width: 100%;
  z-index: 1;
  position: absolute;
}

@media (min-width: 1057px) {
  .training-intro__img {
    top: 0;
    right: 0;
    width: 60%;
    height: 100%;
  }
}

@media (max-width: 1056px) {
  .training-intro__img {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50%;
  }
}

@media (max-width: 575px) {
  .training-intro__img {
    height: 50vw;
  }
}

.training-intro__img__inner {
  position: relative;
}

@media (min-width: 1057px) {
  .training-intro__img__inner {
    position: relative;
    width: 100%;
  }
  .training-intro__img__inner::before {
    content: "";
    display: block;
    padding-top: 100%;
    position: relative;
  }
  .training-intro__img__inner > div,
  .training-intro__img__inner figure,
  .training-intro__img__inner a,
  .training-intro__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .training-intro__img__inner img {
    width: 100%;
  }
}

@media (max-width: 1056px) {
  .training-intro__img__inner {
    position: relative;
    width: 100%;
  }
  .training-intro__img__inner::before {
    content: "";
    display: block;
    padding-top: 50%;
    position: relative;
  }
  .training-intro__img__inner > div,
  .training-intro__img__inner figure,
  .training-intro__img__inner a,
  .training-intro__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .training-intro__img__inner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: "object-fit: cover;";
  }
}

.training-intro__img__inner::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(29, 32, 135, 0.5);
  z-index: 2;
}

.training-intro .l-cont {
  position: relative;
  z-index: 3;
}

.training-intro__inner {
  font-size: calc(0.88rem + 3 * (100vw -  376px) / 1164);
  font-weight: 700;
  background: #fff;
  z-index: 2;
  padding: clamp(2rem, 3vw, 3rem) 5%;
  color: #000;
}

@media (min-width: 768px) {
  .training-intro__inner {
    text-align: center;
  }
}

@media (min-width: 1637px) {
  .training-intro__inner {
    font-size: 1.13rem;
  }
}

@media (min-width: 768px) {
  .training-intro .txt p {
    line-height: 2;
  }
}

.training-intro .is-catch {
  display: inline-block;
  background: #CE0000;
  color: #fff;
  font-size: calc(0.78rem + 9 * (100vw -  376px) / 1164);
  margin: 0 0.2em;
  padding: 0.2em 0.2em;
}

@media (min-width: 1637px) {
  .training-intro .is-catch {
    font-size: 1.5rem;
  }
}

.training-intro__point {
  position: relative;
}

.training-intro__point::before {
  content: "●";
  color: #1D2087;
  font-size: 0.7em;
  margin-right: 0.2em;
}

.training-move {
  padding-top: clamp(5rem, 10vw, 10rem);
  padding-bottom: clamp(4rem, 10vw, 10rem);
  position: relative;
}

@media (min-width: 1057px) {
  .training-move {
    padding-top: 10rem;
    padding-bottom: clamp(6rem, 10vw, 10rem);
  }
}

.training-move::before {
  position: absolute;
  top: clamp(5rem, 10vw, 10rem);
  right: 0;
  width: 100%;
  content: "";
  background: #1D2087;
  height: 40%;
  max-height: 480px;
  display: block;
}

@media (min-width: 1057px) {
  .training-move::before {
    padding-top: 10rem;
  }
}

.training-move__inner {
  position: relative;
  z-index: 3;
}

.training-move__tit {
  color: #000;
  font-weight: 900;
  font-size: calc(1rem + 26 * (100vw -  376px) / 1164);
  line-height: 1.4;
  text-align: center;
  position: relative;
  top: -1em;
}

@media (min-width: 1637px) {
  .training-move__tit {
    top: -1.25em;
    font-size: 2.63rem;
  }
}

.training-move__tit__inner {
  display: inline-block;
  background: #fff;
  padding: 0.75em 1.25em;
  position: relative;
  border: 1px solid #1D2087;
}

.training-move__list {
  display: flex;
}

.training-move__list.is-column01 {
  flex-direction: column;
}

@media (min-width: 576px) {
  .training-move__list.is-column01 {
    row-gap: clamp(3rem, 5vw, 5rem);
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 376px) {
  .training-move__list.is-column01 {
    row-gap: clamp(1.5rem, 2vw, 2rem);
  }
}

@media (min-width: 576px) {
  .training-move__list.is-column02 {
    flex-wrap: wrap;
    column-gap: 4%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
  .training-move__list.is-column02 > * {
    width: 48%;
  }
}

@media (max-width: 575px) {
  .training-move__list.is-column02 {
    flex-direction: column;
    row-gap: clamp(1.5rem, 2vw, 2rem);
  }
}

.training-move .btn-wrap {
  margin-top: clamp(3rem, 4vw, 4rem);
}

.training-move__box {
  width: 100%;
  border: 1px solid #E5E9EC;
  overflow: hidden;
}

.training-move__box__inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.training-move__box__video {
  width: 100%;
}

.training-move__box__video__inner {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.training-move__box__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.training-move__box__txt {
  padding: 1em 5% 1.75em;
  background-color: #fff;
  width: 100%;
  flex-grow: 1;
}

.training-feature {
  position: relative;
  margin-top: clamp(5rem, 9vw, 9rem);
  padding-bottom: clamp(2rem, 4vw, 4rem);
  margin-bottom: clamp(2rem, 5vw, 5rem);
}

.training-feature::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #EBF1FF;
}

@media (min-width: 1057px) {
  .training-feature::before {
    width: 98%;
  }
}

@media (min-width: 1637px) {
  .training-feature::before {
    width: 95%;
  }
}

.training-feature__add {
  position: absolute;
  bottom: calc(100% - 0.5em);
  left: calc(50% - 2rem);
  z-index: 1;
  font-weight: 700;
  letter-spacing: 0.05em;
  font-size: calc(2rem + 80 * (100vw -  376px) / 1164);
  line-height: 0.85;
  display: none;
  width: 100%;
}

@media (min-width: 768px) {
  .training-feature__add {
    left: 60%;
    font-size: calc(6rem + 100 * (100vw - 768px) / 772);
    display: block;
  }
}

@media (min-width: 1637px) {
  .training-feature__add {
    left: calc(50% - 2rem);
    font-size: calc(20rem + 100 * (100vw - 1540px) / 188);
  }
}

@media (min-width: 1637px) {
  .training-feature__add {
    font-size: 12.5rem;
  }
}

.training-feature__add span {
  font-family: 'Montserrat', sans-serif;
  color: #E5EBF8;
}

.training-feature__inner {
  position: relative;
  z-index: 1;
}

.training-feature__tit {
  display: inline-block;
  background: #fff;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding: 2em 4% 2em;
  top: -3rem;
  line-height: 1.4;
}

.training-feature__tit::before {
  content: "";
  background: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 100%;
}

.training-feature__tit span {
  display: block;
  font-weight: 700;
  font-size: calc(0.75rem + 6 * (100vw -  376px) / 1164);
  padding: 0.3em 0.5em 0.3em 0;
  background: #1D2087;
  color: #fff;
  position: relative;
}

@media (min-width: 1637px) {
  .training-feature__tit span {
    font-size: 1.25rem;
  }
}

.training-feature__tit span::after {
  content: "";
  position: absolute;
  top: 0;
  right: 100%;
  background: #1D2087;
  width: 100vw;
  height: 100%;
}

.training-feature__tit strong {
  display: block;
  line-height: 1;
  font-weight: 700;
  font-size: calc(1.75rem + 8 * (100vw -  376px) / 1164);
  letter-spacing: 0.15em;
}

@media (min-width: 1637px) {
  .training-feature__tit strong {
    font-size: 2rem;
  }
}

.training-feature__tit strong b {
  font-size: 2.5em;
  color: #CE0000;
}

.training-feature__list {
  display: flex;
  flex-direction: column;
  row-gap: clamp(3rem, 6vw, 6rem);
}

.training-feature__box {
  padding-bottom: clamp(2rem, 3vw, 3rem);
  position: relative;
}

.training-feature__box:nth-of-type(odd)::before {
  right: -8%;
}

.training-feature__box:nth-of-type(odd) .training-feature__box__img {
  right: 0;
}

@media (min-width: 768px) {
  .training-feature__box:nth-of-type(even) .training-feature__box__inner {
    display: flex;
    justify-content: flex-end;
  }
}

@media (min-width: 768px) {
  .training-feature__box:nth-of-type(even) .training-feature__box__txt {
    display: flex;
    justify-content: flex-end;
  }
}

.training-feature__box:nth-of-type(even)::before {
  left: -7%;
}

.training-feature__box:nth-of-type(even) .training-feature__box__img {
  left: 0;
}

@media (min-width: 768px) {
  .training-feature__box:nth-of-type(even) .training-feature__box__txt__inner {
    padding: 3.5rem 8% 2.5rem 16%;
  }
}

.training-feature__box::before {
  position: absolute;
  bottom: 0;
  content: "";
  background: #1D2087;
  width: 80%;
  height: 80%;
}

.training-feature__box__inner {
  position: relative;
}

@media (min-width: 1057px) {
  .training-feature__box__inner {
    padding-top: 4rem;
  }
}

@media (min-width: 768px) {
  .training-feature__box__img {
    position: absolute;
    top: 3.5rem;
    right: 0;
    width: 38.96%;
  }
}

@media (min-width: 1057px) {
  .training-feature__box__img {
    top: 0;
  }
}

.training-feature__box__img__inner {
  position: relative;
  width: 100%;
}

.training-feature__box__img__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}

.training-feature__box__img__inner > div,
.training-feature__box__img__inner figure,
.training-feature__box__img__inner a,
.training-feature__box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.training-feature__box__txt {
  background: #fff;
}

@media (min-width: 768px) {
  .training-feature__box__txt {
    width: 83.76%;
  }
}

.training-feature__box__txt__inner {
  padding: 2rem 8% 2.5rem;
  overflow: hidden;
}

@media (min-width: 768px) {
  .training-feature__box__txt__inner {
    padding: 3.5rem 16% 2.5rem 8%;
    min-height: 420px;
    width: 77.51%;
  }
}

.training-feature__box__tit {
  margin-bottom: calc(1rem + 1em);
  border-bottom: 2px solid #1D2087;
  padding-bottom: clamp(1rem, 2vw, 2rem);
}

@media (min-width: 1057px) {
  .training-feature__box__tit {
    margin-bottom: calc(1rem + 2em);
  }
}

.training-feature__box__tit b {
  display: inline-block;
  position: relative;
  padding-right: 1em;
  font-weight: 700;
  font-size: calc(1rem + 16 * (100vw -  376px) / 1164);
}

@media (min-width: 768px) {
  .training-feature__box__tit b {
    font-size: calc(1.25rem + 12 * (100vw - 768px) / 772);
  }
}

@media (min-width: 1637px) {
  .training-feature__box__tit b {
    font-size: 2rem;
  }
}

.training-feature__box__tit span {
  color: #CE0000;
}

.training-comment {
  position: relative;
  padding-bottom: clamp(3rem, 6vw, 6rem);
}

.training-comment::before {
  z-index: 1;
  content: "";
  width: 100%;
  height: 60%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #1D2087;
}

.training-comment__inner {
  position: relative;
  z-index: 2;
  background: #fff;
  border: 1px solid #1D2087;
  padding: 2em 5%;
  border: 1px solid #1D2087;
  font-weight: 700;
  font-size: calc(0.85rem + 4 * (100vw -  376px) / 1164);
  background: #fff;
}

@media (min-width: 768px) {
  .training-comment__inner {
    text-align: center;
    padding: 2em 8%;
  }
}

@media (min-width: 1637px) {
  .training-comment__inner {
    font-size: 1.25rem;
  }
}

/*--------------------------------------------------------------------/
	ecosuru
/--------------------------------------------------------------------*/
.ecosuru-intro {
  position: relative;
  background: #F7F9FB;
  padding-top: clamp(3rem, 6vw, 6rem);
  padding-bottom: clamp(6rem, 9vw, 9rem);
  margin-bottom: clamp(6rem, 14vw, 14rem);
}

.ecosuru-intro::before {
  content: "";
  background: #1D2087;
  width: 100%;
  height: 50%;
  max-height: 680px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.ecosuru-intro__add {
  width: 100vw;
  position: absolute;
  top: calc(100% - 5.64vw);
  left: 5%;
  z-index: 2;
}

@media (min-width: 1637px) {
  .ecosuru-intro__add {
    top: calc(100% - 80px);
  }
}

.ecosuru-intro__add span {
  font-family: 'Montserrat', sans-serif;
  color: #EBF1FF;
  font-weight: 700;
  letter-spacing: 0.05em;
  font-size: calc(3.5rem + 130 * (100vw -  376px) / 1164);
  line-height: 0.85;
}

@media (min-width: 1637px) {
  .ecosuru-intro__add span {
    bottom: 4.5rem;
    font-size: calc(10rem + 40 * (100vw - 1540px) / 188);
  }
}

@media (min-width: 1825px) {
  .ecosuru-intro__add span {
    font-size: 12.5rem;
  }
}

.ecosuru-intro__inner {
  position: relative;
  z-index: 2;
}

.ecosuru-intro__tit {
  width: 100%;
  padding: 1em 5%;
  background: #fff;
  margin-bottom: clamp(1.5rem, 2vw, 2rem);
  position: relative;
  line-height: 1.2;
}

@media (min-width: 1057px) {
  .ecosuru-intro__tit {
    padding: 1em 2rem;
  }
}

.ecosuru-intro__tit strong {
  color: #CE0000;
  font-weight: 900;
  display: inline-block;
  margin-right: 0.5em;
  font-size: calc(2rem + 32 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .ecosuru-intro__tit strong {
    font-size: 4rem;
  }
}

.ecosuru-intro__tit span {
  color: #000;
  display: inline-block;
  font-weight: 700;
  font-size: calc(1rem + 8 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .ecosuru-intro__tit span {
    font-size: 1.5rem;
  }
}

.ecosuru-intro__tit::before {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  background: #fff;
}

.ecosuru-intro__box {
  background: #fff;
  border: 1px solid #1D2087;
  position: relative;
  overflow: hidden;
}

@media (min-width: 1057px) {
  .ecosuru-intro__box__inner {
    display: grid;
    grid-template-columns: 35% 1fr;
    grid-template-rows: auto 1fr;
  }
}

.ecosuru-intro__box__img {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 5% 0;
}

@media (min-width: 1057px) {
  .ecosuru-intro__box__img {
    padding: 1.5em 8% 2em;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }
}

.ecosuru-intro__box__img__inner {
  width: clamp(160px, 50%, 320px);
}

@media (min-width: 1057px) {
  .ecosuru-intro__box__img__inner {
    width: 60%;
  }
}

.ecosuru-intro__box__txt {
  padding: clamp(1.5rem, 3vw, 3rem) 5% clamp(1.5rem, 3vw, 3rem);
  font-weight: 700;
}

@media (min-width: 1057px) {
  .ecosuru-intro__box__txt {
    padding: clamp(1.5rem, 3vw, 3rem) 5% clamp(1.5rem, 3vw, 3rem) 0;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }
}

.ecosuru-intro__box__txt span {
  color: #CE0000;
}

.ecosuru-intro__box__point {
  padding: 0 5% clamp(2rem, 3vw, 3rem);
}

@media (min-width: 1057px) {
  .ecosuru-intro__box__point {
    padding: 0 5% clamp(2rem, 5vw, 5rem) 0;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }
}

.ecosuru-intro__box__point__tit {
  font-size: calc(1rem + 8 * (100vw -  376px) / 1164);
  margin-bottom: calc(1rem + 1em);
}

@media (min-width: 1057px) {
  .ecosuru-intro__box__point__tit {
    font-size: calc(1.14rem + 6 * (100vw -  376px) / 1164);
  }
}

@media (min-width: 1637px) {
  .ecosuru-intro__box__point__tit {
    font-size: 1.5rem;
  }
}

.ecosuru-intro__box__point__tit span {
  position: relative;
  padding: 0.5em 1em;
  color: #fff;
  background: #CE0000;
}

.ecosuru-intro__box__point__tit span::after {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  width: 100vw;
  height: 100%;
  background: #CE0000;
}

.ecosuru-intro__box__point__flex {
  display: flex;
}

@media (min-width: 768px) {
  .ecosuru-intro__box__point__flex {
    justify-content: flex-start;
    column-gap: 3.67%;
  }
}

@media (max-width: 767px) {
  .ecosuru-intro__box__point__flex {
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .ecosuru-intro__box__point__img {
    width: 27.83%;
  }
}

@media (max-width: 767px) {
  .ecosuru-intro__box__point__img {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    max-width: 280px;
    margin-bottom: 1rem;
  }
}

.ecosuru-intro__box__point__list {
  width: 100%;
}

@media (min-width: 768px) {
  .ecosuru-intro__box__point__list {
    width: 68.5%;
  }
}

.ecosuru-intro__box__point__list__list {
  display: flex;
  flex-direction: column;
  row-gap: 0.5em;
}

.ecosuru-intro__box__point__list__list li {
  width: 100%;
  padding: 0.5em 1em 0.5em 3em;
  border: 1px solid #C9CED2;
  position: relative;
}

.ecosuru-intro__box__point__list__list li::before {
  position: absolute;
  top: 0.5em;
  left: 1em;
  content: "";
  width: 1.5em;
  height: 1.5em;
  background: url(../img/common/icon-check.svg) center center/cover no-repeat;
}

.ecosuru-banner {
  background: #F7F9FB;
  padding: clamp(3rem, 6vw, 6rem) 0 0;
}

.ecosuru-banner__tit {
  padding: 1em 5%;
  color: #fff;
  background: #CE0000;
  text-align: center;
  font-size: calc(1rem + 8 * (100vw -  376px) / 1164);
  font-weight: 700;
  margin-bottom: clamp(1.5rem, 3vw, 3rem);
}

@media (min-width: 1637px) {
  .ecosuru-banner__tit {
    font-size: 2rem;
  }
}

.ecosuru-banner__item {
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
}

.ecosuru-list {
  margin-bottom: clamp(6rem, 9vw, 9rem);
}

.ecosuru-list__inner {
  display: flex;
  flex-direction: column;
  row-gap: clamp(3rem, 6vw, 6rem);
}

.ecosuru-box {
  padding: 2em 0 2.5em;
  position: relative;
}

@media (min-width: 1057px) {
  .ecosuru-box {
    padding: 3.5em 0 4em;
  }
}

.ecosuru-box::before {
  content: "";
  position: absolute;
  top: 0;
  right: 5%;
  width: 100vw;
  height: 50%;
  z-index: 2;
  background: #1D2087;
}

@media (min-width: 1057px) {
  .ecosuru-box::before {
    right: 16.23%;
  }
}

.ecosuru-box__inner {
  position: relative;
  z-index: 2;
}

.ecosuru-box__tit {
  font-size: calc(1.14rem + 14 * (100vw -  376px) / 1164);
  font-weight: 700;
  color: #fff;
  padding-right: 7%;
  margin-bottom: calc(0.5em + 1rem);
}

@media (min-width: 1057px) {
  .ecosuru-box__tit {
    padding-right: 18.23%;
  }
}

@media (min-width: 1637px) {
  .ecosuru-box__tit {
    font-size: 2rem;
  }
}

.ecosuru-box__tit span {
  display: inline-block;
  margin-right: .2em;
  margin-bottom: .3em;
  vertical-align: baseline;
}

.ecosuru-box__tit b {
  color: #fff;
  display: inline-block;
  padding: .1em .2em;
  vertical-align: baseline;
  font-size: 1.5em;
  background: #CE0000;
}

.ecosuru-box__box {
  padding: 1.5em 5% 2em;
  background: #F6F9FF;
  position: relative;
  display: flex;
}

@media (min-width: 768px) {
  .ecosuru-box__box {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

@media (min-width: 1057px) {
  .ecosuru-box__box {
    padding: 2.5em 5% 2em;
  }
}

@media (max-width: 767px) {
  .ecosuru-box__box {
    row-gap: 1.5rem;
  }
}

@media (min-width: 768px) {
  .ecosuru-box__box__img {
    width: 35.6%;
  }
}

@media (max-width: 767px) {
  .ecosuru-box__box__img {
    margin-bottom: 1rem;
  }
}

@media (min-width: 768px) {
  .ecosuru-box__box__img__inner {
    position: relative;
    width: 100%;
  }
  .ecosuru-box__box__img__inner::before {
    content: "";
    display: block;
    padding-top: 100%;
    position: relative;
  }
  .ecosuru-box__box__img__inner > div,
  .ecosuru-box__box__img__inner figure,
  .ecosuru-box__box__img__inner a,
  .ecosuru-box__box__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .ecosuru-box__box__img__inner {
    position: relative;
    width: 100%;
  }
  .ecosuru-box__box__img__inner::before {
    content: "";
    display: block;
    padding-top: 66.66%;
    position: relative;
  }
  .ecosuru-box__box__img__inner > div,
  .ecosuru-box__box__img__inner figure,
  .ecosuru-box__box__img__inner a,
  .ecosuru-box__box__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .ecosuru-box__box__txt {
    width: 56.37%;
  }
}

.ecosuru-box__box__txt .txt {
  margin-bottom: 2rem;
  font-weight: 500;
}

.ecosuru-box__box__feature {
  border: 1px solid #1D2087;
  padding: 1.5em 5% 2em;
  background: #fff;
}

.ecosuru-box__box__feature__tit {
  font-size: calc(0.94rem + 3 * (100vw -  376px) / 1164);
  font-weight: 700;
  margin-bottom: 1rem;
}

@media (min-width: 1637px) {
  .ecosuru-box__box__feature__tit {
    font-size: 1.13rem;
  }
}

.ecosuru-box__box__video {
  margin-top: clamp(1.5rem, 2vw, 2.5rem);
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.ecosuru-pdf {
  margin-bottom: clamp(6rem, 9vw, 9rem);
}

.ecosuru-pdf__inner {
  background: #fff;
  padding: 3em 5% 2em;
  border: 1px solid #1D2087;
  position: relative;
}

@media (min-width: 1057px) {
  .ecosuru-pdf__inner {
    padding: 4em 8% 3em;
  }
}

.ecosuru-pdf__tit {
  position: absolute;
  width: 100%;
  top: -1em;
  left: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
  font-size: calc(1rem + 12 * (100vw -  376px) / 1164);
}

@media (min-width: 1057px) {
  .ecosuru-pdf__tit {
    top: -1.2em;
  }
}

@media (min-width: 1637px) {
  .ecosuru-pdf__tit {
    font-size: 2rem;
  }
}

.ecosuru-pdf__tit span {
  display: inline-block;
  color: #fff;
  background: #CE0000;
  padding: .4em 1em;
}

@media (min-width: 1057px) {
  .ecosuru-pdf__tit span {
    padding: .4em 1.5em;
  }
}

@media (min-width: 1057px) {
  .ecosuru-pdf__list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2%;
    row-gap: 1rem;
  }
  .ecosuru-pdf__list > * {
    width: 49%;
  }
}

@media (min-width: 1637px) {
  .ecosuru-pdf__list {
    column-gap: 1.04%;
  }
  .ecosuru-pdf__list > * {
    width: 32.64%;
  }
}

@media (max-width: 1056px) {
  .ecosuru-pdf__list li:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}

.ecosuru-pdf__list li a {
  padding: 1rem 4% 1rem 5em;
  transition: all 0.3s ease 0s;
  background: #1D2087;
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  color: #fff;
  text-decoration: none !important;
  font-weight: 500;
}

.ecosuru-pdf__list li a:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ecosuru-pdf__list li a::before {
  position: absolute;
  left: 1em;
  font-size: 2.14em;
  color: #fff;
}

@media (min-width: 576px) {
  .ecosuru-pdf__list li a::before {
    font-size: 2em;
  }
}

@media (min-width: 1057px) {
  .ecosuru-pdf__list li a::before {
    font-size: 1.87em;
  }
}

@media (min-width: 1057px) {
  .ecosuru-pdf__list li a::before {
    position: absolute;
    left: 1.25em;
  }
}

.ecosuru-pdf__list li a:hover {
  background: #120CBC;
  color: #fff;
}

.ecosuru-trader {
  overflow: hidden;
  position: relative;
  padding-top: clamp(4rem, 8vw, 8rem);
  padding-bottom: clamp(3rem, 6vw, 6rem);
  margin-bottom: clamp(6rem, 9vw, 9rem);
}

.ecosuru-trader::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background: #EAFBEA;
  width: 100%;
  height: 100%;
}

@media (min-width: 1057px) {
  .ecosuru-trader::before {
    width: 97%;
  }
}

@media (min-width: 1637px) {
  .ecosuru-trader::before {
    width: calc(50% + 770px);
  }
}

.ecosuru-trader__inner {
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.ecosuru-trader__add {
  position: absolute;
  top: -2rem;
  left: 60%;
  width: 48%;
  min-width: 360px;
  z-index: -1;
}

@media (min-width: 1057px) {
  .ecosuru-trader__add {
    top: -5rem;
    left: 54%;
    width: 52%;
  }
}

@media (min-width: 1637px) {
  .ecosuru-trader__add {
    top: -7rem;
  }
}

@media (max-width: 575px) {
  .ecosuru-trader__add {
    display: none;
  }
}

.ecosuru-trader__tit {
  font-size: calc(1rem + 14 * (100vw -  376px) / 1164);
  margin-bottom: calc(1rem + 0.5em);
}

@media (min-width: 1057px) {
  .ecosuru-trader__tit {
    width: 50%;
    font-size: calc(1.25rem + 10 * (100vw - 768px) / 772);
  }
}

@media (min-width: 1637px) {
  .ecosuru-trader__tit {
    font-size: 2rem;
  }
}

.ecosuru-trader__tit span {
  display: inline-block;
  padding: 1em 1em 1em 0;
  position: relative;
  color: #fff;
}

@media (min-width: 768px) {
  .ecosuru-trader__tit span {
    padding: 1em 2em 1em 0;
  }
}

@media (max-width: 575px) {
  .ecosuru-trader__tit span {
    width: 100%;
  }
}

.ecosuru-trader__tit span::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background: #17A541;
  width: 120%;
  height: 100%;
  z-index: -1;
}

@media (min-width: 1637px) {
  .ecosuru-trader__tit span::before {
    width: calc(100% + 260px);
  }
}

.ecosuru-trader__txt {
  font-weight: 500;
  margin-bottom: clamp(2rem, 3vw, 3rem);
}

@media (min-width: 1057px) {
  .ecosuru-trader__table {
    padding-top: 5rem;
  }
}

.ecosuru-trader__table table {
  width: 100%;
  font-size: 1.07em;
  margin-bottom: 2rem;
  background: #fff;
}

@media (min-width: 576px) {
  .ecosuru-trader__table table {
    font-size: 1em;
  }
}

@media (min-width: 1057px) {
  .ecosuru-trader__table table {
    font-size: 0.93em;
  }
}

.ecosuru-trader__table table tr {
  border-bottom: 1px solid #17A541;
  border-left: 1px solid #17A541;
  border-right: 1px solid #17A541;
}

.ecosuru-trader__table table tr:first-child {
  border-top: 1px solid #17A541;
}

.ecosuru-trader__table table th,
.ecosuru-trader__table table td.column-1 {
  text-align: left;
  font-weight: 500;
  padding: 1rem 1.5em;
  background: #F3F5F7;
}

@media (min-width: 1057px) {
  .ecosuru-trader__table table th,
  .ecosuru-trader__table table td.column-1 {
    width: 25%;
    min-width: 180px;
  }
}

@media (min-width: 1637px) {
  .ecosuru-trader__table table th,
  .ecosuru-trader__table table td.column-1 {
    width: 220px;
  }
}

@media (max-width: 1056px) {
  .ecosuru-trader__table table th,
  .ecosuru-trader__table table td.column-1 {
    display: block;
  }
}

.ecosuru-trader__table table td.column-2 {
  padding: 1rem 4%;
  display: flex;
}

@media (min-width: 1637px) {
  .ecosuru-trader__table table td.column-2 {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2%;
    row-gap: 0.3em;
  }
}

@media (max-width: 1636px) {
  .ecosuru-trader__table table td.column-2 {
    flex-direction: column;
    row-gap: 0.3em;
  }
}

.ecosuru-trader__table table td.column-2 > span {
  padding-left: 1.5em;
  position: relative;
}

@media (min-width: 1637px) {
  .ecosuru-trader__table table td.column-2 > span {
    width: 49%;
  }
}

.ecosuru-trader__table table td.column-2 > span::before {
  content: "・";
  position: absolute;
  top: 0;
  left: 0;
}

/*--------------------------------------------------------------------/
	transmitter
/--------------------------------------------------------------------*/
.transmitter-intro {
  position: relative;
  padding-top: clamp(4rem, 6vw, 6rem);
  padding-bottom: clamp(5rem, 20vw, 20rem);
}

@media (min-width: 1057px) {
  .transmitter-intro {
    padding-top: clamp(2rem, 3vw, 3rem);
    padding-bottom: clamp(10rem, 20vw, 20rem);
  }
}

.transmitter-intro::before {
  content: "";
  background: #0E0F53;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: calc(100% - 2rem);
}

@media (min-width: 1057px) {
  .transmitter-intro::before {
    height: calc(100% - 10vw);
  }
}

@media (min-width: 1637px) {
  .transmitter-intro::before {
    height: calc(100% - 5rem);
    width: calc(100% - 2%);
  }
}

@media (min-width: 1825px) {
  .transmitter-intro::before {
    width: calc(50% + 864px);
  }
}

.transmitter-intro::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: calc(100% - 3rem);
  background: #1D2087;
  z-index: 2;
  display: block;
  width: 80%;
}

@media (min-width: 1057px) {
  .transmitter-intro::after {
    height: calc(100% - 10vw);
    width: 100%;
  }
}

@media (min-width: 1637px) {
  .transmitter-intro::after {
    height: calc(100% - 8rem);
    width: calc(100% - 2%);
  }
}

@media (min-width: 1825px) {
  .transmitter-intro::after {
    width: calc(50% + 864px);
  }
}

.transmitter-intro__add {
  position: absolute;
  bottom: 0;
  right: 5%;
  z-index: 3;
}

.transmitter-intro__add span {
  font-family: 'Montserrat', sans-serif;
  color: #474897;
  font-weight: 700;
  letter-spacing: 0.05em;
  font-size: calc(2rem + 130 * (100vw -  376px) / 1164);
  line-height: 0.85;
}

@media (min-width: 1637px) {
  .transmitter-intro__add span {
    font-size: calc(6rem + 64 * (100vw - 1540px) / 188);
  }
}

@media (min-width: 1825px) {
  .transmitter-intro__add span {
    font-size: 10rem;
  }
}

.transmitter-intro__inner {
  position: relative;
  z-index: 4;
}

.transmitter-intro__tit {
  color: #fff;
  font-size: calc(1rem + 32 * (100vw -  376px) / 1164);
  font-weight: 700;
  margin-bottom: clamp(1.5rem, 3vw, 3rem);
}

@media (min-width: 1637px) {
  .transmitter-intro__tit {
    font-size: 3rem;
  }
}

.transmitter-intro__cont {
  width: 100%;
  position: relative;
}

@media (min-width: 1057px) {
  .transmitter-intro__cont__img {
    position: absolute;
    top: 0;
    left: 54%;
    width: 47vw;
  }
}

.transmitter-intro__cont__img__inner {
  width: 100%;
  position: relative;
  width: 100%;
}

.transmitter-intro__cont__img__inner::before {
  content: "";
  display: block;
  padding-top: 56.25%;
  position: relative;
}

.transmitter-intro__cont__img__inner > div,
.transmitter-intro__cont__img__inner figure,
.transmitter-intro__cont__img__inner a,
.transmitter-intro__cont__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

@media (min-width: 1057px) {
  .transmitter-intro__cont__img__inner {
    position: relative;
    width: 100%;
  }
  .transmitter-intro__cont__img__inner::before {
    content: "";
    display: block;
    padding-top: 80%;
    position: relative;
  }
  .transmitter-intro__cont__img__inner > div,
  .transmitter-intro__cont__img__inner figure,
  .transmitter-intro__cont__img__inner a,
  .transmitter-intro__cont__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.transmitter-intro__cont__txt {
  background: #fff;
  position: relative;
}

@media (min-width: 1057px) {
  .transmitter-intro__cont__txt {
    z-index: 2;
    top: 2.5rem;
    width: 60%;
    min-height: 600px;
  }
}

@media (max-width: 1056px) {
  .transmitter-intro__cont__txt {
    position: relative;
  }
}

@media (min-width: 1057px) {
  .transmitter-intro__cont__txt::before {
    content: "";
    background: #fff;
    position: absolute;
    right: 100%;
    top: 0;
    width: 100vw;
    height: 100%;
  }
}

.transmitter-intro__cont__txt__inner {
  padding: 1.5em 5% 2em;
}

@media (min-width: 1057px) {
  .transmitter-intro__cont__txt__inner {
    padding: 0 8% 4em 0;
  }
}

.transmitter-intro__cont__catch {
  font-size: calc(0.94rem + 3 * (100vw -  376px) / 1164);
  font-weight: 700;
  margin-bottom: clamp(1.5rem, 2vw, 2rem);
  position: relative;
}

@media (min-width: 1057px) {
  .transmitter-intro__cont__catch {
    top: -2.5rem;
    font-size: calc(0.94rem + 4 * (100vw - 1000px) / 540);
  }
}

@media (min-width: 1637px) {
  .transmitter-intro__cont__catch {
    font-size: 1.25rem;
  }
}

.transmitter-intro__cont__catch__up {
  padding: 0.75em 1.25em;
  background: #fff;
  border: 1px solid #1D2087;
  margin-bottom: 0.5em;
}

@media (min-width: 1057px) {
  .transmitter-intro__cont__catch__up {
    display: inline-block;
  }
}

.transmitter-intro__cont__catch__bottom {
  padding: 0.75em 1.25em;
  background: #CE0000;
  color: #fff;
}

@media (min-width: 1057px) {
  .transmitter-intro__cont__catch__bottom {
    display: inline-block;
  }
}

.transmitter-intro__cont__point {
  display: flex;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .transmitter-intro__cont__point {
    flex-wrap: wrap;
    column-gap: 2%;
    row-gap: 1vw;
  }
}

@media (min-width: 1637px) {
  .transmitter-intro__cont__point {
    row-gap: 1rem;
  }
}

@media (max-width: 767px) {
  .transmitter-intro__cont__point {
    flex-direction: column;
    row-gap: 0.75rem;
  }
}

.transmitter-intro__cont__point li {
  padding: 1em 2%;
  text-align: center;
  background: #fff;
  color: #CE0000;
  border: 1px solid #CE0000;
  font-weight: 700;
}

@media (min-width: 768px) {
  .transmitter-intro__cont__point li {
    width: 49%;
  }
}

@media (min-width: 1057px) {
  .transmitter-intro__cont__point li {
    font-size: calc(1rem + 2 * (100vw - 1000px) / 540);
  }
}

@media (min-width: 1637px) {
  .transmitter-intro__cont__point li {
    font-size: 1.25rem;
  }
}

.transmitter-intro__cont__gallery {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2%;
  row-gap: 1.5vw;
  padding-bottom: clamp(2rem, 3vw, 3rem);
}

@media (min-width: 1637px) {
  .transmitter-intro__cont__gallery {
    row-gap: 1.25rem;
  }
}

.transmitter-intro__cont__gallery > * {
  width: 32%;
}

.transmitter-intro__cont__case {
  border-top: 1px solid #E5E9EC;
  padding-top: clamp(1.5rem, 2.5vw, 2.5rem);
}

@media (min-width: 576px) {
  .transmitter-intro__cont__case__inner {
    display: flex;
  }
}

.transmitter-intro__cont__case__tit {
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .transmitter-intro__cont__case__tit {
    width: 23.37%;
    padding-left: 0.2em;
    font-size: calc(1rem + 2 * (100vw - 576px) / 964);
    min-width: 110px;
  }
}

@media (min-width: 1637px) {
  .transmitter-intro__cont__case__tit {
    font-size: 1.13rem;
  }
}

@media (min-width: 576px) {
  .transmitter-intro__cont__case .common-ul {
    width: auto;
  }
}

.transmitter-flow {
  margin-top: clamp(6rem, 9vw, 9rem);
  padding-bottom: clamp(4rem, 9vw, 9rem);
  background: #F3F5F7;
}

.transmitter-flow__tit {
  text-align: center;
  position: relative;
  top: -1.5em;
  margin-bottom: calc(2rem + 1em);
}

@media (min-width: 576px) {
  .transmitter-flow__tit {
    top: -2rem;
  }
}

.transmitter-flow__tit em {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.1em;
  font-style: normal;
  display: block;
  color: #1D2087;
  font-weight: 700;
  position: relative;
  line-height: 1.2;
  font-size: calc(2.25rem + 28 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .transmitter-flow__tit em {
    font-size: 3.75rem;
  }
}

.transmitter-flow__tit span {
  font-family: 'Noto Sans JP', sans-serif;
  letter-spacing: 0.1em;
  display: block;
  font-weight: 700;
  position: relative;
  line-height: 1.4;
  font-size: calc(1.14rem + 10 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .transmitter-flow__tit span {
    font-size: 1.75rem;
  }
}

.transmitter-flow__list {
  counter-reset: flow-num;
}

@media (min-width: 1637px) {
  .transmitter-flow__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    column-gap: 1.8625%;
    row-gap: 4rem;
  }
  .transmitter-flow__list > * {
    width: 18.51%;
  }
}

@media (max-width: 1636px) {
  .transmitter-flow__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: clamp(3rem, 6vw, 6rem);
  }
  .transmitter-flow__list > * {
    width: 100%;
    max-width: 960px;
  }
}

.transmitter-flow__box {
  position: relative;
}

.transmitter-flow__box:not(:last-of-type)::before {
  position: absolute;
  content: "";
}

@media (min-width: 1637px) {
  .transmitter-flow__box:not(:last-of-type)::before {
    top: 50%;
    left: 100%;
    width: 80%;
    height: 1px;
    background-size: 8px 1px;
    background-image: linear-gradient(to right, #1D2087, #1D2087 3px, transparent 3px, transparent 8px);
  }
}

@media (max-width: 1636px) {
  .transmitter-flow__box:not(:last-of-type)::before {
    width: 1px;
    height: 80%;
    top: 100%;
    left: calc(50% + 15px);
    background-size: 1px 8px;
    background-image: linear-gradient(to bottom, #1D2087, #1D2087 3px, transparent 3px, transparent 8px);
  }
}

@media (max-width: 575px) {
  .transmitter-flow__box:not(:last-of-type)::before {
    left: calc(50% + 10px);
  }
}

@media (min-width: 1637px) {
  .transmitter-flow__box:nth-of-type(5n)::before {
    display: none;
  }
}

.transmitter-flow__box__inner {
  width: calc(100% - 20px);
  margin-left: 20px;
  height: 100%;
  border: 1px solid #1D2087;
  background: #fff;
  position: relative;
  z-index: 2;
  padding: 1.5em 8% 2em;
  display: flex;
  flex-direction: column;
}

@media (min-width: 576px) and (max-width: 1636px) {
  .transmitter-flow__box__inner {
    flex-direction: row;
    column-gap: 4%;
    padding: 1.5em 8% 1.5em 80px;
  }
}

@media (min-width: 576px) {
  .transmitter-flow__box__inner {
    width: calc(100% - 30px);
    margin-left: 30px;
  }
}

@media (min-width: 1637px) {
  .transmitter-flow__box__inner {
    padding: 1.5em 8% 2em;
  }
}

@media (min-width: 1825px) {
  .transmitter-flow__box__inner {
    padding: 1.5em 10% 2em;
  }
}

.transmitter-flow__box__num {
  position: absolute;
  top: -20px;
  left: -20px;
  width: 80px;
  height: 80px;
  background: #1D2087;
  z-index: 3;
}

@media (min-width: 576px) {
  .transmitter-flow__box__num {
    top: -30px;
    left: -30px;
  }
}

.transmitter-flow__box__num span {
  position: relative;
  width: 100%;
  height: 100%;
  color: #fff;
  display: block;
}

.transmitter-flow__box__num span::before {
  counter-increment: flow-num;
  content: counter(flow-num, decimal-leading-zero);
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.05em;
  position: absolute;
  top: calc(50% - 0.5em);
  left: calc(50% - 0.65em);
  font-size: 2.25rem;
}

.transmitter-flow__box__img {
  width: 120px;
  height: 120px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  flex-shrink: 0;
}

.transmitter-flow__box__img__inner {
  position: relative;
  width: 100%;
}

.transmitter-flow__box__img__inner::before {
  content: "";
  display: block;
  padding-top: 100%;
  position: relative;
}

.transmitter-flow__box__img__inner > div,
.transmitter-flow__box__img__inner figure,
.transmitter-flow__box__img__inner a,
.transmitter-flow__box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.transmitter-flow__box__tit {
  font-weight: 700;
  font-size: calc(1rem + 4 * (100vw -  376px) / 1164);
  margin-bottom: 0.4em;
}

@media (min-width: 1637px) {
  .transmitter-flow__box__tit {
    font-size: 1.25rem;
  }
}

.transmitter-contact {
  position: relative;
  margin-top: clamp(6rem, 9vw, 9rem);
  padding-bottom: clamp(5rem, 9vw, 9rem);
  border-bottom: #1D2087 solid;
  border-width: 40px;
}

@media (min-width: 768px) {
  .transmitter-contact {
    border-width: 72px;
  }
}

.transmitter-contact::before {
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 80%;
  background: #6768AF;
}

.transmitter-contact__inner {
  position: relative;
  background: #fff;
  z-index: 2;
  border: 1px solid #1D2087;
}

.transmitter-contact__tit {
  background: #1D2087;
  color: #fff;
  font-weight: 700;
  font-size: calc(1rem + 10 * (100vw -  376px) / 1164);
  padding: 0.5em 5%;
}

@media (min-width: 576px) {
  .transmitter-contact__tit {
    text-align: center;
  }
}

@media (min-width: 1637px) {
  .transmitter-contact__tit {
    font-size: 2rem;
  }
}

.transmitter-contact__tit b {
  padding: 0.1em 0.2em;
  margin-left: 0.2em;
  margin-right: 0.2em;
  color: #fff;
  font-size: 1.75em;
  display: inline-block;
  background: #CE0000;
}

.transmitter-contact__txt {
  width: 100%;
  padding: 2em 5% 2.5em;
}

@media (min-width: 1057px) {
  .transmitter-contact__txt {
    padding: 2em 10% 4em;
  }
}

@media (min-width: 1637px) {
  .transmitter-contact__txt {
    padding: 2em 15% 4em;
  }
}

.transmitter-contact__outline__tit {
  font-weight: 700;
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: calc(1rem + 4 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .transmitter-contact__outline__tit {
    font-size: 1.25rem;
  }
}

.transmitter-contact__privacy {
  margin-top: 2rem;
  background: #EBF1FF;
  padding: 1.25em 5% 1.5em;
}

.transmitter-contact__privacy dt {
  color: #CE0000;
  font-weight: 700;
  font-size: calc(0.94rem + 4 * (100vw -  376px) / 1164);
  margin-bottom: 0.5rem;
}

@media (min-width: 768px) {
  .transmitter-contact__privacy dt {
    text-align: center;
  }
}

@media (min-width: 1057px) {
  .transmitter-contact__privacy dt {
    font-size: 1.25rem;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .transmitter-contact__privacy dd {
    text-align: center;
  }
}

/*--------------------------------------------------------------------/
	company
/-------------------------------------------------------------------*/
.company-message {
  padding-top: clamp(3rem, 5vw, 5.5rem);
  padding-bottom: clamp(8rem, 12vw, 16rem);
  position: relative;
}

@media (min-width: 1057px) {
  .company-message {
    padding-top: 5.5rem;
    padding-bottom: clamp(6rem, 16vw, 16rem);
  }
}

.company-message::before {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  content: "";
  background: #1D2087;
  height: calc(100% - 6vw);
  display: block;
}

@media (min-width: 1637px) {
  .company-message::before {
    height: calc(100% - 5rem);
    width: calc(100% - 2%);
  }
}

@media (min-width: 1825px) {
  .company-message::before {
    width: calc(50% + 864px);
  }
}

.company-message::after {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 50%;
  height: calc(100% - 10vw);
  display: block;
  content: "";
  background: #0E0F53;
  display: block;
}

@media (min-width: 768px) {
  .company-message::after {
    height: calc(100% - 4.5rem);
  }
}

@media (min-width: 1057px) {
  .company-message::after {
    width: 50%;
    height: calc(100% - 8rem);
  }
}

.company-message__add {
  position: absolute;
  bottom: 6vw;
  right: 5%;
  z-index: 2;
}

@media (min-width: 1637px) {
  .company-message__add {
    bottom: 4.5rem;
  }
}

.company-message__add span {
  font-family: 'Montserrat', sans-serif;
  color: #474897;
  font-weight: 700;
  letter-spacing: 0.05em;
  font-size: calc(3rem + 140 * (100vw -  376px) / 1164);
  line-height: 0.85;
}

@media (min-width: 1637px) {
  .company-message__add span {
    font-size: calc(10rem + 40 * (100vw - 1540px) / 188);
  }
}

@media (min-width: 1825px) {
  .company-message__add span {
    font-size: 12.5rem;
  }
}

.company-message__inner {
  position: relative;
  z-index: 3;
}

.company-message__tit {
  text-align: left;
  color: #fff;
  margin-bottom: clamp(1.5rem, 3vw, 3rem);
}

@media (min-width: 1057px) {
  .company-message__tit {
    margin-bottom: 4rem;
  }
}

.company-message__tit em {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.1em;
  font-style: normal;
  display: block;
  font-weight: 700;
  position: relative;
  line-height: 1.2;
  font-size: calc(1.5rem + 38 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .company-message__tit em {
    font-size: 3.75rem;
  }
}

.company-message__tit span {
  font-family: 'Noto Sans JP', sans-serif;
  letter-spacing: 0.1em;
  display: block;
  font-weight: 700;
  position: relative;
  line-height: 1.4;
  font-size: calc(1.14rem + 10 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .company-message__tit span {
    font-size: 1.75rem;
  }
}

.company-message__pic {
  position: relative;
  z-index: 3;
}

@media (min-width: 1057px) {
  .company-message__pic {
    position: absolute;
    right: 0;
    top: 5rem;
    width: 38.96%;
  }
}

@media (max-width: 1056px) {
  .company-message__pic {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

.company-message__pic img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.company-message__txt {
  background: #EBF1FF;
  padding: 5rem 5% 2rem;
  position: relative;
}

@media (min-width: 1057px) {
  .company-message__txt {
    width: 73.63%;
    padding: clamp(3rem, 5vw, 5rem) 20% clamp(3rem, 5vw, 4rem) 7%;
  }
}

@media (max-width: 1056px) {
  .company-message__txt {
    margin-top: -3rem;
  }
}

.company-message__txt .txt p:not(:last-of-type) {
  margin-bottom: 1.5rem;
}

@media (min-width: 1057px) {
  .company-message__txt .txt p:not(:last-of-type) {
    margin-bottom: 2rem;
  }
}

.company-outline {
  padding: clamp(4rem, 8vw, 9rem) 0 clamp(4rem, 12vw, 12rem);
}

.company-outline__table table {
  width: 100%;
  margin-bottom: 2rem;
}

.company-outline__table table tr {
  border-bottom: 1px solid #C9CED2;
}

.company-outline__table table tr:first-child {
  border-top: 1px solid #C9CED2;
}

.company-outline__table table th,
.company-outline__table table td.column-1 {
  text-align: left;
  font-weight: 500;
  color: #000;
  padding: 1rem 0;
}

@media (min-width: 1057px) {
  .company-outline__table table th,
  .company-outline__table table td.column-1 {
    width: 25%;
    min-width: 180px;
  }
}

@media (min-width: 1637px) {
  .company-outline__table table th,
  .company-outline__table table td.column-1 {
    width: 225px;
  }
}

@media (max-width: 1056px) {
  .company-outline__table table th,
  .company-outline__table table td.column-1 {
    display: block;
    padding-bottom: 0;
  }
}

.company-outline__table table td.column-2 {
  padding: 1rem 1.25rem 1rem 0.5rem;
}

@media (max-width: 1056px) {
  .company-outline__table table td.column-2 {
    display: block;
    padding-top: 0;
    padding-left: 0;
  }
}

.company-access {
  position: relative;
  padding-top: clamp(3rem, 6vw, 6rem);
}

@media (min-width: 1057px) {
  .company-access {
    padding-top: 6rem;
  }
}

.company-access::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #0E0F53;
  width: 100%;
  height: 40vw;
  min-height: 300px;
  max-height: 740px;
  z-index: 1;
}

.company-access__inner {
  position: relative;
  z-index: 2;
}

.company-access__tit {
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.1em;
  font-style: normal;
  display: block;
  font-weight: 700;
  position: relative;
  line-height: 1.2;
  font-size: calc(1.5rem + 38 * (100vw -  376px) / 1164);
  margin-bottom: calc(1.5rem + 0.5em);
}

@media (min-width: 1637px) {
  .company-access__tit {
    font-size: 3.75rem;
  }
}

.company-access__map {
  position: relative;
  padding: 0;
  height: 0;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
}

@media (min-width: 1057px) {
  .company-access__map {
    padding-bottom: 40%;
  }
}

@media (max-width: 1056px) {
  .company-access__map {
    padding-bottom: 300px;
  }
}

.company-access__map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.company-access__txt {
  margin-top: 1rem;
}

.company-access__txt ul {
  text-align: left;
}

.company-access__txt ul > li {
  line-height: 1.4;
  padding-left: 1.5rem;
  position: relative;
}

.company-access__txt ul > li::before {
  content: "●";
  color: #1D2087;
  position: absolute;
  left: 0;
  top: 0.2em;
  font-size: 0.8em;
}

.company-access__txt ul > li:not(:last-child) {
  margin-bottom: 0.5rem;
}

/*--------------------------------------------------------------------/
	contact_form
/--------------------------------------------------------------------*/
.contact-intro {
  padding: 0 4% clamp(5rem, 6vw, 6rem);
  position: relative;
  margin-bottom: clamp(6rem, 9vw, 9rem);
}

@media (min-width: 1057px) {
  .contact-intro {
    padding: 0 2.5rem clamp(6rem, 10vw, 10rem);
  }
}

.contact-intro::before {
  position: absolute;
  content: "";
  top: 5%;
  left: 0;
  background: #EBF1FF;
  width: 100%;
  height: 40%;
}

.contact-intro::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  background: #0E0F53;
  width: 100%;
  height: 55%;
}

.contact-intro__add {
  position: absolute;
  bottom: 0;
  right: 5%;
  z-index: 2;
}

.contact-intro__add span {
  font-family: 'Montserrat', sans-serif;
  color: #474897;
  font-weight: 700;
  letter-spacing: 0.05em;
  font-size: calc(3rem + 140 * (100vw -  376px) / 1164);
  line-height: 0.85;
}

@media (min-width: 1637px) {
  .contact-intro__add span {
    font-size: calc(10rem + 40 * (100vw - 1540px) / 188);
  }
}

@media (min-width: 1825px) {
  .contact-intro__add span {
    font-size: 12.5rem;
  }
}

.contact-intro__inner {
  position: relative;
  z-index: 3;
}

.contact-intro__up {
  background: #fff;
  font-size: calc(0.84rem + 2 * (100vw -  376px) / 1164);
  margin-bottom: clamp(3rem, 5vw, 5rem);
  position: relative;
  z-index: 2;
}

@media (min-width: 768px) {
  .contact-intro__up {
    text-align: center;
  }
}

@media (min-width: 1637px) {
  .contact-intro__up {
    font-size: 1.13rem;
  }
}

.contact-intro__up__text {
  padding-bottom: 1.5em;
  padding: 2em 5%;
  font-weight: 700;
  color: #fff;
  background: #1D2087;
}

.contact-intro__info {
  text-align: center;
  display: flex;
  padding: 1em 0;
}

@media (min-width: 1057px) {
  .contact-intro__info {
    justify-content: center;
    column-gap: 3rem;
    align-items: center;
  }
}

@media (max-width: 1056px) {
  .contact-intro__info {
    flex-direction: column;
  }
}

.contact-intro__info__text {
  font-weight: 700;
  font-size: calc(1rem + 4 * (100vw -  376px) / 1164);
}

@media (min-width: 1057px) {
  .contact-intro__info__text {
    font-size: 1.25rem;
  }
}

.contact-intro__info__tel {
  font-family: 'Montserrat', sans-serif;
  font-size: calc(1.75rem + 16 * (100vw -  376px) / 1164);
  font-weight: 700;
  color: #232323;
}

@media (min-width: 1057px) {
  .contact-intro__info__tel {
    font-size: 2.5rem;
  }
}

.contact-intro__info__tel i {
  font-size: 0.78em;
  margin-right: 0.2rem;
  color: #1D2087;
}

@media (min-width: 576px) {
  .contact-intro__info__tel i {
    font-size: 0.73em;
  }
}

@media (min-width: 1057px) {
  .contact-intro__info__tel i {
    font-size: 0.68em;
  }
}

.contact-intro__info__tel a {
  color: #232323;
}

.contact-box {
  border: 1px solid #1D2087;
  background: #fff;
  padding: clamp(1.5rem, 4vw, 4rem) 4%;
}

@media (min-width: 1057px) {
  .contact-box {
    padding: 3rem 4.5rem;
  }
}

.contact-form__tit {
  color: #1D2087;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: calc(1.5rem + 10 * (100vw -  376px) / 1164);
  text-align: center;
  margin-bottom: clamp(1rem, 2vw, 2rem);
}

@media (min-width: 1057px) {
  .contact-form__tit {
    font-size: 2.25rem;
  }
}

.contact-privacy {
  border: 1px solid #979DA4;
}

@media (min-width: 1057px) {
  .contact-privacy {
    padding: 3rem 6%;
    overflow-y: scroll;
    max-height: 300px;
  }
  .contact-privacy::-webkit-scrollbar {
    width: 8px;
  }
  .contact-privacy::-webkit-scrollbar-track {
    background: transparent;
  }
  .contact-privacy::-webkit-scrollbar-thumb {
    background: #C9CED2;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px #fff;
  }
}

@media (min-width: 1057px) {
  .contact-privacy__inner {
    margin-top: 1.5rem;
    padding: 0 4%;
  }
}

.contact-privacy__tit {
  font-size: calc(1.14rem + 2 * (100vw -  376px) / 1164);
  font-weight: 700;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

@media (min-width: 1057px) {
  .contact-privacy__tit {
    margin-bottom: 3rem;
  }
}

@media (min-width: 1637px) {
  .contact-privacy__tit {
    font-size: 1.25rem;
  }
}

@media (max-width: 1056px) {
  .contact-privacy__tit {
    padding: 1.5rem 6%;
    cursor: pointer;
    position: relative;
  }
  .contact-privacy__tit:after {
    content: "";
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .contact-privacy__tit::after {
    position: absolute;
    right: 1rem;
    color: #979DA4;
    top: calc(50% - 10px);
    transition: all 0.3s ease 0s;
    font-size: 1.5rem;
  }
}

.contact-privacy__tit.active::after {
  transform: rotate(180deg);
}

.contact-privacy__txt {
  padding: 0 2rem 2rem;
}

@media (min-width: 1057px) {
  .contact-privacy__txt {
    padding-bottom: 3rem;
  }
}

.contact-privacy .txt {
  font-size: 1.07em;
}

@media (min-width: 576px) {
  .contact-privacy .txt {
    font-size: 1em;
  }
}

@media (min-width: 1057px) {
  .contact-privacy .txt {
    font-size: 0.93em;
  }
}

.contact-table {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  line-height: 1.2;
  position: relative;
}

.contact-table th,
.contact-table td {
  text-align: left;
}

@media (min-width: 1057px) {
  .contact-table th,
  .contact-table td {
    display: table-cell;
    padding: 1rem 0 1.5rem;
  }
}

@media (max-width: 1056px) {
  .contact-table th,
  .contact-table td {
    display: block;
    padding: 0;
  }
}

.contact-table th {
  vertical-align: top;
  font-weight: 500;
}

@media (min-width: 1057px) {
  .contact-table th {
    width: 30%;
  }
}

.contact-table th span {
  margin-left: 0.5rem;
}

@media (min-width: 1057px) {
  .contact-table td {
    width: auto;
  }
}

@media (max-width: 1056px) {
  .contact-table td {
    padding: 1rem 0 2rem;
  }
}

.contact-table textarea {
  width: 100%;
}

input,
select {
  width: auto;
}

input.long,
select.long {
  width: 100%;
}

input.middle,
select.middle {
  width: 100%;
}

input.short,
select.short {
  width: 70%;
}

@media (min-width: 768px) {
  input.short,
  select.short {
    width: 30%;
  }
}

input.mini,
select.mini {
  width: 10em;
}

button {
  border: none;
  outline: none;
  font-size: 1.06rem;
  letter-spacing: 0.1em;
}

button[class*="sub-btn"] {
  font-weight: normal;
  display: block;
  text-align: center;
  text-decoration: none;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  color: #fff;
  background-color: #1D2087;
  border: 1px solid #1D2087;
  padding: 1.5rem 4%;
  width: 100%;
}

button[class*="sub-btn"]:hover {
  color: #fff;
  background-color: #120CBC;
  border-color: #120CBC;
}

button[class*="back-btn"] {
  margin-top: 1rem;
  font-weight: normal;
  display: block;
  text-align: center;
  text-decoration: none;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  color: #4B4D4E;
  background-color: #E5E9EC;
  border: 1px solid #E5E9EC;
  padding: 1.5rem 4%;
  width: 100%;
}

button[class*="back-btn"]:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

button[class*="chack-btn"] {
  font-weight: normal;
  display: block;
  text-align: center;
  text-decoration: none;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  color: #fff;
  background-color: #1D2087;
  border: 1px solid #1D2087;
  padding: 1.5rem 4%;
  width: 100%;
}

button[class*="chack-btn"]:hover {
  color: #fff;
  background-color: #120CBC;
  border-color: #120CBC;
}

[type="button"],
[type="submit"] {
  -webkit-appearance: none;
}

[type="button"],
[type="submit"] {
  -webkit-appearance: none;
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  display: block;
  margin-top: 1rem;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  border: none;
  outline: none;
}

/* form-parts
----------------------------------------------------------------*/
input, textarea, select {
  background-color: #fff;
  padding: 0.7em 1em;
  border: 1px solid #979DA4;
  line-height: 1.5;
}

input:focus, textarea:focus, select:focus {
  background-color: #fff;
  box-shadow: rgba(58, 134, 232, 0.25) 0 0 4px 1px;
}

input::-webkit-input-placeholder, input:-moz-placeholder, input:-ms-input-placeholder {
  color: #979DA4;
}

textarea {
  height: 10em;
}

/* radio-box,check-box
----------------------------------------------------------------*/
/* variablea */
/* radio-btn */
.mwform-radio-field {
  cursor: pointer;
}

.mwform-radio-field:not(:last-child) {
  margin-right: 1em;
}

.mwform-radio-field-text {
  vertical-align: top;
  position: relative;
  padding-left: 34px;
  display: block;
}

input[type="radio"] {
  position: absolute;
  opacity: 0;
}

input[type="radio"] + .mwform-radio-field-text:before {
  content: "";
  background: #F7F9FB;
  border-radius: 100%;
  border: 1px solid #979DA4;
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: calc(50% - (20px/ 2));
  left: 0;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 0.4s ease;
}

input[type="radio"]:checked + .mwform-radio-field-text:before {
  background-color: #1D2087;
  box-shadow: inset 0 0 0 5px #F3F5F7;
}

input[type="radio"]:focus + .mwform-radio-field-text:before {
  outline: none;
  border-color: #1D2087;
}

input[type="radio"]:disabled + .mwform-radio-field-text:before {
  box-shadow: inset 0 0 0 4px #979DA4;
  border-color: #979DA4;
  background: #F7F9FB;
}

input[type="radio"] + .mwform-radio-field-text:empty:before {
  margin-right: 0;
}

.vertical-item:not(:first-of-type) {
  margin-top: 1rem !important;
}

/* chack-btn */
@-moz-keyframes dothabottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: 13px;
  }
}

@-webkit-keyframes dothabottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: 13px;
  }
}

@keyframes dothabottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: 13px;
  }
}

@keyframes dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 31.2px;
  }
}

@-webkit-keyframes dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 31.2px;
  }
}

@-moz-keyframes dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 31.2px;
  }
}

input[type="checkbox"] {
  display: none;
}

.check-box .vertical-item:not(:first-of-type) {
  margin-top: 1.5rem !important;
}

.check-box label {
  position: relative;
  cursor: pointer;
}

.check-box label::before {
  content: "";
  height: 26px;
  width: 26px;
  background-color: transparent;
  border: 1px solid #979DA4;
  margin-right: 1em;
  position: absolute;
  top: -0.15em;
  left: 0;
  display: block;
  transition: border-color ease 0.2s;
  z-index: 1;
}

.mwform-checkbox-field-text {
  vertical-align: top;
  padding-left: calc(26px + 1rem);
  display: block;
}

.mwform-checkbox-field-text::before, .mwform-checkbox-field-text::after {
  position: absolute;
  height: 0;
  width: 5.2px;
  background-color: #1D2087;
  display: inline-block;
  transform-origin: left top;
  content: "";
  transition: opacity ease 0.5;
  z-index: 2;
}

.mwform-checkbox-field-text::before {
  top: 22.36px;
  left: 10.4px;
  transform: rotate(-135deg);
}

.mwform-checkbox-field-text::after {
  top: 13px;
  left: 0.78px;
  transform: rotate(-45deg);
}

input[type="checkbox"]:checked + .mwform-checkbox-field-text,
.mwform-checkbox-field-text.checked {
  border-color: #1D2087;
}

input[type="checkbox"]:checked + .mwform-checkbox-field-text::after,
.mwform-checkbox-field-text.checked::after {
  height: 13px;
  animation: dothabottomcheck 0.2s ease 0s forwards;
}

input[type="checkbox"]:checked + .mwform-checkbox-field-text::before,
.mwform-checkbox-field-text.checked::before {
  height: 31.2px;
  animation: dothatopcheck 0.4s ease 0s forwards;
  box-shadow: 0 0 0 1.3px #fff;
}

.mw_wp_form .error {
  margin-top: 0.7rem;
}
