/*--------------------------------------------------------------------/
	breadcrumb
/--------------------------------------------------------------------*/
.breadcrumb {
  position: absolute;
  display: block;
  top: 1rem;
  right: map-get($contmargin, sp);
  width: calc(100% - (#{map-get($contmargin, sp)}* 2));
  @include media-breakpoint-up(lg) {
    right: map-get($contmargin, pc);
    width: calc(100% - (#{map-get($contmargin, pc)}* 2));
  }
  &__list {
    @include flex-wrap;
    font-size: 0.84em;
    width: 100%;
    @include media-breakpoint-up(md) {
      justify-content: flex-end;
    }
    & .item-home {
      font-size: 1.2em;
    }
    li {
      position: relative;
      color: $other_c4;
      &:not(:last-child) {
        padding-right: 1.5em;
        @include icon(arrow-right, after);
        &::after {
          position: absolute;
          right: 0.3em;
          top: 50%;
          transform: translateY(-50%);
          font-size: 0.8em;
        }
      }
    }
    a {
      color: $other_c4;
      @include transition;
      vertical-align: top;
      @include dec-none;
      &:first-child {
        @include dec-none;
      }
      &:hover {
        color: $other_c1;
      }
    }
  }
}
