/*--------------------------------------------------------------------/
	training
/--------------------------------------------------------------------*/
// training-intro
.training-intro {
	background: $main_c;
	position: relative;
	color: $white;
	overflow: hidden;
	padding: clamp(3rem, 6vw, 6rem) 0 clamp(9rem, 14vw, 14rem);
	@include media-breakpoint-up(lg) {
		padding: clamp(3rem, 6vw, 6rem) 0 clamp(5rem, 9vw, 9rem);
	}
	&::before {
		@include media-breakpoint-up(lg) {
			content: "";
			position: absolute;
			background: $main_c;
			z-index: z-index(module, part03);
			top: 0;
			left: 0;
			width: 50%;
			height: 100%;
			transform: skew(-20deg);
		}
	}
	&__img {
		width: 100%;
		z-index: z-index(module, part04);
		position: absolute;
		@include media-breakpoint-up(lg) {
			top: 0;
			right: 0;
			width: 60%;
			height: 100%;
		}
		@include media-breakpoint-down(md) {
			left: 0;
			bottom: 0;
			width: 100%;
			height: 50%;
		}
		@include media-breakpoint-down(xs) {
			height: 50vw;
		}
		&__inner {
			position: relative;
			@include media-breakpoint-up(lg) {
				@include aspect-img(1, 1);
				img {
					width: 100%;
				}
			}
			@include media-breakpoint-down(md) {
				@include aspect-img(2, 1);
				img {
					@include ofi-cover;
				}
			}
			&::before {
				content: "";
				width: 100%;
				height: 100%;
				position: absolute;
				background: rgba($main_c, 0.5);
				z-index: z-index(module, part03);
			}
		}
	}
	.l-cont {
		position: relative;
		z-index: z-index(module, part02);
	}
	&__inner {
		@include f-s_xs(0.88, 3);
		@include f-w(700);
		background: $white;
		z-index: z-index(module, part03);
		padding: clamp(2rem, 3vw, 3rem) 5%;
		color: $black;
		@include media-breakpoint-up(md) {
			text-align: center;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(18);
		}
	}
	.txt {
		p {
			@include media-breakpoint-up(md) {
				@include line-h(2);
			}
		}
	}
	& .is-catch {
		display: inline-block;
		background: $red;
		color: $white;
		@include f-s_xs(0.78, 9);
		margin: 0 0.2em;
		padding: 0.2em 0.2em;
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
	}
	&__point {
		position: relative;
		&::before {
			content: "●";
			color: $main_c;
			font-size: 0.7em;
			margin-right: 0.2em;
		}
	}
}

// training-move
.training-move {
	padding-top: clamp(5rem, 10vw, 10rem);
	padding-bottom: clamp(4rem, 10vw, 10rem);
	position: relative;
	@include media-breakpoint-up(lg) {
		padding-top: 10rem;
		padding-bottom: clamp(6rem, 10vw, 10rem);
	}
	&::before {
		position: absolute;
		top: clamp(5rem, 10vw, 10rem);
		right: 0;
		width: 100%;
		content: "";
		background: $main_c;
		height: 40%;
		max-height: 480px;
		display: block;
		@include media-breakpoint-up(lg) {
			padding-top:10rem;
		}
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part02);
	}
	&__tit {
		color: $black;
		@include f-w(900);
		@include f-s_xs(1, 26);
		@include line-h(1.4);
		text-align: center;
		position: relative;
		top: -1em;
		@include media-breakpoint-up(xl) {
			top: -1.25em;
			@include f-size(42);
		}
		&__inner {
			display: inline-block;
			background: $white;
			padding: 0.75em 1.25em;
			position: relative;
			border: 1px solid $main_c;
		}
	}
	&__list {
		display: flex;
		&.is-column01 {
			flex-direction: column;
			@include media-breakpoint-up(sm) {
				row-gap: clamp(3rem, 5vw, 5rem);
				max-width: 800px;
				@include m-a;
			}
			@include media-breakpoint-up(xs) {
				row-gap: clamp(1.5rem, 2vw, 2rem);
			}
		}
		&.is-column02 {
			@include media-breakpoint-up(sm) {
				flex-wrap: wrap;
				column-gap: 4%;
				max-width: 1200px;
				@include m-a;
				& > * {
					width: 48%;
				}
			}
			@include media-breakpoint-down(xs) {
				flex-direction: column;
				row-gap: clamp(1.5rem, 2vw, 2rem);
			}
		}
	}
	.btn-wrap {
		margin-top: clamp(3rem,4vw,4rem);
	}
}

.training-move__box {
	width: 100%;
	border: 1px solid $l-gray;
	overflow: hidden;
	&__inner {
		@include flex-column;
		width: 100%;
		height: 100%;
	}
	&__video {
		width: 100%;
		&__inner {
			position: relative;
			width: 100%;
			height: 0;
			padding-bottom: 56.25%;
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	&__txt {
		padding: 1em 5% 1.75em;
		background-color: $white;
		width: 100%;
		flex-grow: 1;
	}
}

// training-feature
.training-feature {
	position: relative;
	margin-top: clamp(5rem, 9vw, 9rem);
	padding-bottom: clamp(2rem, 4vw, 4rem);
	margin-bottom: clamp(2rem, 5vw, 5rem);
	&::before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background: $other_c1;
		@include media-breakpoint-up(lg) {
			width: 98%;
		}
		@include media-breakpoint-up(xl) {
			width: 95%;
		}
	}
	&__add {
		position: absolute;
		bottom: calc(100% - 0.5em);
		left: calc(50% - 2rem);
		z-index: z-index(module, part04);
		@include f-w(700);
		@include l-sp(0.05em);
		@include f-s_xs(2, 80);
		@include line-h(0.85);
		display: none;
		width: 100%;
		@include media-breakpoint-up(md) {
			left: 60%;
			@include f-s_md(6, 100);
			display: block;
		}
		@include media-breakpoint-up(xl) {
			left: calc(50% - 2rem);
			@include f-s_xl(20, 100);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(200);
		}
		span {
			@include f-family(font02);
			color: $other_c10;
		}
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part04);
	}
	&__tit {
		display: inline-block;
		background: $white;
		position: relative;
		@include m-a;
		padding: 2em 4% 2em;
		top: -3rem;
		@include line-h(1.4);
		&::before {
			content: "";
			background: $white;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			right: 100%;
		}
		span {
			display: block;
			@include f-w(700);
			@include f-s_xs(0.75, 6);
			padding: 0.3em 0.5em 0.3em 0;
			background: $main_c;
			color: $white;
			position: relative;
			@include media-breakpoint-up(xl) {
				@include f-size(20);
			}
			&::after {
				content: "";
				position: absolute;
				top: 0;
				right: 100%;
				background: $main_c;
				width: 100vw;
				height: 100%;
			}
		}
		strong {
			display: block;
			@include line-h(1);
			@include f-w(700);
			@include f-s_xs(1.75, 8);
			@include l-sp(0.15em);
			@include media-breakpoint-up(xl) {
				@include f-size(32);
			}
			b {
				font-size: 2.5em;
				color: $red;
			}
		}
	}
	&__list {
		@include flex-column;
		row-gap: clamp(3rem, 6vw, 6rem);
	}
}

.training-feature__box {
	padding-bottom: clamp(2rem, 3vw, 3rem);
	position: relative;
	&:nth-of-type(odd) {
		&::before {
			right: -8%;
		}
		.training-feature__box__img {
			right: 0;
		}
	}
	&:nth-of-type(even) {
		.training-feature__box__inner {
			@include media-breakpoint-up(md) {
				@include flex-end;
			}
		}
		.training-feature__box__txt {
			@include media-breakpoint-up(md) {
				@include flex-end;
			}
		}
		&::before {
			left: -7%;
		}
		.training-feature__box__img {
			left: 0;
		}
		.training-feature__box__txt__inner {
			@include media-breakpoint-up(md) {
				padding: 3.5rem 8% 2.5rem 16%;
			}
		}
	}
	&::before {
		position: absolute;
		bottom: 0;
		content: "";
		background: $main_c;
		width: 80%;
		height: 80%;
	}
	&__inner {
		position: relative;
		@include media-breakpoint-up(lg) {
			padding-top: 4rem;
		}
	}
	&__img {
		@include media-breakpoint-up(md) {
			position: absolute;
			top: 3.5rem;
			right: 0;
			width: 38.96%;
		}
		@include media-breakpoint-up(lg) {
			top: 0;
		}
		&__inner {
			@include aspect-img(3, 2);
		}
	}
	&__txt {
		background: $white;
		@include media-breakpoint-up(md) {
			width: 83.76%;
		}
		&__inner {
			padding: 2rem 8% 2.5rem;
			overflow: hidden;
			@include media-breakpoint-up(md) {
				padding: 3.5rem 16% 2.5rem 8%;
				min-height: 420px;
				width: 77.51%;
			}
		}
	}
	&__tit {
		margin-bottom: calc(1rem + 1em);
		border-bottom: 2px solid $main_c;
		padding-bottom: clamp(1rem, 2vw, 2rem);
		@include media-breakpoint-up(lg) {
			margin-bottom: calc(1rem + 2em);
		}
		b {
			display: inline-block;
			position: relative;
			padding-right: 1em;
			@include f-w(700);
			@include f-s_xs(1, 16);
			@include media-breakpoint-up(md) {
				@include f-s_md(1.25, 12);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(32);
			}
		}
		span {
			color: $red;
		}
	}
}

.training-comment {
	position: relative;
	padding-bottom: clamp(3rem, 6vw, 6rem);
	&::before {
		z-index: z-index(module, part04);
		content: "";
		width: 100%;
		height: 60%;
		position: absolute;
		left: 0;
		bottom: 0;
		background: $main_c;
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part03);
		background: $white;
		border: 1px solid $main_c;
		padding: 2em 5%;
		border: 1px solid $main_c;
		@include f-w(700);
		@include f-s_xs(0.85, 4);
		background: $white;
		@include media-breakpoint-up(md) {
			text-align: center;
			padding: 2em 8%;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(20);
		}
	}
}
