/*--------------------------------------------------------------------/
	company
/-------------------------------------------------------------------*/
.company-message {
	padding-top: clamp(3rem, 5vw, 5.5rem);
	padding-bottom: clamp(8rem, 12vw, 16rem);
	position: relative;
	@include media-breakpoint-up(lg) {
		padding-top: 5.5rem;
		padding-bottom: clamp(6rem, 16vw, 16rem);
	}
	&::before {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		content: "";
		background: $main_c;
		height: calc(100% - 6vw);
		display: block;
		@include media-breakpoint-up(xl) {
			height: calc(100% - 5rem);
			width: calc(100% - 2%);
		}
		@include media-breakpoint-up(xxl) {
			width: calc(50% + 864px);
		}
	}
	&::after {
		position: absolute;
		z-index: z-index(module, part04);
		bottom: 0;
		left: 0;
		width: 50%;
		height: calc(100% - 10vw);
		display: block;
		content: "";
		background: $sub_c;
		display: block;
		@include media-breakpoint-up(md) {
			height: calc(100% - 4.5rem);
		}
		@include media-breakpoint-up(lg) {
			width: 50%;
			height: calc(100% - 8rem);
		}
	}
	&__add {
		position: absolute;
		bottom: 6vw;
		right: 5%;
		z-index: z-index(module, part03);
		@include media-breakpoint-up(xl) {
			bottom: 4.5rem;
		}
		span {
			@include f-family(font02);
			color: $other_c8;
			@include f-w(700);
			@include l-sp(0.05em);
			@include f-s_xs(3, 140);
			@include line-h(0.85);
			@include media-breakpoint-up(xl) {
				@include f-s_xl(10, 40);
			}
			@include media-breakpoint-up(xxl) {
				@include f-size(200);
			}
		}
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part02);
	}
	&__tit {
		text-align: left;
		color: $white;
		margin-bottom: clamp(1.5rem, 3vw, 3rem);
		@include media-breakpoint-up(lg) {
			margin-bottom: 4rem;
		}
		em {
			@include f-family(font02);
			@include l-sp(0.1em);
			font-style: normal;
			display: block;
			@include f-w(700);
			position: relative;
			line-height: 1.2;
			@include f-s_xs(1.5, 38);
			@include media-breakpoint-up(xl) {
				@include f-size(60);
			}
		}
		span {
			@include f-family(font01);
			@include l-sp(0.1em);
			display: block;
			@include f-w(700);
			position: relative;
			line-height: 1.4;
			@include f-s_xs(1.14, 10);
			@include media-breakpoint-up(xl) {
				@include f-size(28);
			}
		}
	}
	&__pic {
		position: relative;
		z-index: z-index(module, part02);
		@include media-breakpoint-up(lg) {
			position: absolute;
			right: 0;
			top: 5rem;
			width: 38.96%;
		}
		@include media-breakpoint-down(md) {
			max-width: 90%;
			@include m-a;
		}
		img {
			@include m-a;
			display: block;
		}
	}
	&__txt {
		background: $other_c1;
		padding: 5rem 5% 2rem;
		position: relative;
		@include media-breakpoint-up(lg) {
			width: 73.63%;
			padding: clamp(3rem, 5vw, 5rem) 20% clamp(3rem, 5vw, 4rem) 7%;
		}
		@include media-breakpoint-down(md) {
			margin-top: -3rem;
		}
		.txt {
			& p:not(:last-of-type) {
				margin-bottom: 1.5rem;
				@include media-breakpoint-up(lg) {
					margin-bottom: 2rem;
				}
			}
		}
	}
}

//company-outline
.company-outline {
	padding: clamp(4rem, 8vw, 9rem) 0 clamp(4rem, 12vw, 12rem);
	&__table {
		table {
			width: 100%;
			margin-bottom: 2rem;
			& tr {
				border-bottom: 1px solid $m-gray;
				&:first-child {
					border-top: 1px solid $m-gray;
				}
			}
			& th,
			& td.column-1 {
				text-align: left;
				@include f-w(500);
				color: $black;
				padding: 1rem 0;
				@include media-breakpoint-up(lg) {
					width: 25%;
					min-width: 180px;
				}
				@include media-breakpoint-up(xl) {
					width: 225px;
				}
				@include media-breakpoint-down(md) {
					display: block;
					padding-bottom: 0;
				}
			}
			& td.column-2 {
				padding: 1rem 1.25rem 1rem 0.5rem;
				@include media-breakpoint-down(md) {
					display: block;
					padding-top: 0;
					padding-left: 0;
				}
			}
		}
	}
}

//company-access
.company-access {
	position: relative;
	padding-top: clamp(3rem, 6vw, 6rem);
	@include media-breakpoint-up(lg) {
		padding-top: 6rem;
	}
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		background: $sub_c;
		width: 100%;
		height: 40vw;
		min-height: 300px;
		max-height: 740px;
		z-index: z-index(module, part04);
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part03);
	}
	&__tit {
		color: $white;
		@include f-family(font02);
		@include l-sp(0.1em);
		font-style: normal;
		display: block;
		@include f-w(700);
		position: relative;
		line-height: 1.2;
		@include f-s_xs(1.5, 38);
		margin-bottom: calc(1.5rem + 0.5em);
		@include media-breakpoint-up(xl) {
			@include f-size(60);
		}
	}
	&__map {
		position: relative;
		padding: 0;
		height: 0;
		width: 100%;
		overflow: hidden;
		background-color: $white;
		@include media-breakpoint-up(lg) {
			padding-bottom: 40%;
		}
		@include media-breakpoint-down(md) {
			padding-bottom: 300px;
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100% !important;
			height: 100% !important;
		}
	}
	&__txt {
		margin-top: 1rem;
		.txt {
		}
		ul {
			text-align: left;
			& > li {
				line-height: 1.4;
				padding-left: 1.5rem;
				position: relative;
				&::before {
					content: "●";
					color: $main_c;
					position: absolute;
					left: 0;
					top: 0.2em;
					font-size: 0.8em;
				}
				&:not(:last-child) {
					margin-bottom: 0.5rem;
				}
			}
		}
	}
}
