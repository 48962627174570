/*--------------------------------------------------------------------/
	transmitter
/--------------------------------------------------------------------*/
.transmitter-intro {
	position: relative;
	padding-top: clamp(4rem, 6vw, 6rem);
	padding-bottom: clamp(5rem, 20vw, 20rem);
	@include media-breakpoint-up(lg) {
		padding-top: clamp(2rem, 3vw, 3rem);
		padding-bottom: clamp(10rem, 20vw, 20rem);
	}
	&::before {
		content: "";
		background: $sub_c;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: z-index(module, part04);
		width: 100%;
		height: calc(100% - 2rem);
		@include media-breakpoint-up(lg) {
			height: calc(100% - 10vw);
		}
		@include media-breakpoint-up(xl) {
			height: calc(100% - 5rem);
			width: calc(100% - 2%);
		}
		@include media-breakpoint-up(xxl) {
			width: calc(50% + 864px);
		}
	}
	&::after {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		height: calc(100% - 3rem);
		background: $main_c;
		z-index: z-index(module, part03);
		display: block;
		width: 80%;
		@include media-breakpoint-up(lg) {
			height: calc(100% - 10vw);
			width: 100%;
		}
		@include media-breakpoint-up(xl) {
			height: calc(100% - 8rem);
			width: calc(100% - 2%);
		}
		@include media-breakpoint-up(xxl) {
			width: calc(50% + 864px);
		}
	}
	&__add {
		position: absolute;
		bottom: 0;
		right: 5%;
		z-index: z-index(module, part02);
		span {
			@include f-family(font02);
			color: $other_c8;
			@include f-w(700);
			@include l-sp(0.05em);
			@include f-s_xs(2, 130);
			@include line-h(0.85);
			@include media-breakpoint-up(xl) {
				@include f-s_xl(6, 64);
			}
			@include media-breakpoint-up(xxl) {
				@include f-size(160);
			}
		}
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part01);
	}
	&__tit {
		color: $white;
		@include f-s_xs(1, 32);
		@include f-w(700);
		margin-bottom: clamp(1.5rem, 3vw, 3rem);
		@include media-breakpoint-up(xl) {
			@include f-size(48);
		}
	}
}

.transmitter-intro {
	&__cont {
		width: 100%;
		position: relative;
		&__img {
			@include media-breakpoint-up(lg) {
				position: absolute;
				top: 0;
				left: 54%;
				width: 47vw;
			}
			&__inner {
				width: 100%;
				@include aspect-img(16, 9);
				@include media-breakpoint-up(lg) {
					@include aspect-img(5, 4);
				}
			}
		}
		&__txt {
			background: $white;
			position: relative;
			@include media-breakpoint-up(lg) {
				z-index: z-index(module, part03);
				top: 2.5rem;
				width: 60%;
				min-height: 600px;
			}
			@include media-breakpoint-down(md) {
				position: relative;
			}
			&::before {
				@include media-breakpoint-up(lg) {
					content: "";
					background: $white;
					position: absolute;
					right: 100%;
					top: 0;
					width: 100vw;
					height: 100%;
				}
			}
			&__inner {
				padding: 1.5em 5% 2em;
				@include media-breakpoint-up(lg) {
					padding: 0 8% 4em 0;
				}
			}
		}
		&__catch {
			@include f-s_xs(0.94, 3);
			@include f-w(700);
			margin-bottom: clamp(1.5rem, 2vw, 2rem);
			position: relative;
			@include media-breakpoint-up(lg) {
				top: -2.5rem;
				@include f-s_lg(0.94, 4);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(20);
			}
			&__up {
				padding: 0.75em 1.25em;
				background: $white;
				border: 1px solid $main_c;
				margin-bottom: 0.5em;
				@include media-breakpoint-up(lg) {
					display: inline-block;
				}
			}
			&__bottom {
				padding: 0.75em 1.25em;
				background: $red;
				color: $white;
				@include media-breakpoint-up(lg) {
					display: inline-block;
				}
			}
		}
		&__point {
			display: flex;
			margin-bottom: 2rem;
			@include media-breakpoint-up(md) {
				flex-wrap: wrap;
				column-gap: 2%;
				row-gap: 1vw;
			}
			@include media-breakpoint-up(xl) {
				row-gap: 1rem;
			}
			@include media-breakpoint-down(sm) {
				flex-direction: column;
				row-gap: 0.75rem;
			}
			li {
				padding: 1em 2%;
				text-align: center;
				background: $white;
				color: $red;
				border: 1px solid $red;
				@include f-w(700);
				@include media-breakpoint-up(md) {
					width: 49%;
				}
				@include media-breakpoint-up(lg) {
					@include f-s_lg(1, 2);
				}
				@include media-breakpoint-up(xl) {
					@include f-size(20);
				}
			}
		}
		&__gallery {
			@include flex-wrap;
			column-gap: 2%;
			row-gap: 1.5vw;
			padding-bottom: clamp(2rem, 3vw, 3rem);
			@include media-breakpoint-up(xl) {
				row-gap: 1.25rem;
			}
			& > * {
				width: 32%;
			}
		}
		&__case {
			border-top: 1px solid $l-gray;
			padding-top: clamp(1.5rem, 2.5vw, 2.5rem);
			&__inner {
				@include media-breakpoint-up(sm) {
					display: flex;
				}
			}
			&__tit {
				@include f-w(500);
				@include f-size(16);
				margin-bottom: 1rem;
				@include media-breakpoint-up(sm) {
					width: 23.37%;
					padding-left: 0.2em;
					@include f-s_sm(1, 2);
					min-width: 110px;
				}
				@include media-breakpoint-up(xl) {
					@include f-size(18);
				}
			}
			.common-ul {
				@include media-breakpoint-up(sm) {
					width: auto;
				}
			}
		}
	}
}

//transmitter-flow
.transmitter-flow {
	margin-top: clamp(6rem, 9vw, 9rem);
	padding-bottom: clamp(4rem, 9vw, 9rem);
	background: $p-gray;
	&__inner {
	}
	&__tit {
		text-align: center;
		position: relative;
		top: -1.5em;
		margin-bottom: calc(2rem + 1em);
		@include media-breakpoint-up(sm) {
			top: -2rem;
		}
		em {
			@include f-family(font02);
			@include l-sp(0.1em);
			font-style: normal;
			display: block;
			color: $main_c;
			@include f-w(700);
			position: relative;
			line-height: 1.2;
			@include f-s_xs(2.25, 28);
			@include media-breakpoint-up(xl) {
				@include f-size(60);
			}
		}
		span {
			@include f-family(font01);
			@include l-sp(0.1em);
			display: block;
			@include f-w(700);
			position: relative;
			line-height: 1.4;
			@include f-s_xs(1.14, 10);
			@include media-breakpoint-up(xl) {
				@include f-size(28);
			}
		}
	}
	&__list {
		counter-reset: flow-num;
		@include media-breakpoint-up(xl) {
			@include flex-wrap;
			@include m-a;
			column-gap: 1.8625%;
			row-gap: 4rem;
			& > * {
				width: 18.51%;
			}
		}
		@include media-breakpoint-down(lg) {
			@include flex-column;
			justify-content: center;
			align-items: center;
			row-gap: clamp(3rem, 6vw, 6rem);
			& > * {
				width: 100%;
				max-width: 960px;
			}
		}
	}
}

.transmitter-flow__box {
	position: relative;
	&:not(:last-of-type) {
		&::before {
			position: absolute;
			content: "";
			@include media-breakpoint-up(xl) {
				top: 50%;
				left: 100%;
				width: 80%;
				height: 1px;
				background-size: 8px 1px;
				background-image: linear-gradient(to right, $main_c, $main_c 3px, transparent 3px, transparent 8px);
			}
			@include media-breakpoint-down(lg) {
				width: 1px;
				height: 80%;
				top: 100%;
				left: calc(50% + 15px);
				background-size: 1px 8px;
				background-image: linear-gradient(to bottom, $main_c, $main_c 3px, transparent 3px, transparent 8px);
			}
			@include media-breakpoint-down(xs) {
				left: calc(50% + 10px);
			}
		}
	}
	&:nth-of-type(5n) {
		&::before {
			@include media-breakpoint-up(xl) {
				display: none;
			}
		}
	}
	&__inner {
		width: calc(100% - 20px);
		margin-left: 20px;
		height: 100%;
		border: 1px solid $main_c;
		background: $white;
		position: relative;
		z-index: z-index(module, part03);
		padding: 1.5em 8% 2em;
		display: flex;
		flex-direction: column;
		@include media-breakpoint-between(sm, lg) {
			flex-direction: row;
			column-gap: 4%;
			padding: 1.5em 8% 1.5em 80px;
		}
		@include media-breakpoint-up(sm) {
			width: calc(100% - 30px);
			margin-left: 30px;
		}
		@include media-breakpoint-up(xl) {
			padding: 1.5em 8% 2em;
		}
		@include media-breakpoint-up(xxl) {
			padding: 1.5em 10% 2em;
		}
	}
	&__num {
		position: absolute;
		top: -20px;
		left: -20px;
		width: 80px;
		height: 80px;
		background: $main_c;
		z-index: z-index(module, part02);
		@include media-breakpoint-up(sm) {
			top: -30px;
			left: -30px;
		}
		span {
			position: relative;
			width: 100%;
			height: 100%;
			color: $white;
			display: block;
			&::before {
				counter-increment: flow-num;
				content: counter(flow-num, decimal-leading-zero);
				@include f-family(font02);
				@include f-w(700);
				@include line-h(1);
				@include l-sp(0.05em);
				position: absolute;
				top: calc(50% - 0.5em);
				left: calc(50% - 0.65em);
				@include f-size(36);
			}
		}
	}
	&__img {
		width: 120px;
		height: 120px;
		@include m-a;
		margin-bottom: 1rem;
		flex-shrink: 0;
		&__inner {
			@include aspect-img(1, 1);
		}
	}
	&__tit {
		@include f-w(700);
		@include f-s_xs(1, 4);
		margin-bottom: 0.4em;
		@include media-breakpoint-up(xl) {
			@include f-size(20);
		}
	}
}

// transmitter-contact
.transmitter-contact {
	position: relative;
	margin-top: clamp(6rem, 9vw, 9rem);
	padding-bottom: clamp(5rem, 9vw, 9rem);
	border-bottom: $main_c solid;
	border-width: 40px;
	@include media-breakpoint-up(md) {
		border-width: 72px;
	}
	&::before {
		position: absolute;
		z-index: z-index(module, part04);
		left: 0;
		bottom: 0;
		content: "";
		width: 100%;
		height: 80%;
		background: $other_c4;
	}
	&__inner {
		position: relative;
		background: $white;
		z-index: z-index(module, part03);
		border: 1px solid $main_c;
	}
	&__tit {
		background: $main_c;
		color: $white;
		@include f-w(700);
		@include f-s_xs(1, 10);
		padding: 0.5em 5%;
		@include media-breakpoint-up(sm) {
			text-align: center;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(32);
		}
		b {
			padding: 0.1em 0.2em;
			margin-left: 0.2em;
			margin-right: 0.2em;
			color: $white;
			font-size: 1.75em;
			display: inline-block;
			background: $red;
		}
	}
	&__txt {
		width: 100%;
		padding: 2em 5% 2.5em;
		@include media-breakpoint-up(lg) {
			padding: 2em 10% 4em;
		}
		@include media-breakpoint-up(xl) {
			padding: 2em 15% 4em;
		}
	}
	&__outline {
		&__tit {
			@include f-w(700);
			text-align: center;
			margin-bottom: 1.5rem;
			@include f-s_xs(1, 4);
			@include media-breakpoint-up(xl) {
				@include f-size(20);
			}
		}
	}
	&__privacy {
		margin-top: 2rem;
		background: $other_c1;
		padding: 1.25em 5% 1.5em;
		dt {
			color: $red;
			@include f-w(700);
			@include f-s_xs(0.94, 4);
			margin-bottom: 0.5rem;
			@include media-breakpoint-up(md) {
				text-align: center;
			}
			@include media-breakpoint-up(lg) {
				@include f-size(20);
				text-align: center;
			}
		}
		dd {
			@include media-breakpoint-up(md) {
				text-align: center;
			}
		}
	}
}
