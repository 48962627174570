/*--------------------------------------------------------------------/
	fixtures
/--------------------------------------------------------------------*/
// fixtures-intro
.fixtures-intro {
	padding: clamp(3rem, 6vw, 6rem) 0;
	position: relative;
	background: $main_c;
	margin-bottom: clamp(3rem, 6vw, 6rem);
	&::before {
		content: "";
		background: $other_c7;
		width: 80%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 50%;
		transform: skew(-20deg);
	}
	&__inner {
		@include f-s_xs(0.85, 2);
		@include f-w(500);
		background: $white;
		position: relative;
		z-index: z-index(module, part03);
		padding: clamp(2rem, 4vw, 4rem) 5%;
		@include media-breakpoint-up(lg) {
			@include f-size(18);
			text-align: center;
		}
		.txt {
			@include f-s_xs(0.8, 10);
			@include f-w(700);
			@include media-breakpoint-up(xl) {
				@include f-size(28);
			}
			span {
				display: inline-block;
				background: $red;
				color: $white;
				padding: 0 0.2em;
				font-size: 1.14em;
				margin-right: 0.2em;
			}
		}
	}
}

// fixtures-parts
.fixtures-parts {
	position: relative;
	padding-top: clamp(3rem, 8vw, 8rem);
	padding-bottom: clamp(4rem, 10vw, 10rem);
	&::before {
		content: "";
		background: $other_c3;
		top: 0;
		left: 0;
		position: absolute;
		width: 100%;
		height: 90%;
		@include media-breakpoint-up(xl) {
			width: 95%;
		}
	}
	&::after {
		content: "";
		background: $main_c;
		bottom: 0;
		right: 0;
		position: absolute;
		width: 98%;
		height: 80%;
		@include media-breakpoint-up(xl) {
			width: 95%;
		}
	}
	&__add {
		position: absolute;
		bottom: 0;
		right: 5%;
		z-index: z-index(module, part04);
		span {
			@include f-family(font02);
			color: $other_c8;
			@include f-w(700);
			@include l-sp(0.05em);
			@include f-s_xs(2, 80);
			@include line-h(0.85);
			@include media-breakpoint-up(xl) {
				@include f-s_xl(8, 40);
			}
			@include media-breakpoint-up(xxl) {
				@include f-size(160);
			}
		}
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part03);
	}
	&__tit {
		text-align: center;
		background: $red;
		@include f-s_xs(0.8, 16);
		position: relative;
		color: $white;
		@include f-w(700);
		padding: 1em 4%;
		@include media-breakpoint-up(lg) {
			padding: 1em 8%;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(36);
		}
	}
	&__txt {
		@include m-a;
		background: $white;
		&__inner {
			padding: clamp(2em, 4vw, 4rem) 5% 4em;
			@include media-breakpoint-up(lg) {
				padding: clamp(2em, 4vw, 4rem) 10% 4em;
			}
		}
		&__banner {
			margin-bottom: 1rem;
		}
	}
}

// fixtures-product
.fixtures-product {
	padding-top: clamp(3rem, 6vw, 6rem);
	&__inner {
	}
	&__tit {
		position: relative;
		max-width: 1040px;
		@include m-a;
		margin-bottom: clamp(3rem, 6vw, 6rem);
		display: flex;
		align-items: center;
		justify-content: center;
		background: $white;
		border: 1px solid $main_c;
		position: relative;
		z-index: z-index(module, part03);
		padding: 1.25em 4%;
		@include f-w(900);
		@include f-s_xs(1.25, 20);
		@include media-breakpoint-up(xl) {
			@include f-size(40);
		}
		&::before {
			content: "";
			background: $main_c;
			position: absolute;
			top: 0;
			left: 100%;
			height: 80%;
			width: 100vw;
		}
		&::after {
			content: "";
			background: $main_c;
			position: absolute;
			top: 0;
			right: 100%;
			height: 80%;
			width: 100vw;
		}
		&__inner {
			text-align: center;
		}
	}
	&__list {
		@include flex-column;
		row-gap: clamp(2rem, 4vw, 4rem);
	}
}

.fixtures-product__box {
	border: 1px solid $main_c;
	background: $white;
	&__inner {
		@include media-breakpoint-up(md) {
			display: grid;
			grid-template-columns: 65% 35%;
			grid-template-rows: auto 1fr;
		}
	}
	&__tit {
		background: $main_c;
		color: $white;
		overflow: hidden;
		position: relative;
		@include f-w(700);
		padding: 1.25em 5%;
		@include line-h(1.2);
		@include f-s_xs(1, 16);
		@include media-breakpoint-up(md) {
			grid-column: 1 / 2;
			grid-row: 1 / 2;
			@include f-s_md(1, 16);
			padding: 1.25em 8%;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(32);
		}
		@include media-breakpoint-down(sm) {
			margin-bottom: 1rem;
			text-align: center;
		}
		&::before {
			content: "";
			background: $other_c7;
			width: 80%;
			height: 100%;
			position: absolute;
			top: 0;
			right: 50%;
			transform: skew(-20deg);
		}
		span {
			position: relative;
			z-index: z-index(module,part04);
		}
	}
	&__img {
		background: $white;
		@include media-breakpoint-up(md) {
			grid-column: 2 / 3;
			grid-row: 1 / 3;
			padding: 8%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		@include media-breakpoint-down(sm) {
			@include m-a;
			margin-bottom: 1rem;
			padding: 5%;
		}
		&__inner {
			max-width: 300px;
			@include m-a;
		}
	}
	&__txt {
		padding: 0 5% 2rem;
		@include media-breakpoint-up(md) {
			padding: 2em 8% 2rem;
			grid-column: 1 / 2;
			grid-row: 2 / 3;
			border-right: 1px solid $main_c;
		}
	}
}
