/*--------------------------------------------------------------------/
	ecosuru
/--------------------------------------------------------------------*/
// ecosuru-intro
.ecosuru-intro {
	position: relative;
	background: $o-white;
	padding-top: clamp(3rem, 6vw, 6rem);
	padding-bottom: clamp(6rem, 9vw, 9rem);
	margin-bottom: clamp(6rem, 14vw, 14rem);
	&::before {
		content: "";
		background: $main_c;
		width: 100%;
		height: 50%;
		max-height: 680px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: z-index(module, part04);
	}
	&__add {
		width: 100vw;
		position: absolute;
		top: calc(100% - 5.64vw);
		left: 5%;
		z-index: z-index(module, part03);
		@include media-breakpoint-up(xl) {
			top: calc(100% - 80px);
		}
		span {
			@include f-family(font02);
			color: $other_c1;
			@include f-w(700);
			@include l-sp(0.05em);
			@include f-s_xs(3.5, 130);
			@include line-h(0.85);
			@include media-breakpoint-up(xl) {
				bottom: 4.5rem;
				@include f-s_xl(10, 40);
			}
			@include media-breakpoint-up(xxl) {
				@include f-size(200);
			}
		}
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part03);
	}
	&__tit {
		width: 100%;
		padding: 1em 5%;
		background: $white;
		margin-bottom: clamp(1.5rem, 2vw, 2rem);
		position: relative;
		@include line-h(1.2);
		@include media-breakpoint-up(lg) {
			padding: 1em 2rem;
		}
		strong {
			color: $red;
			@include f-w(900);
			display: inline-block;
			margin-right: 0.5em;
			@include f-s_xs(2, 32);
			@include media-breakpoint-up(xl) {
				@include f-size(64);
			}
		}
		span {
			color: $black;
			display: inline-block;
			@include f-w(700);
			@include f-s_xs(1, 8);
			@include media-breakpoint-up(xl) {
				@include f-size(24);
			}
		}
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 100%;
			width: 100%;
			height: 100%;
			background: $white;
		}
	}
}

.ecosuru-intro__box {
	background: $white;
	border: 1px solid $main_c;
	position: relative;
	overflow: hidden;
	&__inner {
		@include media-breakpoint-up(lg) {
			display: grid;
			grid-template-columns: 35% 1fr;
			grid-template-rows: auto 1fr;
		}
	}
	&__img {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 1em 5% 0;
		@include media-breakpoint-up(lg) {
			padding: 1.5em 8% 2em;
			grid-column: 1 / 2;
			grid-row: 1 / 3;
		}

		&__inner {
			width: clamp(160px, 50%, 320px);
			@include media-breakpoint-up(lg) {
				width: 60%;
			}
		}
	}
	&__txt {
		padding: clamp(1.5rem, 3vw, 3rem) 5% clamp(1.5rem, 3vw, 3rem);
		@include f-w(700);
		@include media-breakpoint-up(lg) {
			padding: clamp(1.5rem, 3vw, 3rem) 5% clamp(1.5rem, 3vw, 3rem) 0;
			grid-column: 2 / 3;
			grid-row: 1 / 2;
		}
		span {
			color: $red;
		}
	}
}

.ecosuru-intro__box__point {
	padding: 0 5% clamp(2rem, 3vw, 3rem);
	@include media-breakpoint-up(lg) {
		padding: 0 5% clamp(2rem, 5vw, 5rem) 0;
		grid-column: 2 / 3;
		grid-row: 2 / 3;
	}
	&__tit {
		@include f-s_xs(1, 8);
		margin-bottom: calc(1rem + 1em);
		@include media-breakpoint-up(lg) {
			@include f-s_xs(1.14, 6);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
		span {
			position: relative;
			padding: 0.5em 1em;
			color: $white;
			background: $red;
			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 100%;
				width: 100vw;
				height: 100%;
				background: $red;
			}
		}
	}
	&__flex {
		display: flex;
		@include media-breakpoint-up(md) {
			justify-content: flex-start;
			column-gap: 3.67%;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}
	}
	&__img {
		@include media-breakpoint-up(md) {
			width: 27.83%;
		}
		@include media-breakpoint-down(sm) {
			width: 80%;
			@include m-a;
			max-width: 280px;
			margin-bottom: 1rem;
		}
	}
	&__list {
		width: 100%;
		@include media-breakpoint-up(md) {
			width: 68.5%;
		}
		&__list {
			@include flex-column;
			row-gap: 0.5em;
			li {
				width: 100%;
				padding: 0.5em 1em 0.5em 3em;
				border: 1px solid $m-gray;
				position: relative;
				&::before {
					position: absolute;
					top: 0.5em;
					left: 1em;
					content: "";
					width: 1.5em;
					height: 1.5em;
					background: url(../img/common/icon-check.svg) center center/cover no-repeat;
				}
			}
		}
	}
}

// ecosuru-banner
.ecosuru-banner {
	background: $o-white;
	padding: clamp(3rem, 6vw, 6rem) 0 0;
	&__tit {
		padding: 1em 5%;
		color: $white;
		background: $red;
		text-align: center;
		@include f-s_xs(1, 8);
		@include f-w(700);
		margin-bottom: clamp(1.5rem, 3vw, 3rem);
		@include media-breakpoint-up(xl) {
			@include f-size(32);
		}
	}
	&__item {
		max-width: 1040px;
		@include m-a;
	}
}

// ecosuru-list
.ecosuru-list {
	margin-bottom: clamp(6rem, 9vw, 9rem);
	&__inner {
		@include flex-column;
		row-gap: clamp(3rem, 6vw, 6rem);
	}
}

.ecosuru-box {
	padding: 2em 0 2.5em;
	position: relative;
	@include media-breakpoint-up(lg) {
		padding: 3.5em 0 4em;
	}
	&::before {
		content: "";
		position: absolute;
		top: 0;
		right: 5%;
		width: 100vw;
		height: 50%;
		z-index: z-index(module,part03);
		background: $main_c;
		@include media-breakpoint-up(lg) {
			right: 16.23%;
		}
	}
	&__inner {
		position: relative;
		z-index: z-index(module,part03);
	}
	&__tit {
		@include f-s_xs(1.14, 14);
		@include f-w(700);
		color: $white;
		padding-right: 7%;
		margin-bottom: calc(0.5em + 1rem);
		@include media-breakpoint-up(lg) {
			padding-right: 18.23%;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(32);
		}
		span {
			display: inline-block;
			margin-right:.2em;
			margin-bottom: .3em;
			vertical-align: baseline;
		}
		b {
			color: $white;
			display: inline-block;
			padding: .1em .2em;
			vertical-align: baseline;
			font-size: 1.5em;
			background: $red;
		}
	}
}

.ecosuru-box__box {
	padding: 1.5em 5% 2em;
	background: $other_c11;
	position: relative;
	display: flex;
	@include media-breakpoint-up(md) {
		flex-direction: row-reverse;
		justify-content: space-between;
	}
	@include media-breakpoint-up(lg) {
		padding: 2.5em 5% 2em;
	}
	@include media-breakpoint-down(sm) {
		row-gap: 1.5rem;
	}
	&__img {
		@include media-breakpoint-up(md) {
			width: 35.6%;
		}
		@include media-breakpoint-down(sm) {
			margin-bottom: 1rem;
		}
		&__inner {
			@include media-breakpoint-up(md) {
				@include aspect-img(1, 1);
			}
			@include media-breakpoint-down(sm) {
				@include aspect-img();
			}
		}
	}
	&__txt {
		@include media-breakpoint-up(md) {
			width: 56.37%;
		}
		.txt {
			margin-bottom: 2rem;
			@include f-w(500);
		}
	}
	&__feature {
		border: 1px solid $main_c;
		padding: 1.5em 5% 2em;
		background: $white;
		&__inner {
		}
		&__tit {
			@include f-s_xs(0.94, 3);
			@include f-w(700);
			margin-bottom: 1rem;
			@include media-breakpoint-up(xl) {
				@include f-size(18);
			}
		}
	}
	&__video {
		margin-top: clamp(1.5rem, 2vw, 2.5rem);
		@include flex-column;
		row-gap: 1rem;
	}
}

// ecosuru-pdf
.ecosuru-pdf {
	margin-bottom: clamp(6rem, 9vw, 9rem);
	&__inner {
		background: $white;
		padding: 3em 5% 2em;
		border: 1px solid $main_c;
		position: relative;
		@include media-breakpoint-up(lg) {
			padding: 4em 8% 3em;
		}
	}
	&__tit {
		position: absolute;
		width: 100%;
		top: -1em;
		left: 0;
		text-align: center;
		display: flex;
		justify-content: center;
		@include m-a;
		@include f-w(700);
		@include f-s_xs(1, 12);
		@include media-breakpoint-up(lg) {
			top: -1.2em;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(32);
		}
		span {
			display: inline-block;
			color: $white;
			background: $red;
			padding: .4em 1em;
			@include media-breakpoint-up(lg) {
				padding: .4em 1.5em;
			}
		}
	}
	&__list {
		@include media-breakpoint-up(lg) {
			@include flex-wrap;
			column-gap: 2%;
			row-gap: 1rem;
			& > * {
				width: 49%;
			}
		}
		@include media-breakpoint-up(xl) {
			column-gap: 1.04%;
			& > * {
				width: 32.64%;
			}
		}
		& li {
			&:not(:last-of-type) {
				@include media-breakpoint-down(md) {
					margin-bottom: 1rem;
				}
			}
			a {
				padding: 1rem 4% 1rem 5em;
				@include transition;
				background: $main_c;
				position: relative;
				width: 100%;
				height: 100%;
				display: block;
				color: $white;
				@include dec-none;
				@include f-w(500);
				@include icon(pdf);
				&::before {
					position: absolute;
					left: 1em;
					@include f-em(30);
					color: $white;
					@include media-breakpoint-up(lg) {
						position: absolute;
						left: 1.25em;
					}
				}
				&:hover {
					background: $point_c;
					color: $white;
				}
			}
		}
	}
}

// ecosuru-trader
.ecosuru-trader {
	overflow: hidden;
	position: relative;
	padding-top: clamp(4rem, 8vw, 8rem);
	padding-bottom: clamp(3rem, 6vw, 6rem);
	margin-bottom: clamp(6rem, 9vw, 9rem);
	&::before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		z-index: z-index(module, part04);
		background: $other_c6;
		width: 100%;
		height: 100%;
		@include media-breakpoint-up(lg) {
			width: 97%;
		}
		@include media-breakpoint-up(xl) {
			width: calc(50% + 770px);
		}
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part03);
		max-width: 1200px;
		@include m-a;
	}
	&__add {
		position: absolute;
		top: -2rem;
		left: 60%;
		width: 48%;
		min-width: 360px;
		z-index: -1;
		@include media-breakpoint-up(lg) {
			top: -5rem;
			left: 54%;
			width: 52%;
		}
		@include media-breakpoint-up(xl) {
			top: -7rem;
		}
		@include media-breakpoint-down(xs) {
			display: none;
		}
	}
	&__tit {
		@include f-s_xs(1, 14);
		margin-bottom: calc(1rem + 0.5em);
		@include media-breakpoint-up(lg) {
			width: 50%;
			@include f-s_md(1.25, 10);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(32);
		}
		span {
			display: inline-block;
			padding: 1em 1em 1em 0;
			position: relative;
			color: $white;
			@include media-breakpoint-up(md) {
				padding: 1em 2em 1em 0;
			}
			@include media-breakpoint-down(xs) {
				width: 100%;
			}
			&::before {
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				background: $other_c5;
				width: 120%;
				height: 100%;
				z-index: -1;
				@include media-breakpoint-up(xl) {
					width: calc(100% + 260px);
				}
			}
		}
	}
	&__txt {
		@include f-w(500);
		margin-bottom: clamp(2rem, 3vw, 3rem);
	}
	&__table {
		@include media-breakpoint-up(lg) {
			padding-top: 5rem;
		}
		table {
			width: 100%;
			@include f-em(15);
			margin-bottom: 2rem;
			background: $white;
			& tr {
				border-bottom: 1px solid $other_c5;
				border-left: 1px solid $other_c5;
				border-right: 1px solid $other_c5;
				&:first-child {
					border-top: 1px solid $other_c5;
				}
			}
			& th,
			& td.column-1 {
				text-align: left;
				@include f-w(500);
				padding: 1rem 1.5em;
				background: $p-gray;
				@include media-breakpoint-up(lg) {
					width: 25%;
					min-width: 180px;
				}
				@include media-breakpoint-up(xl) {
					width: 220px;
				}
				@include media-breakpoint-down(md) {
					display: block;
				}
			}
			& td.column-2 {
				padding: 1rem 4%;
				display: flex;
				@include media-breakpoint-up(xl) {
					@include flex-wrap;
					column-gap: 2%;
					row-gap: 0.3em;
				}
				@include media-breakpoint-down(lg) {
					flex-direction: column;
					row-gap: 0.3em;
				}
				& > span {
					padding-left: 1.5em;
					position: relative;
					@include media-breakpoint-up(xl) {
						width: 49%;
					}
					&::before {
						content: "・";
						position: absolute;
						top: 0;
						left: 0;
					}
				}
			}
		}
	}
}
