/*--------------------------------------------------------------------/
	news
/--------------------------------------------------------------------*/
.contents-flex {
	display: flex;
	@include media-breakpoint-up(lg) {
		justify-content: space-between;
	}
	@include media-breakpoint-down(md) {
		flex-direction: column;
		row-gap: clamp(3rem, 5vw, 5rem);
	}
}

.contents-left {
	width: 100%;
	@include media-breakpoint-up(lg) {
		width: 71.42%;
	}
}

// news-interview
.news-interview {
	padding-bottom: clamp(3rem, 5vw, 5rem);
	&__flex {
		display: flex;
		@include media-breakpoint-up(md) {
			justify-content: space-between;
			&.is-right {
				flex-direction: row-reverse;
			}
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: 0.75rem;
		}
		.news-interview__img {
			@include media-breakpoint-up(md) {
				width: 38%;
			}
			&__inner {
				overflow: hidden;
			}
		}
		.news-interview__txt {
			@include media-breakpoint-up(md) {
				width: 58%;
			}
		}
	}
}

// news-video
.news-video {
	padding-bottom: clamp(3rem, 5vw, 5rem);
	&__tit {
		margin-bottom: calc(1.5rem + 0.3em);
		@include f-s_xs(1, 8);
		@include f-w(600);
		position: relative;
		overflow: hidden;
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
		span {
			position: relative;
			display: inline-block;
			padding-right: 1em;
			z-index: z-index(module, part03);
			&::before {
				content: "";
				width: 100vw;
				height: 1px;
				background: $m-gray;
				position: absolute;
				top: 50%;
				left: 100%;
				z-index: z-index(module, part04);
			}
		}
	}
	&__flex {
		@include media-breakpoint-up(lg) {
			@include flex-between;
		}
	}
	&__video {
		@include media-breakpoint-up(lg) {
			flex-basis: 37.5%;
		}
		@include media-breakpoint-down(md) {
			@include m-a;
			max-width: 480px;
			margin-bottom: 1rem;
		}
		&__inner {
			position: relative;
			width: 100%;
			height: 0;
			padding-bottom: 56.25%;
			border-radius: 8px;
			overflow: hidden;
			z-index: z-index(module, part04);
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	&__txt {
		@include media-breakpoint-up(lg) {
			flex-basis: 58%;
		}
	}
}

/* news-table */
.news-table {
	padding-bottom: clamp(3rem, 5vw, 5rem);
	&__tit {
		text-align: center;
		@include f-s_xs(1, 8);
		@include f-w(600);
		margin-bottom: calc(1.5rem + 0.3em);
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
	}
}

/*--------------------------------------------------------------------/
	single-page
/--------------------------------------------------------------------*/
.single-cont {
	padding-bottom: clamp(3rem, 6vw, 6rem);
	&__inner {
		position: relative;
		@include clearfix;
	}
	&__addtoany {
		margin-top: 2rem;
		border: 1px solid $m-gray;
		padding: 1em 1.5em;
		& .addtoany_shortcode {
			& > div {
				line-height: 1 !important;
			}
		}
	}
	&__tag {
		padding-top: 1em;
		a {
			font-size: 1rem !important;
			color: $main_c;
			padding-left: 1.5em;
			margin-right: 0.5rem;
			position: relative;
			@include icon(tag, before);
			@include dec-none;
			&::before {
				position: absolute;
				top: 0.6em;
				left: 0.2rem;
				font-size: 0.85em;
				color: $main_c;
				@include dec-none;
			}
			&:hover {
				@include dec-line;
			}
		}
	}
}

// block editor
.wp-block-gallery {
	margin-bottom: 2em;
}
.wp-block-image {
	overflow: hidden;
}
.wp-block-file {
	margin-bottom: 2em;
}
.wp-block-columns {
	margin-bottom: clamp(2rem, 4vw, 4rem) !important;
	h2 {
		margin-bottom: 0.75em !important;
		padding-bottom: 0.5em !important;
		border-bottom: 1px solid $main_c;
		@include f-s_xs(1, 4);
		@include media-breakpoint-up(xl) {
			@include f-size(20);
		}
	}
}
