/*--------------------------------------------------------------------/
	purchase
/--------------------------------------------------------------------*/
// purchase-intro
.purchase-intro {
	position: relative;
	padding: clamp(2rem, 4vw, 4rem) 0 clamp(3rem, 6vw, 6rem);
	&::before {
		content: "";
		width: 100%;
		height: 60%;
		position: absolute;
		top: 0;
		left: 0;
		background: $main_c;
		z-index: z-index(module, part04);
	}
	&::after {
		content: "";
		width: 100%;
		height: 40%;
		position: absolute;
		bottom: 0;
		left: 0;
		background: $other_c3;
		z-index: z-index(module, part04);
	}
	&__add {
		position: absolute;
		top: 1em;
		z-index: z-index(module, part03);
		@include media-breakpoint-up(lg) {
			left: 70%;
			width: 54%;
		}
		@include media-breakpoint-up(xl) {
			left: calc(50% + 240px);
		}
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
	&__inner {
		@include f-s_xs(0.88, 3);
		@include f-w(500);
		position: relative;
		z-index: z-index(module, part02);
		@include media-breakpoint-up(xl) {
			@include f-size(18);
		}
	}
	&__tit {
		margin-bottom: clamp(1.5rem, 3vw, 3rem);
		&__inner {
			background: $white;
			padding: 1em 1em 1em 0;
			position: relative;
			display: inline-block;
			@include f-w(700);
			@include f-s_xs(1, 8);
			@include media-breakpoint-up(xl) {
				@include f-size(24);
			}
			&::before {
				content: "";
				position: absolute;
				top: 0;
				right: 100%;
				width: 100vw;
				height: 100%;
				background: $white;
			}
			b {
				color: $point_c;
				font-size: 2.5em;
			}
			strong {
				display: inline-block;
				background: $red;
				color: $white;
				font-size: 1.75em;
				padding: 0.1em 0.2em;
			}
		}
	}
	&__list {
		@include flex-wrap;
		column-gap: 2%;
		row-gap: clamp(0.5rem, 1.5vw, 1rem);
		@include media-breakpoint-up(lg) {
			column-gap: 0.925%;
		}
	}
	&__img {
		width: 49%;
		@include media-breakpoint-up(lg) {
			width: 24.3%;
		}
	}
	&__txt {
		margin-top: clamp(2rem, 5vw, 5rem);
		padding: 1.5em 5%;
		background: $main_c;
		color: $white;
	}
}

// purchase-point
.purchase-point {
	position: relative;
	padding-top: clamp(5rem, 9vw, 9rem);
	margin-bottom: clamp(3rem, 6vw, 6rem);
	&__inner {
		padding: 1em 5% 2em;
		border: 1px solid $main_c;
		background: $white;
		position: relative;
		z-index: z-index(module, part03);
		@include media-breakpoint-up(md) {
			padding: 1em 5% 4em;
		}
	}
	&__tit {
		text-align: center;
		display: block;
		position: relative;
		@include m-a;
		@include line-h(1);
		top: -3em;
		&__inner {
			background: $white;
			padding: 0 4%;
			display: inline-block;
			span {
				display: block;
				@include f-w(700);
				color: $main_c;
				@include f-s_xs(0.94, 18);
				@include media-breakpoint-up(xl) {
					@include f-size(36);
				}
			}
			strong {
				display: block;
				@include f-w(900);
				@include line-h(1);
				@include f-s_xs(1.5, 28);
				@include l-sp(0.15em);
				@include media-breakpoint-up(xl) {
					@include f-size(54);
				}
				b {
					font-size: 2em;
					color: $red;
				}
			}
		}
	}
	&__list {
		display: flex;
		@include media-breakpoint-up(md) {
			column-gap: 1.3%;
			row-gap: 1em;
			justify-content: center;
			flex-wrap: wrap;
			& > * {
				width: 49.35%;
				max-width: 640px;
			}
		}
		@include media-breakpoint-up(xl) {
			row-gap: 1.25rem;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: 1rem;
		}
	}
	&__item {
		background: $red;
		color: $white;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 1em 2em;
		@include media-breakpoint-up(md) {
			min-height: 100px;
		}
		&__inner {
			text-align: center;
			@include line-h(1.5);
			@include f-w(700);
			@include f-s_xs(0.94, 4);
			@include media-breakpoint-up(md) {
				@include f-s_md(0.94, 5);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(20);
			}
		}
	}
}

// purchase-assessment
.purchase-assessment {
	padding-top: clamp(3rem, 6vw, 6rem);
	padding-bottom: clamp(5rem, 12vw, 12rem);
	margin-bottom: clamp(5rem, 9vw, 9rem);
	position: relative;
	background: $main_c;
	overflow: hidden;
	&::before {
		content: "";
		background: $other_c7;
		width: 80%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 50%;
		transform: skew(-20deg);
	}
	&__add {
		position: absolute;
		bottom: 0;
		right: 5%;
		z-index: z-index(module, part03);
		width: 100vw;
		text-align: right;
		span {
			@include f-family(font02);
			color: $other_c8;
			@include f-w(700);
			@include l-sp(0.05em);
			@include f-s_xs(3, 140);
			@include line-h(0.85);
			@include media-breakpoint-up(xl) {
				@include f-s_xl(10, 40);
			}
			@include media-breakpoint-up(xxl) {
				@include f-size(200);
			}
		}
	}
	&__inner {
		position: relative;
		background: $white;
		z-index: z-index(module, part03);
		padding: 0 5% 2em;
	}
	&__tit {
		color: $black;
		@include f-w(900);
		@include f-s_xs(0.9, 20);
		@include line-h(1.4);
		text-align: center;
		position: relative;
		top: -1em;
		@include media-breakpoint-up(xl) {
			@include f-size(32);
		}
		&__inner {
			display: inline-block;
			background: $white;
			padding: 0.75em 1.25em;
			position: relative;
			&::after {
				content: "";
				position: absolute;
				left: 25%;
				bottom: 0;
				width: 50%;
				height: 2px;
				background: $main_c;
			}
		}
	}
	&__txt {
		@include media-breakpoint-up(lg) {
			text-align: center;
		}
	}
}

// purchase-type
.purchase-type {
	&__tit {
		padding: 0.5em 2%;
		border-top: 1px solid $main_c;
		border-bottom: 1px solid $main_c;
		@include f-w(700);
		color: $black;
		text-align: center;
		@include f-s_xs(1.25, 32);
		@include l-sp(0.1em);
		margin-bottom: calc(2em + 2rem);
		@include media-breakpoint-up(lg) {
			margin-bottom: calc(2em + 4rem);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(52);
		}
		span {
			font-size: 1.12em;
		}
		b {
			font-size: 1.75em;
			color: $red;
		}
	}
	&__list {
		@include flex-column;
		row-gap: clamp(3rem, 6vw, 6rem);
	}
}

.purchase-box {
	position: relative;
	background-color: $o-white;
	&__num {
		position: absolute;
		left: 3%;
		@include line-h(1);
		top: -0.5em;
		@include f-w(700);
		@include f-family(font02);
		@include f-s_xs(3, 48);
		@include media-breakpoint-up(lg) {
			left: 6%;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(96);
		}
		span {
			position: relative;
			color: $main_c;
			@include l-sp(0.05em);
		}
	}
	&__inner {
		padding: 4em 5% 3em;
		position: relative;
		@include media-breakpoint-up(lg) {
			padding: 5em 8% 5em;
		}
	}
	&__head {
		margin-bottom: clamp(1.5rem, 4vw, 4rem);
		@include media-breakpoint-up(lg) {
			@include flex-between;
		}
	}
	&__tit {
		color: $black;
		overflow: hidden;
		@include f-w(700);
		@include f-s_xs(2, 40);
		@include media-breakpoint-up(lg) {
			@include f-s_lg(2.5, 24);
			width: 34.88%;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(64);
		}
		span {
			display: inline-block;
			position: relative;
			padding-right: 1em;
			//&::after {
			//	content: "";
			//	position: absolute;
			//	top: 50%;
			//	left: 100%;
			//	height: 1px;
			//	width: 100vw;
			//	background: $main_c;
			//}
		}
	}
	&__txt {
		@include f-w(500);
		color: $black;
		@include media-breakpoint-up(lg) {
			@include f-s_lg(1, 4);
			width: 60.46%;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(20);
		}
		b {
			display: inline-block;
			background: $red;
			color: $white;
			padding: 0.1em 0.2em;
		}
	}
}

.purchase-box__merit {
	&__tit {
		text-align: center;
		overflow: hidden;
		@include f-s_xs(1.14, 12);
		color: $black;
		margin-bottom: calc(1rem + 1em);
		@include f-w(700);
		@include media-breakpoint-up(xl) {
			@include f-size(28);
		}
		span {
			display: inline-block;
			padding: 0 0.75em;
			position: relative;
			&::before,
			&::after {
				content: "";
				width: 100vw;
				height: 1px;
				background: $main_c;
				position: absolute;
				top: calc(50% - 1px);
				@include media-breakpoint-up(md) {
					height: 2px;
				}
			}
			&::before {
				right: 100%;
			}
			&::after {
				left: 100%;
			}
		}
	}
	&__list {
		display: flex;
		row-gap: 1rem;
		@include media-breakpoint-up(md) {
			flex-wrap: wrap;
			column-gap: 2%;
			& > * {
				width: 49%;
			}
		}
		@include media-breakpoint-up(lg) {
			column-gap: 1.55%;
			& > * {
				width: 23.8375%;
			}
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}
	}
	&__box {
		border: 1px solid $main_c;
		background: $white;
		&__inner {
			padding: 1.5em 8% 2em;
		}
		&__img {
			width: 100%;
			@include m-a;
			margin-bottom: 1rem;
			max-width: 240px;
			&__inner {
				@include aspect-img();
			}
		}
		&__tit {
			@include line-h(1.4);
			@include f-w(700);
			margin-bottom: 1rem;
			@include media-breakpoint-up(md) {
				@include f-s_md(0.94, 4);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(17);
			}
		}
		&__txt {
			@include media-breakpoint-up(md) {
				font-size: 0.94em;
			}
		}
	}
}

.purchase-box__file {
	margin-top: clamp(1.5rem, 2vw, 2rem);
	background: $white;
	border: 1px solid $main_c;
	padding: 1.5em 5% 2em;
	&__inner {
	}
	&__tit {
		@include f-w(700);
		margin-bottom: 0.5rem;
		@include f-s_xs(1, 4);
		@include media-breakpoint-up(xl) {
			@include f-size(20);
		}
	}
	.btn-wrap {
		a {
			padding: 1rem 4% 1rem 4rem;
			@include transition;
			background: $main_c;
			position: relative;
			width: 100%;
			height: 100%;
			display: block;
			color: $white;
			@include dec-none;
			@include f-w(500);
			@include icon(pdf);
			&::before {
				position: absolute;
				left: 1.25rem;
				@include f-em(30);
				color: $white;
			}
			&:hover {
				background: $point_c;
				color: $white;
			}
		}
	}
}
