/*--------------------------------------------------------------------/
	rental
/--------------------------------------------------------------------*/
// rental-intro
.rental-intro {
	padding: clamp(4rem, 8vw, 8rem) 0 clamp(5rem, 10vw, 10rem);
	position: relative;
	background-image: url(../img/lv2/rental_bg_sp.jpg);
	background: center center/cover no-repeat;
	@include media-breakpoint-up(lg) {
		background-image: url(../img/lv2/rental_bg_sp.jpg);
		padding: clamp(4rem, 6vw, 6rem) 0 clamp(5rem, 9vw, 9rem);
	}
	&::before {
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: rgba($main_c, 0.5);
		z-index: z-index(module, part03);
	}
	&__inner {
		position: relative;
		background: $white;
		z-index: z-index(module, part02);
		padding: 2em 4%;
		@include media-breakpoint-up(lg) {
			padding: 2em 2% 3em;
		}
		p {
			@include line-h(1.5);
			@include media-breakpoint-up(md) {
				text-align: center;
			}
			&.is-blue {
				color: $main_c;
				@include f-w(700);
				@include f-s_xs(0.84, 12);
				@include media-breakpoint-up(xl) {
					@include f-size(28);
				}
			}
			&.is-black {
				color: $txt_c;
				@include f-w(900);
				@include f-s_xs(0.84, 14);
				@include media-breakpoint-up(xl) {
					@include f-size(30);
				}
				strong {
					display: inline-block;
					background: $red;
					color: $white;
					padding: 0.2em 0.3em;
					margin: 0 0.2em;
				}
			}
		}
	}
}

//rental-summary
.rental-summary {
	position: relative;
	padding: clamp(3rem, 4vw, 4rem) 0 clamp(8rem, 12vw, 12rem);
	background: $sub_c;
	&__add {
		position: absolute;

		z-index: z-index(module, part03);
		@include media-breakpoint-up(lg) {
			bottom: 1em;
			left: 70%;
			width: 54%;
		}
		@include media-breakpoint-up(xl) {
			left: calc(50% + 240px);
		}
		@include media-breakpoint-down(md) {
			bottom: 0;
			left: 40%;
			width: 60%;
		}
	}
	.l-cont {
		position: relative;
		z-index: z-index(module, part02);
	}
}

.rental-summary__box {
	background: $white;
	padding: 2em 5% 3em;
	&__tit {
		text-align: center;
		color: $main_c;
		@include f-s_xs(1.25, 14);
		@include f-w(700);
		margin-bottom: calc(1rem + 0.5em);
		@include media-breakpoint-up(xl) {
			@include f-size(36);
		}
	}
	&__pdf {
		margin-top: clamp(2rem, 3vw, 3rem);
		@include flex-column;
		row-gap: 1rem;
		background: $l-gray;
		padding: 1em 5%;
		@include media-breakpoint-up(lg) {
			padding: 1.5em 5%;
		}
		& li {
			a {
				padding: 1rem 4% 1rem 4.5rem;
				@include transition;
				background: $main_c;
				position: relative;
				width: 100%;
				height: 100%;
				display: block;
				color: $white;
				@include dec-none;
				@include f-w(500);
				@include icon(pdf);
				&::before {
					position: absolute;
					left: 1.75rem;
					@include f-em(30);
					color: $white;
				}
				&:hover {
					background: $point_c;
					border-color: $point_c;
					color: $white;
					&::before {
						color: $white;
					}
				}
			}
		}
	}
}

//rental-list
.rental-list {
	padding-top: clamp(3rem, 6vw, 6rem);
	padding-bottom: clamp(4rem, 10vw, 10rem);
	@include media-breakpoint-up(lg) {
		padding-top: 6rem;
	}
	&__inner {
	}
	&__tit {
		display: flex;
		align-items: center;
		justify-content: center;
		column-gap: 2px;
		margin-bottom: clamp(2.5rem, 5vw, 5rem);
		& > span {
			width: 60px;
			height: 60px;
			color: $white;
			display: flex;
			justify-content: center;
			align-items: center;
			background: $main_c;
			@include f-size(32);
			@include media-breakpoint-up(lg) {
				width: 90px;
				height: 90px;
				@include f-size(48);
			}
		}
	}
	&__list {
		@include flex-column;
		row-gap: clamp(4rem, 7vw, 7rem);
	}
}

// rental-box
.rental-box {
	&__tit {
		background: $main_c;
		padding: 0.3em 1em;
		color: $white;
		margin-bottom: calc(0.5em + 0.5rem);
		@include f-w(700);
		@include f-s_xs(1, 14);
		@include media-breakpoint-up(xl) {
			@include f-size(32);
		}
	}
	&__price {
		margin-bottom: clamp(1rem, 2vw, 2rem);
		span {
			@include line-h(1);
			&.is-sub {
				display: inline-block;
				background: $main_c;
				padding: 0.2em 0.5em;
				color: $white;
				@include f-s_xs(0.84, 4);
				@include f-w(700);
				@include media-breakpoint-up(xl) {
					@include f-size(20);
				}
			}
			&.is-price {
				color: $red;
				@include f-family(font02);
				@include f-s_xs(2, 40);
				@include f-w(700);
				padding-left: 0.75em;
				@include media-breakpoint-up(xl) {
					@include f-size(72);
				}
			}
			&.is-en {
				display: inline-block;
				padding-left: 0.4em;
				color: $txt_c;
				@include f-s_xs(0.84, 16);
				@include f-w(700);
				@include media-breakpoint-up(xl) {
					@include f-size(30);
				}
			}
		}
	}
	&__flex {
		display: flex;
		@include media-breakpoint-up(lg) {
			justify-content: space-between;
			align-items: stretch;
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
			row-gap: 2rem;
		}
	}
	&__left {
		@include media-breakpoint-up(lg) {
			width: 65.19%;
		}
		&__inner {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			column-gap: 1.4%;
			row-gap: 1vw;
			@include media-breakpoint-up(xl) {
				row-gap: 0.94rem;
			}
		}
	}
	&__right {
		@include media-breakpoint-up(lg) {
			width: 30.38%;
		}
		&__inner {
			width: 100%;
			height: 100%;
			border: 1px solid $main_c;
			padding: 1em 5% 2em;
			@include media-breakpoint-up(lg) {
				padding: 1.5em 2em 2em;
			}
		}
	}
	&__img {
		width: 49.3%;
		&__inner {
			margin: 0;
			@include aspect-img;
		}
	}
	&__size {
		&__tit {
			color: $txt_c;
			margin-bottom: 1rem;
			text-align: center;
			@include f-s_xs(1, 4);
			@include media-breakpoint-up(lg) {
				@include f-s_lg(1, 4);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(20);
			}
		}
		&__list {
			display: flex;
			@include media-breakpoint-up(lg) {
				flex-direction: column;
				row-gap: 1vw;
			}
			@include media-breakpoint-up(xl) {
				row-gap: 0.94rem;
			}
			@include media-breakpoint-down(md) {
				justify-content: space-between;
				column-gap: 2%;
			}
		}
		&__img {
			@include media-breakpoint-down(md) {
				width: 49%;
			}
			&__inner {
				margin: 0;
				@include aspect-img;
			}
		}
	}
	&__teble {
		margin-top: clamp(1.5rem, 3vw, 3rem);
	}
}

//rental-contact
.rental-contact {
	padding: clamp(2rem, 4vw, 4rem) 0 clamp(4rem, 7vw, 7rem);
	position: relative;
	background: $main_c;
	&::before {
		content: "";
		position: absolute;
		bottom: 0;
		z-index: z-index(module, part04);
		background: $sub_c;
		height: 80%;
		right: 0;
		@include media-breakpoint-up(lg) {
			width: 98%;
		}
		@include media-breakpoint-up(xl) {
			width: calc(50% + 860px);
		}
	}
	&__inner {
		background: $white;
		position: relative;
		z-index: z-index(module, part03);
	}
	&__tit {
		background: $red;
		color: $white;
		@include f-w(900);
		padding: 0.5em 2%;
		@include f-s_xs(0.94, 16);
		position: relative;
		text-align: center;
		@include media-breakpoint-up(xl) {
			@include f-size(32);
		}
		&::after {
			position: absolute;
			left: calc(50% - 8px);
			top: calc(100% - 2px);
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 16px 16px 0 16px;
			border-color: $red transparent transparent transparent;
		}
	}
	&__txt {
		padding: 3em 5%;
	}
}

.rental-contact {
	&__flex {
		padding-top: clamp(2rem, 3vw, 3rem);
		display: flex;
		@include media-breakpoint-up(md) {
			justify-content: space-between;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: 1rem;
		}
	}
	&__tel {
		background: $main_c;
		@include media-breakpoint-up(md) {
			width: 61.32%;
		}
		&__inner {
			width: 100%;
			padding: 1em 5% 2em;
		}
		&__tit {
			padding: 0.3em 2%;
			text-align: center;
			color: $red;
			@include f-w(700);
			background: $white;
			@include f-s_xs(1, 6);
			margin-bottom: 1em;
			@include media-breakpoint-up(md) {
				@include f-s_md(0.94, 8);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(26);
			}
		}
		&__num {
			color: $black;
			@include f-family(font02);
			position: relative;
			@include icon(phone, before);
			@include f-s_xs(1.75, 20);
			@include f-w(700);
			@include l-sp(0);
			@include line-h(1);
			text-align: center;
			color: $white;
			@include media-breakpoint-up(md) {
				@include f-s_md(2, 28);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(64);
			}
			&::before {
				margin-right: 0.2em;
				@include f-em(11);
				color: $white;
			}
			a {
				color: $white !important;
			}
		}
	}
	&__mail {
		border: 1px solid $main_c;
		@include media-breakpoint-up(md) {
			width: 37.51%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		&__inner {
			width: 100%;
			padding: 2em 5%;
		}
		&__tit {
			text-align: center;
			@include f-w(700);
			margin-bottom: 0.5em;
			@include f-s_xs(0.94, 5);
			@include media-breakpoint-up(md) {
				@include f-s_md(0.75, 8);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(20);
			}
		}
		.btn-wrap {
			width: 100%;
			@include media-breakpoint-up(md) {
				max-width: 320px;
			}
			a {
				display: block;
				width: 100%;
				padding: 1em 2%;
				background: $main_c;
				@include dec-none;
				@include transition;
				color: $white;
				@include f-w(700);
				text-align: center;
				@include media-breakpoint-up(md) {
					@include f-s_md(0.8, 2);
				}
				@include media-breakpoint-up(xl) {
					@include f-size(16);
				}
				&:hover {
					background: $point_c;
				}
				span {
					@include icon(mail);
					&::before {
						font-size: 1.2em;
						margin-right: 0.2em;
					}
				}
			}
		}
	}
}
