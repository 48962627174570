/*--------------------------------------------------------------------/
	contact_form
/--------------------------------------------------------------------*/
.contact-intro {
	padding: 0 4% clamp(5rem, 6vw, 6rem);
	position: relative;
	margin-bottom: clamp(6rem,9vw,9rem);
	@include media-breakpoint-up(lg) {
		padding: 0 2.5rem clamp(6rem, 10vw, 10rem);
	}
	&::before {
		position: absolute;
		content: "";
		top: 5%;
		left: 0;
		background: $other_c1;
		width: 100%;
		height: 40%;
	}
	&::after {
		position: absolute;
		content: "";
		bottom: 0;
		left: 0;
		background: $sub_c;
		width: 100%;
		height: 55%;
	}
	&__add {
		position: absolute;
		bottom: 0;
		right: 5%;
		z-index: z-index(module, part03);
		span {
			@include f-family(font02);
			color: $other_c8;
			@include f-w(700);
			@include l-sp(0.05em);
			@include f-s_xs(3, 140);
			@include line-h(0.85);
			@include media-breakpoint-up(xl) {
				@include f-s_xl(10, 40);
			}
			@include media-breakpoint-up(xxl) {
				@include f-size(200);
			}
		}
	}
	&__inner {
		position: relative;
		z-index: z-index(module,part02);
	}
}

.contact-intro {
	&__up {
		background: $white;
		@include f-s_xs(0.84, 2);
		margin-bottom: clamp(3rem,5vw,5rem);
		position: relative;
		z-index: z-index(module,part03);
		@include media-breakpoint-up(md) {
			text-align: center;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(18);
		}
		&__text {
			padding-bottom: 1.5em;
			padding: 2em 5%;
			@include f-w(700);
			color: $white;
			background: $main_c;
		}
	}
	&__info {
		text-align: center;
		display: flex;
		padding: 1em 0;
		@include media-breakpoint-up(lg) {
			justify-content: center;
			column-gap: 3rem;
			align-items: center;
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
		}
		&__text {
			@include f-w(700);
			@include f-s_xs(1, 4);
			@include media-breakpoint-up(lg) {
				@include f-size(20);
			}
		}
		&__tel {
			@include f-family(font02);
			@include f-s_xs(1.75, 16);
			@include f-w(700);
			color: $txt_c;
			@include media-breakpoint-up(lg) {
				@include f-size(40);
			}
			i {
				@include f-em(11);
				margin-right: 0.2rem;
				color: $main_c;
			}
			a {
				color: $txt_c;
			}
		}
	}
}

.contact-box {
	border: 1px solid $main_c;
	background: $white;
	padding: clamp(1.5rem, 4vw, 4rem) 4%;
	@include media-breakpoint-up(lg) {
		padding: 3rem 4.5rem;
	}
}

.contact-form {
	&__tit {
		color: $main_c;
		@include f-family(font02);
		@include f-w(700);
		@include f-s_xs(1.5, 10);
		text-align: center;
		margin-bottom: clamp(1rem, 2vw, 2rem);
		@include media-breakpoint-up(lg) {
			@include f-size(36);
		}
	}
}

.contact-privacy {
	border: 1px solid $gray;
	@include media-breakpoint-up(lg) {
		padding: 3rem 6%;
		overflow-y: scroll;
		@include scroll-bar;
		max-height: 300px;
	}
	&__inner {
		@include media-breakpoint-up(lg) {
			margin-top: 1.5rem;
			padding: 0 4%;
		}
	}
	&__tit {
		@include f-s_xs(1.14, 2);
		@include f-w(700);
		text-align: center;
		@include f-family(font03);
		@include media-breakpoint-up(lg) {
			margin-bottom: 3rem;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(20);
		}
		@include media-breakpoint-down(md) {
			padding: 1.5rem 6%;
			cursor: pointer;
			position: relative;
			@include icon(down_arrow, after);
			&::after {
				position: absolute;
				right: 1rem;
				color: $gray;
				top: calc(50% - 10px);
				@include transition;
				@include f-size(24);
			}
		}
		&.active {
			&::after {
				transform: rotate(180deg);
			}
		}
	}
	&__txt {
		padding: 0 2rem 2rem;
		@include media-breakpoint-up(lg) {
			padding-bottom: 3rem;
		}
	}
	.txt {
		@include f-em(15);
	}
}

.contact-table {
	width: 100%;
	@include m-a;
	margin-bottom: 2rem;
	line-height: 1.2;
	position: relative;
	& th,
	& td {
		text-align: left;
		@include media-breakpoint-up(lg) {
			display: table-cell;
			padding: 1rem 0 1.5rem;
		}
		@include media-breakpoint-down(md) {
			display: block;
			padding: 0;
		}
	}
	& th {
		vertical-align: top;
		@include f-w(500);
		@include media-breakpoint-up(lg) {
			width: 30%;
		}
		& span {
			margin-left: 0.5rem;
		}
	}
	& td {
		@include media-breakpoint-up(lg) {
			width: auto;
		}
		@include media-breakpoint-down(md) {
			padding: 1rem 0 2rem;
		}
	}
	& textarea {
		width: 100%;
	}
}

input,
select {
	width: auto;
	&.long {
		width: 100%;
	}
	&.middle {
		width: 100%;
	}
	&.short {
		width: 70%;
		@include media-breakpoint-up(md) {
			width: 30%;
		}
	}
	&.mini {
		width: 10em;
	}
}

button {
	border: none;
	outline: none;
	@include f-size(17);
	@include l-sp(0.1em);
}

button[class*="sub-btn"] {
	@include btn_normal($white, $main_c, $main_c, $white, $point_c, $point_c);
}
button[class*="back-btn"] {
	margin-top: 1rem;
	@include btn_normal($d-gray, $l-gray, $l-gray, $white, $black, $black);
}
button[class*="chack-btn"] {
	@include btn_normal($white, $main_c, $main_c, $white, $point_c, $point_c);
}

[type="button"],
[type="submit"] {
	-webkit-appearance: none;
}

%form-btn {
	width: 100%;
	max-width: 240px;
	margin: 0 auto;
	display: block;
	margin-top: 2rem;
	padding: 0.5rem 2rem;
	@include transition;
	cursor: pointer;
	@include f-size(15);
}

[type="button"],
[type="submit"] {
	-webkit-appearance: none;
	width: 100%;
	max-width: 320px;
	margin: 0 auto;
	display: block;
	margin-top: 1rem;
	@include transition;
	cursor: pointer;
	border: none;
	outline: none;
}

/* form-parts
----------------------------------------------------------------*/
%form-parts {
	background-color: $white;
	padding: 0.7em 1em;
	border: 1px solid $gray;
	line-height: 1.5;
	&:focus {
		background-color: $white;
		box-shadow: rgba(58, 134, 232, 0.25) 0 0 4px 1px;
	}
}

%placeholder {
	color: $gray;
}

input {
	@extend %form-parts;
	&::-webkit-input-placeholder {
		@extend %placeholder;
	}
	&:-moz-placeholder {
		@extend %placeholder;
	}
	&:-ms-input-placeholder {
		@extend %placeholder;
	}
}
textarea {
	@extend %form-parts;
	height: 10em;
}
select {
	@extend %form-parts;
}

/* radio-box,check-box
----------------------------------------------------------------*/
/* variablea */
$radio_height: 20px; //チェックボックスの高さ
$duration_radio: 0.4s; //チェックボックスの「「アニメーションのスピード
$checkbox_height: 26px; //チェックボックスの高さ
$duration_checkbox: 0.4s; //チェックボックスの「「アニメーションのスピード

/* radio-btn */
.mwform-radio-field {
	cursor: pointer;
	&:not(:last-child) {
		margin-right: 1em;
	}
}

.mwform-radio-field-text {
	vertical-align: top;
	position: relative;
	padding-left: 34px;
	display: block;
}
input[type="radio"] {
	position: absolute;
	opacity: 0;
	+ .mwform-radio-field-text {
		&:before {
			content: "";
			background: $o-white;
			border-radius: 100%;
			border: 1px solid $gray;
			display: inline-block;
			width: $radio_height;
			height: $radio_height;
			position: absolute;
			top: calc(50% - (#{$radio_height}/ 2));
			left: 0;
			vertical-align: top;
			cursor: pointer;
			text-align: center;
			transition: all $duration_radio ease;
		}
	}
	&:checked {
		+ .mwform-radio-field-text {
			&:before {
				background-color: $main_c;
				box-shadow: inset 0 0 0 5px $p-gray;
			}
		}
	}
	&:focus {
		+ .mwform-radio-field-text {
			&:before {
				outline: none;
				border-color: $main_c;
			}
		}
	}
	&:disabled {
		+ .mwform-radio-field-text {
			&:before {
				box-shadow: inset 0 0 0 4px $gray;
				border-color: $gray;
				background: $o-white;
			}
		}
	}
	+ .mwform-radio-field-text {
		&:empty {
			&:before {
				margin-right: 0;
			}
		}
	}
}
.vertical-item:not(:first-of-type) {
	margin-top: 1rem !important;
}

/* chack-btn */
@-moz-keyframes dothabottomcheck {
	0% {
		height: 0;
	}
	100% {
		height: $checkbox_height/2;
	}
}

@-webkit-keyframes dothabottomcheck {
	0% {
		height: 0;
	}
	100% {
		height: $checkbox_height/2;
	}
}

@keyframes dothabottomcheck {
	0% {
		height: 0;
	}
	100% {
		height: $checkbox_height/2;
	}
}

@keyframes dothatopcheck {
	0% {
		height: 0;
	}
	50% {
		height: 0;
	}
	100% {
		height: $checkbox_height * 1.2;
	}
}
@-webkit-keyframes dothatopcheck {
	0% {
		height: 0;
	}
	50% {
		height: 0;
	}
	100% {
		height: $checkbox_height * 1.2;
	}
}
@-moz-keyframes dothatopcheck {
	0% {
		height: 0;
	}
	50% {
		height: 0;
	}
	100% {
		height: $checkbox_height * 1.2;
	}
}

input[type="checkbox"] {
	display: none;
}

.check-box {
	& .vertical-item:not(:first-of-type) {
		margin-top: 1.5rem !important;
	}
	& label {
		position: relative;
		cursor: pointer;
		&::before {
			content: "";
			height: $checkbox_height;
			width: $checkbox_height;
			background-color: transparent;
			border: 1px solid $gray;
			margin-right: 1em;
			position: absolute;
			top: -0.15em;
			left: 0;
			display: block;
			transition: border-color ease $duration_checkbox/2;
			z-index: z-index(module, part04);
		}
	}
}

.mwform-checkbox-field-text {
	vertical-align: top;
	padding-left: calc(#{$checkbox_height} + 1rem);
	display: block;
	&::before,
	&::after {
		position: absolute;
		height: 0;
		width: $checkbox_height * 0.2;
		background-color: $main_c;
		display: inline-block;
		transform-origin: left top;
		content: "";
		transition: opacity ease 0.5;
		z-index: z-index(module, part03);
	}
	&::before {
		top: $checkbox_height * 0.86;
		left: $checkbox_height * 0.4;
		transform: rotate(-135deg);
	}
	&::after {
		top: $checkbox_height * 0.5;
		left: $checkbox_height * 0.03;
		transform: rotate(-45deg);
	}
}

input[type="checkbox"]:checked + .mwform-checkbox-field-text,
.mwform-checkbox-field-text.checked {
	border-color: $main_c;
	&::after {
		height: $checkbox_height * 0.5;
		animation: dothabottomcheck $duration_checkbox/2 ease 0s forwards;
	}

	&::before {
		height: $checkbox_height * 1.2;
		animation: dothatopcheck $duration_checkbox ease 0s forwards;
		box-shadow: 0 0 0 $checkbox_height * 0.05 $white;
	}
}

.mw_wp_form .error {
	margin-top: 0.7rem;
}
