@mixin btn_base {
	font-weight: normal;
	display: block;
	text-align: center;
	text-decoration: none;
	position: relative;
	line-height: 1.4;
	@include transition;
	@include dec-none;
}

@mixin btn_normal($color: $white, $bg: $main_c, $border: $main_c, $color_h: $white, $bg_h: $sub_c, $border_h: $sub_c) {
	@include btn_base;
	color: $color;
	background-color: $bg;
	border: 1px solid $border;
	padding: 1.5rem 4%;
	width: 100%;
	&:hover {
		color: $color_h;
		background-color: $bg_h;
		border-color: $border_h;
	}
}

//矢印あり
@mixin btn_arrow(
	$color: $white,
	$bg: $main_c,
	$border: $main_c,
	$color_h: $white,
	$bg_h: $point_c,
	$border_h: $point_c,
) {
	@include btn_base;
	color: $color;
	background-color: $bg;
	border: 1px solid $border;
	padding: 1.5rem 4%;
	width: 100%;
	@include icon(arrow-right, after);
	&::after {
		position: absolute;
		right: 1rem;
		color: $color;
		font-size: 0.9em;
		@include transition;
		top: 50%;
		transform: translateY(-50%);
	}
	&:hover {
		color: $color_h;
		background-color: $bg_h;
		border-color: $border_h;
		&::after {
			color: $color_h;
			right: 0.7rem;
		}
	}
}
